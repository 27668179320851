export default class RoleService {
  checkRouteAccess(props, fromPage = '') {
    const access_details = JSON.parse(localStorage.getItem('access_details'));
    
    const pathStr = props.location.pathname.split('/');
    
    const pathIdx = pathStr.length > 3 ? 2 : pathStr.length - 1;
    let PAGE_NAME = fromPage || pathStr[pathIdx].slice(0, -1);

    /** Quick Fix */
    if (pathStr[1] === 'masters' && (pathStr[2]?.indexOf('address-config') > -1 || pathStr[2]?.indexOf('denominations') >= -1)) {
      return true;
    }

    if (pathStr[1] === 'provider' && (pathStr[2]?.indexOf('visit-fee') > -1)) {
      return true;
    }
    /** Quick Fix */

    if (pathStr[1] === 'user-management' && pathStr[2] === 'access-rights') {
      PAGE_NAME = 'ROLE';
    } else if (pathStr[1] === 'masters' && pathStr[2] === 'benefit-hierarchy') {
      PAGE_NAME = 'BENEFITSSTRCTURE';
    } else if (pathStr[pathIdx].split('-').length > 1) {
      PAGE_NAME = pathStr[pathIdx].split('-')[0];
    }
    
    const accessList = access_details.filter(ad => ad?.indexOf(PAGE_NAME.toUpperCase()) > -1);
    
    if (props.location.search?.indexOf('edit') > -1) {
      return accessList.some(role => role?.indexOf('UPDATE') > -1);
    } else if (props.location.search?.indexOf('create') > -1) {
      return accessList.some(role => role?.indexOf('CREATE') > -1);
    } else {
      return accessList.some(role => role?.indexOf('VIEW') > -1);
    }
  }

  checkActionPermission(PAGE_NAME, accessName, fnc = () => { }, actionButtons = []) {
    const access_details = JSON.parse(localStorage.getItem('access_details'));

    if (actionButtons.length > 0) {
      const actionList = [];
      for (let idx = 0; idx < actionButtons.length; idx++) {
        const action = actionButtons[idx];
        const ROLE_NAME = action.key.toUpperCase();
        const PERMISSION = ROLE_NAME.split('_')[0];
        const MODULE_NAME = ROLE_NAME.split('_')[1];
        const accessList = access_details.filter(ad => ad?.indexOf(MODULE_NAME) > -1).map(ac => ac.split('_')[0]);
        const status = accessList.some(a => a?.indexOf(PERMISSION) > -1);
        if (!status) {
          break;
        } else {
          actionList.push(action);
        }
      }
      return actionList;
    } else {
      const accessList = access_details.filter(ad => ad?.indexOf(PAGE_NAME) > -1).map(ac => ac.split('_')[0]);
      const status = accessList.some(a => a?.indexOf(accessName) > -1);
      if (accessName === 'UPDATE') {
        if (status) {
          return [
            {
              icon: 'pi pi-user-edit',
              className: 'ui-button-warning',
              onClick: fnc,
            },
          ];
        } else {
          return [];
        }
      } else {
        return status;
      }
    }
  }
}
