import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Client from './client/client.component';
import ClientDetails from './client/client.details.component';
import ProspectActionComponent from './prospect/prospect-action.component';
import ProspectComponent from './prospect/prospect.component';

const roleService = new RoleService();

export default function ClientsRoutersComponent(props) {
  const { path } = useRouteMatch();
  
  const hasAccess = roleService.checkRouteAccess(props);
  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Client />{' '}
          </Route>
          <Route exact path={`${path}/clients`}>
            {' '}
            <Client />
          </Route>
          <Route exact path={`${path}/prospects`}>
            {' '}
            <ProspectComponent />{' '}
          </Route>
          <Route exact path={`${path}/prospects/:id`}>
            <ProspectActionComponent />
          </Route>
          <Route exact path={`${path}/clients/:id`}>
            <ClientDetails />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
