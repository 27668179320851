import {
  Box,
  Button,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ProviderTypeService } from '../../remote-api/eo2v2-remote-api';
import { Delete, Edit } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const providertypeservice = new ProviderTypeService();

const CommissionConfig = () => {
  const [commissionRole, setCommissionRole] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [showError, setShowError] = useState(false);

  const handleAddFieldClick = () => {
    setShowInput(true);
  };

  const handleSelectChange = e => {
    setSelectedOption(e.target.value);
  };

  const handleSaveClickCategory = () => {
    if(selectedOption.length > 3){
    const payload = {
        name: selectedOption
    }
    try {
        providertypeservice.addCommissionRole(payload).subscribe(res => {
        //   setCommissionRole(res);
        setSelectedOption('');
        setShowInput(false);
        });
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    }else{
        setShowError(true)
    }
}
  useEffect(() => {
    if(!showInput){
    try {
      providertypeservice.getCommissionRole().subscribe(res => {
        setCommissionRole(res);
      });
    } catch (error) {
      console.error('Error saving the value:', error);
    }
}
  }, [showInput]);

  return (
     <Paper elevation="none">
     <Box p={3} my={2}>
      <Box sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column', paddingRight: '20px' }}>
        {!showInput && (
          <Button variant="contained" color="primary" onClick={handleAddFieldClick}>
            Add New
          </Button>
        )}

        {showInput && (
          <>
            <FormControl variant="outlined" margin="normal" style={{ width: '200px', marginTop: '-12px' }}>
              <TextField
                label="Fund Type"
                value={selectedOption}
                onChange={handleSelectChange}
                variant="outlined"
                margin="normal"
                error={showError}
                helperText={showError ? 'Name Should have Atleast 3 words' : ''}
              />
            </FormControl>

            <Button style={{ display: 'flex' }} variant="contained" color="secondary" onClick={handleSaveClickCategory}>
              Save
            </Button>
          </>
        )}
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Code</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commissionRole?.content?.map(item => {
                return (
                  <TableRow>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell align="center">{item?.code}</TableCell>
                    <TableCell align="center">
                      <Edit style={{ marginRight: '25px', cursor: 'pointer' }} />
                      <Delete style={{ cursor: 'pointer' }} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      </Box>
      </Paper>
  );
};

export default CommissionConfig;
