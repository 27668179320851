import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { ClientService } from '../../remote-api/api/client-services';
import { PolicyService } from '../../remote-api/api/policy-services';
import { Eo2v2DataGrid } from '../../shared-components';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CloseOutlined } from '@material-ui/icons';
import { Button } from 'primereact/button';

const PAGE_NAME = 'POLICY';
const roleService = new RoleService();

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  reassignButton: {
    marginLeft: '5px',
  },
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  background: '#fff',
  // border: '2px solid #000',
  boxShadow: 24,
  padding: '2% 3%',
  borderRadius: '20px',
};

const policyService = new PolicyService();
const clientService = new ClientService();

const columnsDefinations = [
  { field: 'clientName', headerName: 'Name' },
  { field: 'policyNumber', headerName: 'Policy Number' },
  {
    field: 'policyStartDate',
    headerName: 'Policy Period',
    body: rowData => (
      <span style={{ width: '100px' }}>
        {new Date(rowData.policyStartDate).toLocaleDateString()} - {new Date(rowData.policyEndDate).toLocaleDateString()}
      </span>
    ),
  },
  { field: 'policyInitDate', headerName: 'Date' },
  { field: 'policyStatus', headerName: 'Policy Status' },
];

export default function PolicyListComponent(props) {
  const history = useHistory();
  const [rows, setRows] = React.useState(props.rows);

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [reloadTable, setReloadTable] = React.useState(false);
  const [selectedPolicy, setSelectedPolicy] = React.useState({});
  const [invoiceModal, setInvoiceModal] = React.useState(false);
  const [searchType, setSearchType] = React.useState();
  const [policyStartDate, setPolicyStartDate] = React.useState(null);
  const [policyEndDate, setPolicyEndDate] = React.useState(null);

  const [toPolicyStartDate, setToPolicyStartDate] = React.useState(null);
  const [toPolicyEndDate, setToPolicyEndDate] = React.useState(null);

  const [policyCreationStart, setPolicyCreationStart] = React.useState(null);
  const [policyCreationEnd, setPolicyCreationEnd] = React.useState(null);
  const theme = useTheme();
  const dataSource$ = (
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
    },
    policyStartDatePayload = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      fromPolicyStartDate: policyStartDate === '' ? 0 : policyStartDate - 5.5 * 60 * 60 * 1000,
      toPolicyStartDate:
        policyEndDate === ''
          ? policyStartDate === ''
            ? 0
            : Number(policyStartDate) - 5.5 * 60 * 60 * 1000
          : !policyEndDate
          ? Number(policyStartDate) - 5.5 * 60 * 60 * 1000
          : Number(policyEndDate) - 5.5 * 60 * 60 * 1000,
    },
    policyEndDatePayload = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      fromPolicyEndDate: toPolicyStartDate === '' ? 0 : toPolicyStartDate - 5.5 * 60 * 60 * 1000,
      toPolicyEndDate:
        toPolicyEndDate === ''
          ? toPolicyStartDate === ''
            ? 0
            : toPolicyStartDate - 5.5 * 60 * 60 * 1000
          : !toPolicyEndDate
          ? toPolicyStartDate - 5.5 * 60 * 60 * 1000
          : toPolicyEndDate - 5.5 * 60 * 60 * 1000,
    },
    creationDatePayload = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      fromCreatedDate: policyCreationStart === '' ? 0 : policyCreationStart - 5.5 * 60 * 60 * 1000,
      toCreatedDate:
        policyCreationEnd === ''
          ? policyCreationStart === ''
            ? 0
            : policyCreationStart - 5.5 * 60 * 60 * 1000
          : !policyCreationEnd
          ? policyCreationStart - 5.5 * 60 * 60 * 1000
          : policyCreationEnd - 5.5 * 60 * 60 * 1000,
    },
  ) => {
    pageRequest.sort = ['rowLastUpdatedDate dsc'];

    // delete pageRequest.searchKey;
    if (!pageRequest.searchKey) {
      return policyService
        .getPolicy(
          searchType === 1
            ? policyStartDatePayload
            : searchType === 2
            ? policyEndDatePayload
            : searchType === 3
            ? creationDatePayload
            : pageRequest,
        )
        .pipe(
          map(data => {
            let content = data.content;
            let records = content.map(item => {
              item['policyInitDate'] = new Date(item.policyInitDate).toLocaleDateString();
              return item;
            });
            data.content = records;
            return data;
          }),
        );
    }
    pageRequest.sort = ['rowLastUpdatedDate dsc'];
    if (pageRequest.searchKey) {
      pageRequest['clientName'] = pageRequest.searchKey.trim();
      pageRequest['policyNo'] = pageRequest.searchKey.trim();
      return policyService.getPolicy(pageRequest).pipe(
        map(cdata => {
          return cdata;
        }),
      );
    }
  };

  const handleOpen = () => {
    history.push('/policies?mode=create');
  };

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const downloadPolicy = (item) => {
    policyService.downloadPolicy(item?.id).subscribe(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
     
      // Create a temporary anchor element
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `Policy_${item.id}.pdf`; // Set the default file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up the DOM
     
      // Release the object URL
      window.URL.revokeObjectURL(downloadUrl);
     });
  }

  const openEditSection = policy => {
    let referenceID = policy.referenceNumber;
    let clientID = policy.clientId;

    if (policy.sourceType == 'INVOICE' && policy.sourceId) {
      history.push(`/policies/${policy.id}?mode=edit&clientid=` + clientID + `&invid=` + policy.sourceId);
    }
    if (policy.sourceType == 'RECEIPT' && policy.sourceId) {
      history.push(`/policies/${policy.id}?mode=edit&clientid=` + clientID + `&recid=` + policy.sourceId);
    }
    if (referenceID) {
      history.push(`/policies/${policy.id}?mode=edit&clientid=` + clientID + `&refid=` + referenceID);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPolicy(null);
  };

  const handleClickForReAssignOpen = policy => {
    setSelectedPolicy(policy);
    setOpen(true);
  };

  const handleReAssignButtonClick = () => {
    policyService.reAssignRequest(selectedPolicy.id).subscribe(res => {
      setOpen(false);
      setReloadTable(true);
    });
  };

  const onSearch = () => {
    setInvoiceModal(false);
    setReloadTable(true);
    setTimeout(() => {
      setReloadTable(false);
      setPolicyStartDate(null);
      setToPolicyStartDate(null);
      setPolicyCreationStart(null);
      setPolicyEndDate(null);
      setToPolicyEndDate(null);
      setPolicyCreationEnd(null);
    }, [1000]);
  };
  
  const actionBtnList = [
    {
      key: 'update_policy',
      icon: 'pi pi-download', // Changed icon to download
      className: 'ui-button-warning',
      tooltip: 'Download',
      onClick: downloadPolicy,
    },
    {
      key: 'update_policy',
      icon: 'pi pi-user-edit',
      className: 'ui-button-warning',
      onClick: openEditSection,
    },
    {
      key: 'update_policy',
      icon: 'pi pi-arrow-circle-down',
      className: classes.reassignButton,
      onClick: handleClickForReAssignOpen,
      disabled: p => p.policyStatus != 'ASSIGN_FAILED',
    },
  ];

  // PolicyStartDate
  const PolicyStartDate = () => {
    setInvoiceModal(true);
    setSearchType(1);
  };
  // PolicyEndDate
  const PolicyEndDate = () => {
    setInvoiceModal(true);
    setSearchType(2);
  };
  const PolicyCreation = () => {
    setInvoiceModal(true);
    setSearchType(3);
  };
  const xlsColumns = [
    'clientName',
    'policyNumber',
    'policyStartDate',
    'policyInitDate',
    'policyStatus'
  ];
  
  const configuration = {
    enableSelection: false,
    scrollHeight: '300px',
    pageSize: 10,
    actionButtons: roleService.checkActionPermission(PAGE_NAME, '', () => {}, actionBtnList),
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      onCreateButtonClick: handleOpen,
      text: 'Policy Management',
      enableGlobalSearch: true,
      searchText: 'Search by Name, Policy Number',
      selectionMenus: [
        { icon: '', text: 'Policy StartDate', onClick: PolicyStartDate },
        { icon: '', text: 'Policy EndDate', onClick: PolicyEndDate },
        { icon: '', text: 'Policy CreationDate', onClick: PolicyCreation },
      ],
      selectionMenuButtonText: 'Advance Search',
    },
  };

  return (
    <div>
      <Eo2v2DataGrid
        $dataSource={dataSource$}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
        config={configuration}
        reloadTable={reloadTable}
      />

      <Modal open={invoiceModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box style={modalStyle}>
          <Box>
            <Box id="alert-dialog-slide-description">
              {searchType == 1 && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box component="h3" marginBottom={'10px'}>
                      Policy Start Date
                    </Box>
                    <CloseOutlined onClick={() => setInvoiceModal(false)} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box display={'flex'} marginBottom={'10px'}>
                    <Box display={'flex'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        From
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={policyStartDate}
                            onChange={date => setPolicyStartDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                    <Box display={'flex'} marginLeft={'3%'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        To
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={policyEndDate}
                            onChange={date => setPolicyEndDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}

              {searchType == 2 && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box component="h3" marginBottom={'10px'}>
                      Policy End Date
                    </Box>
                    <CloseOutlined onClick={() => setInvoiceModal(false)} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box display={'flex'} marginBottom={'10px'}>
                    <Box display={'flex'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        From
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={toPolicyStartDate}
                            onChange={date => setToPolicyStartDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                    <Box display={'flex'} marginLeft={'3%'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        To
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={toPolicyEndDate}
                            onChange={date => setToPolicyEndDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}

              {searchType == 3 && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box component="h3" marginBottom={'10px'}>
                      Policy End Date
                    </Box>
                    <CloseOutlined onClick={() => setInvoiceModal(false)} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box display={'flex'} marginBottom={'10px'}>
                    <Box display={'flex'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        From
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={policyCreationStart}
                            onChange={date => setPolicyCreationStart(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                    <Box display={'flex'} marginLeft={'3%'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        To
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={policyCreationEnd}
                            onChange={date => setPolicyCreationEnd(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box marginTop={'10%'}>
            <Button
              variant="contained"
              style={{ backgroundColor: theme.palette.primary.main, color: '#fff' }}
              onClick={onSearch}>
              Search
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
        <DialogTitle id="form-dialog-title">Request reassign</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: 12 }}>
            Are you sure , want to raise reassign Request of policy {selectedPolicy.policyNumber}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="p-button-text" color="primary">
            Cancel
          </Button>
          <Button onClick={handleReAssignButtonClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
