import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAlert from '@material-ui/lab/Alert';
import 'date-fns';
import * as React from 'react';
import { HierarchyService } from '../../../remote-api/api/hierarchy-services';
const orgtypeservice = new HierarchyService();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ConfirmationDialogComponent(props) {
  const [remarks, setRemarks] = React.useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleClose = () => {
    props.confirmNo();
  };

  const handleModalSubmit = () => {
    props.confirmYes(props.selectedNode);
  };

  const handleChange = e => {
    setRemarks(e.target.value);
  };

  return (
    <Dialog
      open={props.confirmModal}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus>
      {/* <DialogTitle id="form-dialog-title">Add Position</DialogTitle> */}
      <DialogContent>
        <span>Are you sure to delete? Please confirm.</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalSubmit} color="primary">
          Yes
        </Button>
        <Button onClick={handleClose} color="primary" className="p-button-text">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
