import React, { useContext, useMemo } from 'react';

import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { IntlProvider } from 'react-intl';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import AppContext from '../contextProvider/AppContextProvider/AppContext';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const AppWrapper = ({ children }) => {
  const { locale, theme, themeType } = useContext(AppContext);

  // const muiTheme = useMemo(() => {
  //   return createTheme(theme);
  // }, [theme]); old funtion

  const muiTheme = useMemo(() => {
    return createTheme({
      ...theme, // Your existing theme configuration
      palette: {
        ...theme.palette, // Preserve other palette settings
        type: themeType, // Set the theme type dynamically
      },
    });
  }, [theme, themeType]);

  return (
    <IntlProvider locale={AppLocale[locale.locale].locale} messages={AppLocale[locale.locale].messages}>
      <ThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StylesProvider jss={jss}>
            <CssBaseline />
            <AppLayout>{children}</AppLayout>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default AppWrapper;
