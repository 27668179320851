import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Branch from './components/branch.component';
import BranchDetailsComponent from './components/branch.details.component';

const roleService = new RoleService();

export default function BranchRoutersComponent(props) {
  const { path } = useRouteMatch();

    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Branch />{' '}
          </Route>
          <Route exact path="/branch" component={Branch} />
          <Route exact path="/branch/:id" component={BranchDetailsComponent} />
        </Switch>
      </React.Fragment>
    );
  }