import React, { useEffect, useState } from 'react';
import { QuestionnaireService } from '../remote-api/api/master-services/questionnaire.service';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Typography, Grid, Button } from '@material-ui/core';
import { MemberService } from '../remote-api/api/member-services';
import { Toast } from 'primereact/toast';

const questionnaireService = new QuestionnaireService();
const memberservice = new MemberService();

const MemberQuestionnair = ({ memberData }) => {
  const [questionnaires, setQuestionnairs] = useState([]);
  const [answers, setAnswers] = useState({});
  const toast = React.useRef(null);

  const handleToggle = (id, value) => {
    setAnswers(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  const dataSource$ = (pageRequest = {}) => {
    pageRequest.age = memberData.age;
    pageRequest.gender = memberData.gender;
    return questionnaireService.getMemberQuestionnaireList(pageRequest);
  };

  const saveQuestionnair = () => {
    if (Object.entries(answers).length < 1) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please answer at least one question!!',
        life: 3000,
      });
      return;
    }
    const payload = Object.entries(answers).map(([questionId, answer]) => ({
      questionId: Number(questionId),
      answer: answer === 'yes' ? true : false,
    }));

    memberservice.saveMemberQuestionnair(payload, memberData.id).subscribe({
      next: response => {
        console.log('Save successful', response);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Answers Updated Successfully!!',
          life: 3000,
        });
      },
      error: error => {
        console.error('Save failed', error);
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'Something went wrong!!',
          life: 3000,
        });
      },
    });
  };

  useEffect(() => {
    dataSource$().subscribe(pageData => setQuestionnairs(pageData));

    memberservice.getMemberQuestionnair(memberData.id).subscribe(savedAnswers => {
      const mappedAnswers = savedAnswers.reduce((acc, { questionId, answer }) => {
        acc[questionId] = answer ? 'yes' : 'no';
        return acc;
      }, {});
      setAnswers(mappedAnswers);
    });
  }, [memberData.id]);

  return (
    <>
      <Toast ref={toast} />
      <Grid container spacing={3}>
        {questionnaires.map(({ id, question }) => (
          <Grid item xs={12} sm={6} md={3} key={id}>
            <Typography variant="h6">{question}</Typography>
            <ToggleButtonGroup
              color="primary"
              value={answers[id] || ''}
              exclusive
              onChange={(e, value) => handleToggle(id, value)}
              aria-label={`question-${id}`}>
              <ToggleButton size="small" value="yes" aria-label="yes">
                Yes
              </ToggleButton>
              <ToggleButton size="small" value="no" aria-label="no">
                No
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        ))}
        <Grid item xs={12} container justifyContent="flex-end">
          <Button color="secondary" onClick={() => saveQuestionnair()}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MemberQuestionnair;
