import Grid from "@material-ui/core/Grid";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ClientService } from "../../remote-api/api/client-services";
import ClientDetails from "./client.details.component";
import ClientListComponent from "./client.list.component";


const clientService = new ClientService();
let csTableData$ = clientService.getClients();


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Client() {
  const history = useHistory();
  const query = useQuery();

  const [isEdit, setIsEdit] = React.useState(false);

  const [rows, setRows] = React.useState([]);

  const [opendetails, setOpenDetails] = React.useState(false);

  const [clientType, setClientType] = React.useState("RETAIL");


  const columns = [
    { field: "code", headerName: "Client Code", width: 350 },
    { field: "firstName", headerName: "Client Name", width: 350 },
    { field: "clientTypeCd", headerName: "Client type", width: 350 },
    {
      field: "displayName",
      headerName: "Name",
      // type: 'number',
      width: 100,
    },
    {
      field: "contact",
      headerName: "Contact Number",
      // type: 'number',
      width: 300,
    },
    {
      field: "action",
      headerName: "Action",
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      width: 300,
      renderCell: (params) => {
        const onClickEdit = () => {
          const { id } = params.row
          history.push(`/client/clients/${id}?mode=edit`);
          // populateDetails(obj);
        };

        const onClickDelete = () => {
          let obj = extractObject(params);
        };

        return <div>
          <CreateIcon style={{ cursor: "pointer", color: "#626BDA" }} onClick={onClickEdit} />
          <DeleteIcon style={{ cursor: "pointer", color: "#626BDA", marginLeft: "5px" }} onClick={onClickDelete} />
        </div>
      }
      // valueGetter: (params) =>
      //   `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    },
  ];


  const extractObject = (params) => {

    const api = params.api;
    const fields = api
      .getAllColumns()
      .map((c) => c.field)
      .filter((c) => c !== "__check__" && !!c);
    const thisRow = {};

    fields.forEach((f) => {
      thisRow[f] = params.getValue(f);
    });
    return thisRow;

  }

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              code: ele.clientBasicDetails.code,
              firstName: ele.clientBasicDetails.firstName,
              clientTypeCd: ele.clientBasicDetails.clientTypeCd,
              displayName: ele.clientBasicDetails.displayName,
              contact: ele.clientBasicDetails.contactNos.length > 0 ? ele.clientBasicDetails.contactNos[0].contactNo : '',
              id: ele.id,
            });
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe()
    },
      [observable, setter]
    );
  };

  // useObservable(csTableData$, setRows);




  const populateDetails = (obj) => {
    
  }

  return (
    <div>
      {query.get("mode") === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            height: "2em",
            color: "#000",
            fontSize: "18px",
            fontWeight: 600
          }}
        >

          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Client Management- Create Client
          </span>

        </Grid>
      ) : null}

      {(() => {
        switch (query.get("mode")) {
          case 'viewList':
            return (
              <ClientListComponent rows={rows} columns={columns} />
            );
          case 'create':
            return (
              <ClientDetails />
            );
          default:
            history.push("/client/clients?mode=viewList");
        }
      })()}
    </div>

  )
}
