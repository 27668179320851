import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import AccessRightsAction from './access-rights/access.rights.action';
import AccessRightsComponent from './access-rights/access.rights.component';
import UsersActionComponent from './users/users.action.component';
import UsersComponent from './users/users.component';

const roleService = new RoleService();

export default function UsersRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props);
  if (hasAccess) {
    return (
      <div>
        <React.Fragment>
          <Switch>
            <Route exact path={path}>
              {' '}
              <AccessRightsComponent />{' '}
            </Route>
            <Route exact path={`${path}/access-rights`}>
              {' '}
              <AccessRightsComponent />{' '}
            </Route>
            <Route exact path={`${path}/access-rights/:roleName`}>
              {' '}
              <AccessRightsAction />{' '}
            </Route>
            <Route exact path={`${path}/users`}>
              {' '}
              <UsersComponent />{' '}
            </Route>
            <Route exact path={`${path}/users/:userName`}>
              {' '}
              <UsersActionComponent />{' '}
            </Route>
          </Switch>
        </React.Fragment>
      </div>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
