import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: red[500],
    marginRight: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function ChartDialog(props) {
  const classes = useStyles();
  const { open, data } = props;

  const handleClose = () => {
    props.handleChartDialog(false);
  };

  return (
    <Dialog
      style={{ boxShadow: 'none' }}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
      disableBackdropClick>
      <MuiDialogTitle disableTypography className={classes.root}>
        <div className={classes.header}>
          <Avatar aria-label="caption" className={classes.avatar}>
            {data.caption}
          </Avatar>
          <Typography variant="h3">{data.title}</Typography>
        </div>
        {handleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <DialogContent dividers>
        <DialogContentText></DialogContentText>
        {data.widContent}
      </DialogContent>
    </Dialog>
  );
}
