// import * as React from "react";
// import * as yup from "yup";
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { Formik, useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { CardService } from '../../remote-api/api/banks-services';
import { CardTypeService, FeesTypeService } from '../../remote-api/api/master-services';
import { PlanService } from '../../remote-api/api/plan-services';
import { ProductService } from '../../remote-api/api/product-services';
import Asterisk from '../../shared-components/components/red-asterisk';

const cardservice = new CardService();
const feestype = new FeesTypeService();
const cardtype = new CardTypeService();
const productservice = new ProductService();
const planservice = new PlanService();

let ct$ = cardtype.getCardTypes();
let ft$ = feestype.getFeesTypes();
let ps$ = planservice.getPlans();
let pdt$ = productservice.getProducts({page:0, size:10000});

const validationSchema = yup.object({
  cardType: yup.string('Choose Card Type').required('Card Type is required'),
  feesType: yup.string('Choose Fees Type').required('Fees Type is required'),
  cardRate: yup.string('Enter Card Rate').required('Card Rate is required'),
  productData: yup.object().shape({
    name: yup.string('Choose Product').required('Product is required'),
  }),
  planData: yup.object().shape({
    name: yup.string('Choose Plan').required('Plan is required'),
  }),
});

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: 182,
  },
}));

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

export default function CardDetailsComponent(props) {
  const query2 = useQuery1();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [cardTypes, setCardTypes] = React.useState([]);
  const [feesTypes, setFeesTypes] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [planList, setPlanList] = React.useState([]);
  const [cardCode, setCardCode] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const handleValidFromDate = date => {
    setSelectedDate(date);
    const timestamp = new Date(date).getTime();
    formik.setFieldValue('validFrom', timestamp);
  };

  const formik = useFormik({
    initialValues: {
      code: '',
      cardType: '',
      feesType: '',
      currency: '',
      cardRate: '',
      withPhoto: false,
      validFrom: new Date().getTime(),
      product: '',
      plan: '',
      description: '',
      planData: '',
      productData: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit();
    },
  });

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  //plan API
  const useObservable2 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.name,
              id: ele.id,
            });
          });
          setter(tableArr);
        }
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  //product API
  const useObservable3 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        tableArr.push({
          name: 'All',
          id: 'all',
        });
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.productBasicDetails.name,
              id: ele.id,
            });
          });
          setter(tableArr);
        }
        populateData(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable(ct$, setCardTypes);
  useObservable(ft$, setFeesTypes);
  // useObservable2(ps$, setPlanList);
  useObservable3(pdt$, setProductList);

  const handleSwitchChange = e => {
    const { name, checked } = e.target;
    formik.setFieldValue('withPhoto', checked);
  };

  const handleSubmit = () => {
    let payload = {
      cardType: formik.values.cardType,
      feesType: formik.values.feesType,
      currency: formik.values.currency,
      cardRate: formik.values.cardRate,
      withPhoto: formik.values.withPhoto,
      validFrom: new Date(selectedDate).getTime(),
      product: formik.values.product,
      plan: formik.values.plan,
      description: formik.values.description,
      code: cardCode,
    };
    if (query2.get('mode') === 'create') {
      cardservice.saveCard(payload).subscribe(res => {
        history.push(`/bank-management/cards?mode=viewList`);
        // window.location.reload();
      });
    }
    if (query2.get('mode') === 'edit') {
      cardservice.editCard(payload, id).subscribe(res => {
        history.push(`/bank-management/cards?mode=viewList`);
        // window.location.reload();
      });
    }
  };

  // React.useEffect(() => {
  //     if (id) {
  //         populateData(id);
  //     }
  // }, [id]);

  const populateData = prodlist => {
    if (id) {
      cardservice.getCardDetails(id).subscribe(values => {
        setCardCode(values.code);
        formik.setValues({
          cardType: values.cardType,
          feesType: values.feesType,
          currency: values.currency,
          cardRate: values.cardRate,
          withPhoto: values.withPhoto,
          validFrom: values.validFrom,
          product: values.product,
          plan: values.plan,
          description: values.description,
        });

        setSelectedDate(new Date(values.validFrom));

        prodlist.forEach(p => {
          if (p.id === values.product && values.product !== '') {
            formik.setFieldValue('productData', p);
            formik.setFieldValue('product', values.product);
            planservice.getPlanFromProduct(values.product).subscribe(pl => {
              if (pl.length > 0) {
                // pl.push({
                //   name: 'All',
                //   id: '',
                // });
                setPlanList(pl);
                pl.forEach(plandata => {
                  if (plandata.id === values.plan) {
                    formik.setFieldValue('planData', plandata);
                    formik.setFieldValue('plan', values.plan);
                  }
                });
              }
            });
          }
          if (values.product === '') {
            // formik.setFieldValue('productData', {
            //   name: 'All',
            //   id: '',
            // });
            formik.setFieldValue('product', '');
            // formik.setFieldValue('planData', {
            //   name: 'All',
            //   id: '',
            // });
            formik.setFieldValue('plan', '');
          }
        });
        // getPlans(values.product);
      });
    }
  };

  const getPlans = productId => {
    if (productId === 'all') {
      let pageRequest = {
        page: 0,
        size: 10000,
        summary: true,
        active: true,
      };
      planservice.getPlans(pageRequest).subscribe(res => {
        let data = [
          {
            name: 'All',
            id: 'all',
          },
          ...res.content,
        ];
        if (res.content.length > 0) {
          setPlanList(data);
        }
      });
    } else {
      planservice.getPlanFromProduct(productId).subscribe(res => {
        let data = [
          {
            name: 'All',
            id: 'all',
          },
          ...res,
        ];
        if (res.length > 0) {
          setPlanList(data);
        }
      });
    }
  };

  const handleClose = () => {
    history.push(`/bank-management/cards?mode=viewList`);
    // window.location.reload();
  };

  const handlePlanChange = (e, value) => {
    if (value && value.id !== '') {
      formik.setFieldValue('planData', value);
      formik.setFieldValue('plan', value.id);
    }
    if (!value || value.id === '') {
      // formik.setFieldValue('planData', {
      //   name: 'All',
      //   id: '',
      // });
      formik.setFieldValue('plan', '');
    }
  };

  const handleProductChange = (e, value) => {
    if (value && value.id !== '') {
      formik.setFieldValue('productData', value);
      formik.setFieldValue('product', value.id);
      setPlanList([]);
      getPlans(value.id);
    }
    formik.setFieldValue('planData', {
      name: 'All',
      id: '',
    });

    // if (!value || value.id === '') {
      // formik.setFieldValue('productData', {
      //   name: 'All',
      //   id: '',
      // });
      // formik.setFieldValue('product', '');
      // formik.setFieldValue('plan', '');
    // }
  };

  return (
    <Paper elevation="none">
      <Box p={3} my={2}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={4}>
              <FormControl
                className={classes.formControl}
                // required
                error={formik.touched.cardType && Boolean(formik.errors.cardType)}
                helperText={formik.touched.cardType && formik.errors.cardType}>
                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                  Card Type <Asterisk/>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="cardType"
                  id="demo-simple-select"
                  value={formik.values.cardType}
                  onChange={formik.handleChange}>
                  {cardTypes.map(ele => {
                    return (
                      <MenuItem key={ele.code} value={ele.code}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem value="Individual">Individual</MenuItem>
                                    <MenuItem value="Organization">Organization</MenuItem> */}
                </Select>
                {formik.touched.cardType && Boolean(formik.errors.cardType) && (
                  <FormHelperText>{formik.touched.cardType && formik.errors.cardType}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                className={classes.formControl}
                // required
                error={formik.touched.feesType && Boolean(formik.errors.feesType)}
                helperText={formik.touched.feesType && formik.errors.feesType}>
                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                  Fees Type <Asterisk/>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="feesType"
                  id="demo-simple-select"
                  value={formik.values.feesType}
                  onChange={formik.handleChange}>
                  {feesTypes.map(ele => {
                    return (
                      <MenuItem key={ele.code} value={ele.code}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem value="Individual">Individual</MenuItem>
                                    <MenuItem value="Organization">Organization</MenuItem> */}
                </Select>
                {formik.touched.feesType && Boolean(formik.errors.feesType) && (
                  <FormHelperText>{formik.touched.feesType && formik.errors.feesType}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="standard-basic"
                name="cardRate"
                value={formik.values.cardRate}
                onChange={formik.handleChange}
                error={formik.touched.cardRate && Boolean(formik.errors.cardRate)}
                helperText={formik.touched.cardRate && formik.errors.cardRate}
                label={<span>Card Rate  <Asterisk/></span>}
                
                // required
              />
            </Grid>

            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {/* <Grid container justify="space-around"> */}
                <KeyboardDatePicker
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  autoOk
                  id="date-picker-inline"
                  label="Date Of Inauguration"
                  value={selectedDate}
                  onChange={handleValidFromDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change ing date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch checked={formik.values.withPhoto} color="primary" onChange={handleSwitchChange} name="checkedA" />
                }
                label="With Photo"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={4}>
              <Autocomplete
                id="combo-box-demo"
                options={productList}
                getOptionLabel={option => option.name}
                value={formik.values.productData}
                style={{ width: '50%' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={<span>Product <Asterisk/></span>}
                    // required
                    error={formik.touched.productData && Boolean(formik.errors.productData)}
                    helperText={formik.touched.productData && formik.errors.productData}
                  />
                )}
                name="productData"
                onChange={handleProductChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="combo-box-demo"
                options={planList}
                getOptionLabel={option => option.name}
                value={formik.values.planData}
                style={{ width: '50%' }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={<span>Plan <Asterisk/></span>}
                    // required
                    error={formik.touched.planData && Boolean(formik.errors.planData)}
                    helperText={formik.touched.planData && formik.errors.planData}
                  />
                )}
                name="planData"
                onChange={handlePlanChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-multiline-flexible"
                label="Description"
                multiline
                rowsMax={4}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="primary" style={{ marginRight: '5px' }} type="submit">
                Save
              </Button>
              <Button variant="contained" onClick={handleClose} className="p-button-text">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
}
