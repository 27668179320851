// import * as React from "react";
// import * as yup from "yup";
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { OrganizationTypeService } from '../../remote-api/api/master-services';
import { TaxService } from '../../remote-api/api/tax-services';
import { Button } from 'primereact/button';

const taxservice = new TaxService();
const orgtypeservice = new OrganizationTypeService();
let pt$ = taxservice.getParentTaxes();
let ot$ = orgtypeservice.getOrganizationTypes();

const taxvalueRegExp = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
const validationSchema = yup.object({
  name: yup.string('Enter Name').required('Name is required'),
  type: yup.string('Choose Tax type').required('Tax Type is required'),
  taxvalue: yup
    .string('Choose Tax value')
    .required('Tax Value is required')
    .matches(taxvalueRegExp, 'Tax value is not valid'),
  sortOrder: yup.string('Choose sort order').required('Sort order is required'),
  orgTypeCd: yup.string('Choose Parent Tax').required('Parent Tax is required'),
  effectiveFrom: yup.string('Choose effective from date').required('Effective from Date is required'),
});

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: 182,
  },
}));

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

export default function TaxDetails(props) {
  const sortinOrders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { id } = useParams();
  const query2 = useQuery1();
  const history = useHistory();
  const classes = useStyles();

  const [effectiveFromDate, setEffectiveFromDate] = React.useState(new Date());
  const [effectiveToDate, setEffectiveToDate] = React.useState(null);
  const [parentTaxes, setParentTaxes] = React.useState([]);
  const [orgTypes, setOrgTypes] = React.useState([]);
  const [showMessage, setShowMessage] = React.useState(false);

  const handleEffectiveFromDate = date => {
    setEffectiveFromDate(date);
    const timestamp = new Date(date).getTime();
    formik.setFieldValue('effectiveFrom', timestamp);

    const oneYearLater = new Date(timestamp);
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

    setEffectiveToDate(oneYearLater.getTime());
    // setEffectiveToDate(timestamp)
  };

  useEffect(() => {
    const currentDate = new Date(); // Get the current date
    const timestamp = currentDate.getTime();
    formik.setFieldValue('effectiveFrom', timestamp);

    const oneYearLater = new Date(timestamp);
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

    setEffectiveToDate(oneYearLater.getTime());
  }, []);

  const handleEffectiveToDate = date => {
    console.log(date);
    setEffectiveToDate(date);
    const timestamp = new Date(date).getTime();
    formik.setFieldValue('effectiveUpto', timestamp);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      taxvalue: '',
      sortOrder: 1,
      isRefundable: false,
      effectiveFrom: new Date().getTime(),
      effectiveUpto: null,
      parentTaxId: '',
      orgTypeCd: '',
      pOrgData: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (formik.values.effectiveUpto && formik.values.effectiveFrom > formik.values.effectiveUpto) {
        setShowMessage(true);
        return;
      }
      handleSubmit();
    },
  });

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };
  useObservable(ot$, setOrgTypes);

  const useObservable2 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.name,
              id: ele.id,
            });
          });
          setter(tableArr);
          if (id) {
            populateData(tableArr);
          }
        }
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable2(pt$, setParentTaxes);
  const handleSwitchChange = e => {
    const { name, checked } = e.target;
    formik.setFieldValue('isRefundable', checked);
  };

  const handleSubmit = () => {
    let payload = {
      name: formik.values.name,
      type: formik.values.type,
      value: formik.values.taxvalue,
      sortOrder: formik.values.sortOrder,
      isRefundable: formik.values.isRefundable,
      effectiveFrom: new Date(effectiveFromDate).getTime(),
      orgTypeCd: formik.values.orgTypeCd,
    };
    if (formik.values.orgTypeCd === 'OT117246') {
      payload['parentTaxId'] = formik.values.parentTaxId;
    }
    if (effectiveToDate !== '' && effectiveToDate !== null) {
      payload['effectiveUpto'] = new Date(effectiveToDate).getTime();
    }
    if (query2.get('mode') === 'create') {
      taxservice.saveTax(payload).subscribe(res => {
        history.push(`/taxes?mode=viewList`);
        // window.location.reload();
      });
    }
    if (query2.get('mode') === 'edit') {
      taxservice.editTax(payload, id).subscribe(res => {
        history.push(`/taxes?mode=viewList`);
        // window.location.reload();
      });
    }
  };

  // React.useEffect(() => {
  //     if (id) {
  //         populateData(id);
  //     }
  // }, [id]);

  const populateData = tableArr => {
    if (id) {
      taxservice.getTaxDetails(id).subscribe(values => {
        let pOrg = {
          name: 'All',
          id: '',
        };

        tableArr.forEach(ele => {
          if (ele.id === values.parentTaxId) {
            pOrg = ele;
          }
        });

        formik.setValues({
          name: values.name,
          type: values.type,
          taxvalue: values.value,
          sortOrder: values.sortOrder,
          isRefundable: values.isRefundable,
          effectiveFrom: values.effectiveFrom,
          effectiveUpto: values.effectiveUpto,
          parentTaxId: values.parentTaxId,
          pOrgData: pOrg,
          orgTypeCd: values.orgTypeCd,
        });

        setEffectiveFromDate(new Date(values.effectiveFrom));
        console.log(values.effectiveUpto);
        if (values.effectiveUpto) {
          setEffectiveToDate(new Date(values.effectiveUpto));
        }
      });
    }
  };

  const handlePChange = (e, value) => {
    formik.setFieldValue('pOrgData', value);
    formik.setFieldValue('parentTaxId', value.id);
  };

  const handleClose = () => {
    history.push(`/taxes?mode=viewList`);
    // window.location.reload();
  };

  const handleSnackClose = (event, reason) => {
    setShowMessage(false);
  };

  return (
    <Paper elevation="none">
      <Snackbar open={showMessage} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} variant="filled" severity="error">
          Effective upto should be greater than Effective from
        </Alert>
      </Snackbar>
      <Box p={3} my={2}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="standard-basic"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                label={
                  <span>
                    Name<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                className={classes.formControl}
                // required
                error={formik.touched.type && Boolean(formik.errors.type)}>
                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                  <span>
                    Tax Type<span style={{ color: 'red' }}>*</span>
                  </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="type"
                  id="demo-simple-select"
                  value={formik.values.type}
                  onChange={formik.handleChange}>
                  <MenuItem key="percentage" value="PERCENTAGE">
                    PERCENTAGE
                  </MenuItem>
                  <MenuItem key="fixed" value="FIXED">
                    FIXED
                  </MenuItem>
                </Select>
                {formik.touched.type && Boolean(formik.errors.type) && <FormHelperText>{formik.errors.type}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                id="standard-basic"
                name="taxvalue"
                value={formik.values.taxvalue}
                onChange={formik.handleChange}
                error={formik.touched.taxvalue && Boolean(formik.errors.taxvalue)}
                helperText={formik.touched.taxvalue && formik.errors.taxvalue}
                label={
                  <span>
                    Tax Value<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={4}>
              <FormControl
                className={classes.formControl}
                // required
                error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
                helperText={formik.touched.sortOrder && formik.errors.sortOrder}>
                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                  <span>
                    Sort Order<span style={{ color: 'red' }}>*</span>
                  </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="sortOrder"
                  id="demo-simple-select"
                  value={formik.values.sortOrder}
                  onChange={formik.handleChange}>
                  {sortinOrders.map(ele => {
                    return (
                      <MenuItem key={ele} value={ele}>
                        {ele}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formik.touched.sortOrder && Boolean(formik.errors.sortOrder) && (
                  <FormHelperText>{formik.touched.sortOrder && formik.errors.sortOrder}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              {query2.get('mode') === 'edit' ? (
                <FormControl
                  className={classes.formControl}
                //   required
                  error={formik.touched.orgTypeCd && Boolean(formik.errors.orgTypeCd)}
                  helperText={formik.touched.orgTypeCd && formik.errors.orgTypeCd}>
                  <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                    <span>
                      Parent Tax<span style={{ color: 'red' }}>*</span>
                    </span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="orgTypeCd"
                    id="demo-simple-select"
                    readOnly={true}
                    value={formik.values.orgTypeCd}
                    onChange={formik.handleChange}>
                    {orgTypes.map(ele => {
                      return (
                        <MenuItem key={ele.code} value={ele.code}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.orgTypeCd && Boolean(formik.errors.orgTypeCd) && (
                    <FormHelperText>{formik.touched.orgTypeCd && formik.errors.orgTypeCd}</FormHelperText>
                  )}
                </FormControl>
              ) : (
                <FormControl
                  className={classes.formControl}
                  //   required
                  error={formik.touched.orgTypeCd && Boolean(formik.errors.orgTypeCd)}
                  helperText={formik.touched.orgTypeCd && formik.errors.orgTypeCd}>
                  <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                    <span>
                      Parent Tax<span style={{ color: 'red' }}>*</span>
                    </span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="orgTypeCd"
                    id="demo-simple-select"
                    value={formik.values.orgTypeCd}
                    onChange={formik.handleChange}>
                    {orgTypes.map(ele => {
                      return (
                        <MenuItem key={ele.code} value={ele.code}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.orgTypeCd && Boolean(formik.errors.orgTypeCd) && (
                    <FormHelperText>{formik.touched.orgTypeCd && formik.errors.orgTypeCd}</FormHelperText>
                  )}
                </FormControl>
              )}
            </Grid>
            <Grid item xs={4}>
              {formik.values.orgTypeCd === 'OT117246' && (
                <Grid item xs={4}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={parentTaxes}
                    getOptionLabel={option => option.name}
                    value={formik.values.pOrgData}
                    // style={{ width: "50%" }}
                    renderInput={params => <TextField {...params} label="" />}
                    name="parentTaxId"
                    onChange={handlePChange}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {/* <Grid container justify="space-around"> */}
                <KeyboardDatePicker
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  autoOk={true}
                  id="date-picker-inline"
                  label="Effective from"
                  value={effectiveFromDate}
                  onChange={handleEffectiveFromDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change ing date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {/* <Grid container justify="space-around"> */}
                <KeyboardDatePicker
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  autoOk={true}
                  id="date-picker-inline"
                  label="Effective upto"
                  value={effectiveToDate}
                  onChange={handleEffectiveToDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change ing date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.isRefundable}
                    color="primary"
                    onChange={handleSwitchChange}
                    name="checkedA"
                  />
                }
                label="Is Refundable"
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="primary" style={{ marginRight: '5px' }} type="submit">
                Save
              </Button>
              <Button variant="contained" onClick={handleClose} className="p-button-text">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
}
