import { Snackbar } from '@material-ui/core';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import 'date-fns';
import * as React from 'react';
import { useEffect } from 'react';
import { HierarchyService } from '../../../remote-api/api/hierarchy-services';
import { UsersService } from '../../../remote-api/api/user-management-service';
const orgtypeservice = new HierarchyService();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const usersService = new UsersService();
let usersService$ = usersService.getUsers();
const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: 182,
  },
}));

export default function EmployeeAddModal(props) {
  const classes = useStyles();
  const [remarks, setRemarks] = React.useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [employeeList, setEmployeelist] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  useEffect(() => {
    if (props.selectedNode?.user?.userId) {
      props.userList.forEach(usr => {
        if (usr.id === props.selectedNode?.user?.userId) {
          setRemarks(usr);
        }
      });
    }
  }, [props.userList]);
  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        result.content.forEach((r, i) => {
          r['id'] = i;
          r['name'] = r.userName;
        });

        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable(usersService$, setEmployeelist);
  const handleClose = () => {
    props.closeEmployeeModal();
  };

  const handleModalSubmit = () => {
    if (props.selectedNode && remarks !== '' && remarks !== null) {
      let payload = {
        id: props.selectedNode.user?.id ? props.selectedNode.user?.id : null,
        userId: remarks.id,
        userName: remarks.username,
        userType: remarks.type,
      };
      orgtypeservice.addUser(payload, props.selectedNode.id ? props.selectedNode.id : null).subscribe(res => {
        setRemarks('');
        props.submitEmployeeModal();
      });
    }
    if (!props.selectedNode) {
      setSnackbarMessage('Position not selected');
      setSnackbarOpen(true);
    }
    if (remarks === null || remarks === '') {
      setSnackbarMessage('Please choose a user/agent');
      setSnackbarOpen(true);
    }
    // invoiceservice.revertInvoice(remarks,props.selectedInvoiceForReversal).subscribe(ele=> {
    //     props.closeEmployeeModal();
    // })
  };

  const handleChange = e => {
    setRemarks(e.target.value);
  };

  return (
    <Dialog
      open={props.employeeModal}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus>
      <DialogTitle id="form-dialog-title">{props.headerText}</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            name="remarks"
            id="demo-simple-select"
            value={remarks}
            onChange={handleChange}>
            {props.userList.map(ele => {
              return (
                <MenuItem value={ele} key={ele.id}>
                  {ele.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Snackbar
          open={snackbarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}>
          <Alert onClose={handleSnackbarClose} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" className="p-button-text">
          Cancel
        </Button>
        <Button onClick={handleModalSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
