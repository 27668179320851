import Grid from "@material-ui/core/Grid";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProspectManagementForm from './prospect-management-form';

export default function ProspectActionComponent(props) {
    const query = useQuery();
    const history = useHistory();

    const clientType = "RETAIL";

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    return (
        <div>
            <Grid item xs={12} style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "20px",
                height: "2em",
                color: "#000",
                fontSize: "18px",
            }}
            >
                <span
                    style={{
                        fontWeight: "600",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "5px",
                    }}
                >
                    Prospect Management - Edit Prospect
                </span>
            </Grid>
            {(() => {
                switch (query.get("mode")) {
                    case 'edit':
                        return (
                            <ProspectManagementForm clientType={clientType} prospectId={props.match?.params?.id} />
                        );
                    default:
                        history.push(`/prospects/${props.match.params.id}?mode=edit`);
                }
            })()}
        </div>
    );
}