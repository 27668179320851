import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { BenefitService } from '../../remote-api/eo2v2-remote-api';
import { Button } from 'primereact/button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const benefitService = new BenefitService();

const ServiceDetails = ({
  x,
  i,
  handleProviderChangeInService,
  providerList,
  handleBenefitChangeInService,
  autocompleteFilterChange,
  benefitOptions,
  handleChangeDiagnosis,
  handleChangeCodeStandard,
  handleChangeIntervention,
  handleEstimateCostInService,
  handleRemoveServicedetails,
  handleAddServicedetails,
  serviceDetailsList,
  classes,
  id,
  memberBasic,
  interventions,
  serviceList,
}) => {
  const [benefitId, setBenefitId] = React.useState();
  useEffect(() => {
    if (x.benefitId) {
      setBenefitId(x.benefitId);
    }
  }, [x.benefitId]);

  return (
    <Grid container spacing={3} key={i}>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
            Provider
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="providerId"
            fullWidth
            value={x.providerId}
            onChange={e => handleProviderChangeInService(e, i)}>
            {providerList.map(ele => {
              return (
                <MenuItem key={ele.id} value={ele.id}>
                  {ele.providerBasicDetails.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={9}> */}
      <Grid item xs={12} sm={6} md={3}>
        <FormControl className={classes.formControl} fullWidth>
          <Autocomplete
            name="benefitId"
            defaultValue={x?.benefitId ? x?.benefitId : undefined}
            value={x.benefitId ? benefitOptions?.find(item => item?.benefitStructureId === x?.benefitId) : null}
            onChange={(e, val) => {
              // setIntervention([]);
              // getIntervemntions(val);
              handleBenefitChangeInService(val, i);
              setBenefitId(val.benefitStructureId);
            }}
            id="checkboxes-tags-demo"
            filterOptions={autocompleteFilterChange}
            options={benefitOptions}
            getOptionLabel={option => option.label ?? benefitOptions.find(benefit => benefit?.value == option)?.label}
            getOptionSelected={(option, value) => option?.value === value}
            renderOption={(option, { selected }) => <React.Fragment>{option?.label}</React.Fragment>}
            renderInput={params => <TextField {...params} label="Benefit Provider" />}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={1}>
        <FormControl
          // className={classes.formControl}
          style={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
            Standard
          </InputLabel>
          <Select
            label="Code Standard"
            name="codeStandard"
            value={x.codeStandard}
            variant="standard"
            fullWidth
            onChange={(e) => {
              handleChangeCodeStandard(e.target.value, i);
            }}>
            <MenuItem value="ICD">ICD</MenuItem>
            <MenuItem value="SHA">SHA</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl className={classes.formControl} fullWidth>
          <Autocomplete
            name="intervention"
            defaultValue={x.interventionCode ? x.interventionCode : null}
            // value={x.interventionCode ? interventions?.find(item =>{ if(item?.value === x?.interventionCode)return item}) : null}
            value={
              x.interventionCode
                ? interventions?.find(item => {
                    if (item?.value === x?.interventionCode) return item;
                  })
                : null
            }
            onChange={(e, val) => {
              // getServices(val);
              handleChangeIntervention(val, i);
            }}
            id="checkboxes-tags-demo"
            // filterOptions={autocompleteFilterChange}
            options={interventions}
            // options={selectedBenefit}
            getOptionLabel={option => option.label}
            renderInput={params => (
              <TextField
                {...params}
                label="Intervention"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null, // This removes the clear icon
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: '8px',
        }}>
        <FormControl className={classes.formControl} fullWidth>
          <Autocomplete
            name="diagnosis"
            defaultValue={x.diagnosis ? x.diagnosis : null}
            value={x.diagnosis ? serviceList?.find(item => item?.value === x?.diagnosis) : null}
            onChange={(e, val) => {
              // getServices(val);
              handleChangeDiagnosis(val, i);
            }}
            id="checkboxes-tags-demo"
            // filterOptions={autocompleteFilterChange}
            options={serviceList}
            // options={selectedBenefit}
            getOptionLabel={option => option.label}
            // getOptionSelected={(option, value) =>
            //   option?.interventionId === value
            // }
            // renderOption={(option, { selected }) => (
            //   <React.Fragment>{option?.label}</React.Fragment>
            // )}

            renderInput={params => (
              <TextField {...params} label={i === 0 ? 'Primary Diagnosis' : 'Diagnosis'} variant="standard" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          id="standard-basic"
          type="number"
          name="estimatedCost"
          value={x?.estimatedCost}
          onChange={e => handleEstimateCostInService(e, i)}
          label="Estimated Cost"
        />
      </Grid>
      {/* </Grid> */}

      <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', alignItems: 'center' }}>
        {serviceDetailsList.length !== 1 && (
          <Button
            className={`mr10 p-button-danger ${classes.buttonSecondary}`}
            onClick={() => handleRemoveServicedetails(i)}
            variant="contained"
            color="secondary"
            style={{ marginLeft: '5px' }}>
            <DeleteIcon />
          </Button>
        )}
        {serviceDetailsList.length - 1 === i && (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonPrimary}
            style={{ marginLeft: '5px' }}
            onClick={handleAddServicedetails}>
            <AddIcon />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ServiceDetails;
