import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import MembersDetails from './components/members.list.component';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ViewPolicyHistory from './components/member.details.view.policy.history';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function MemberEnquiryComponent() {
  const history = useHistory();
  const query = useQuery();
  const { path } = useRouteMatch();
  return (
    // <div>
    //   {query.get('mode') === 'create' ? (
    //     <Grid
    //       item
    //       xs={12}
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'flex-start',
    //         marginBottom: '20px',
    //         height: '2em',
    //         color: '#000',
    //         fontSize: '18px',
    //         fontWeight: 600,
    //       }}>
    //       <span
    //         style={{
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }}>
    //         Member Details Component
    //       </span>
    //     </Grid>
    //   ) : null}

    //   {(() => {
    //     switch (query.get('mode')) {
    //       case 'viewList':
    //         return <MembersDetails />;
    //       case 'create':
    //         return <h3>Component creation pending</h3>
    //       default:
    //         history.push('/member-enquiry?mode=viewList');
    //     }
    //   })()}
    // </div>
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          <MembersDetails />
        </Route>
        <Route exact path={`${path}/view-policy-history/:id`}>
          <ViewPolicyHistory />
        </Route>
      </Switch>
    </React.Fragment>
  );
}
