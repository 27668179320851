import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Plan from './components/plan.component';
import PlanDetailsComponent from './components/plan.details.component';

const roleService = new RoleService();

export default function PlansRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props, 'PLAN');
  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Plan />{' '}
          </Route>
          <Route exact path="/plans" component={Plan} />
          <Route exact path="/plans/:id" component={PlanDetailsComponent} />
        </Switch>
      </React.Fragment>
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
