import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import FeesListComponent from "./fees.list.component";
import FeesDetailsComponent from "./fees.details.component";


function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
export default function Fees() {
    const history = useHistory();
    const query = useQuery();
  
    return (
        <div>
           {query.get("mode") === "create" ? (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "20px",
              height: "2em",
              color: "#000",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
           
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Fees Config- Create Fee
              </span>
            
          </Grid>
          ) : null}
    
          {(() => {
            switch (query.get("mode")) {
              case "viewList":
                return <FeesListComponent />;
              case "create":
                return <FeesDetailsComponent />;
              default:
                history.push("/fees?mode=viewList");
            }
          })()}
        </div>
      );
}