import DateFnsUtils from '@date-io/date-fns';
import { Button } from 'primereact/button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect } from 'react';
import {
  ClientTypeService,
  CurrencyService,
  GroupTypeService,
  ProductMarketService,
  ProductTypeService,
} from '../../../remote-api/api/master-services';
import { ProductService } from '../../../remote-api/api/product-services';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: 100,
    padding: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 182,
    width: '90%',
  },
  header: {
    paddingTop: 10,
    paddingBottom: 10,
    color: 'primary',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  gridItem: {
    marginBottom: theme.spacing(2)
  }
}));

const producttypeservice = new ProductTypeService();
const currencyservice = new CurrencyService();
const prodmarketservice = new ProductMarketService();
const grouptypeservice = new GroupTypeService();
const clienttypeservice = new ClientTypeService();
const productservice = new ProductService();

let cs$ = currencyservice.getCurrencies();
let pt$ = producttypeservice.getProductTypes();
let gt$ = grouptypeservice.getGroupTypes();
let ct$ = clienttypeservice.getCleintTypes();
let pm$ = prodmarketservice.getProductMarket();

export default function ProductBasicDetailsComponent(props) {
  const classes = useStyles();

  const today = new Date();
  const tommorow = new Date();

  const [currencyList, setCurrencyList] = React.useState([]);
  const [productTypes, setProductTypes] = React.useState([]);
  const [productMarkets, setProductMarkets] = React.useState([]);
  const [clientTypes, setClientTypes] = React.useState([]);
  const [groupTypes, setGroupMarkets] = React.useState([]);
  const [state, setState] = React.useState({
    productBasicDetailsForm: {
      productTypeId: 'PT585526',
      productTypeName: productTypes?.find(p => p.code === 'PT585526')?.name,
      name: '',
      productMarketId: 'PM243607',
      productMarketName: productMarkets?.find(p => p.code === 'PM243607')?.name,
      description: '',
      clientTypeId: '',
      groupTypeId: '',
      productCurrencyCd: '',
      validFrom: today,
      validUpTo: new Date(tommorow.setDate(today.getDate() + 365)),
      premiumCurrencyCd: '',
    },
  });
  const [showMessage, setShowMessage] = React.useState(false);

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable(cs$, setCurrencyList);
  useObservable(pt$, setProductTypes);
  useObservable(pm$, setProductMarkets);
  useObservable(gt$, setGroupMarkets);
  useObservable(ct$, setClientTypes);

  useEffect(() => {
    if ('productBasicDetails' in props.productDetails) {
      setState({
        productBasicDetailsForm: {
          ...props.productDetails.productBasicDetails,
        },
      });
    }
    // setFieldNamefromIds();
  }, [props.productDetails]);

  const setFieldNamefromIds = () => {
    const prodDetails = props.productDetails?.productBasicDetails;
    if (!prodDetails?.productTypeName && prodDetails?.productTypeId) {
      const formObj = {
        ...prodDetails,
        productTypeName: productTypes.find(p => p.code === prodDetails.productTypeId)?.name || '',
        productMarketName: productMarkets.find(p => p.code === prodDetails.productMarketId)?.name || '',
        clientTypeName: clientTypes.find(p => p.id === prodDetails.clientTypeId)?.name || '',
        groupTypeName: (prodDetails.groupTypeId && groupTypes.find(p => p.id === prodDetails.groupTypeId)?.name) || '',
      };
      setState({
        productBasicDetailsForm: formObj,
      });
      props.updateBasiDetails({
        productBasicDetailsForm: formObj,
      });
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const formObj = {
      ...state.productBasicDetailsForm,
      [name]: value,
      ...(name === 'productTypeId' && value && { productTypeName: productTypes.find(p => p.code === value).name }),
      ...(name === 'productMarketId' && value && { productMarketName: productMarkets.find(p => p.code === value).name }),
      ...(name === 'clientTypeId' && value && { clientTypeName: clientTypes.find(p => p.id === value).name }),
      ...(name === 'groupTypeId' && value && { groupTypeName: groupTypes.find(p => p.id === value).name }),
    };

    setState({
      productBasicDetailsForm: formObj,
    });

    props.updateBasiDetails({
      productBasicDetailsForm: formObj,
    });
  };

  const handleValidFromChange = date => {
    const formObj = {
      ...state.productBasicDetailsForm,
      validFrom: date,
    };
    setState({
      productBasicDetailsForm: formObj,
    });
    props.updateBasiDetails({
      productBasicDetailsForm: formObj,
    });
  };

  const handleValidToChange = date => {
    const formObj = {
      ...state.productBasicDetailsForm,
      validUpTo: date,
    };
    setState({
      productBasicDetailsForm: formObj,
    });
    props.updateBasiDetails({
      productBasicDetailsForm: formObj,
    });
  };

  const handleSubmit = e => {
    if (state.productBasicDetailsForm.validFrom > state.productBasicDetailsForm.validUpTo) {
      setShowMessage(true);
      return;
    }

    let payload = {
      productBasicDetails: {
        ...state.productBasicDetailsForm,
        validFrom: new Date(state.productBasicDetailsForm.validFrom).getTime(),
        validUpTo: new Date(state.productBasicDetailsForm.validUpTo).getTime(),
      },
    };

    const productId = localStorage.getItem('productId');
    if (productId) {
      productservice.editProduct(payload.productBasicDetails, productId, 1).subscribe(res => {
        if (res.status === 200) {
          props.handleNextStep();
        }
      });
    } else {
      productservice.saveProductBasicDetails(payload.productBasicDetails).subscribe(res => {
        if (res.status === 201) {
          localStorage.setItem('productId', res.data.id);
          props.handleNextStep();
        }
      });
    }
  };

  const handleSnackClose = (event, reason) => {
    setShowMessage(false);
  };

  return (
    <Paper elevation='none' style={{padding: '16px'}}>
      <Grid container alignItems="center">
        <Snackbar open={showMessage} autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} variant="filled" severity="error">
            Valid upto should be greater than valid from
          </Alert>
        </Snackbar>

        <Grid item container>
          <Grid item xs={12}>
            <Grid item xs={12} sm={6} md={4} className={classes.header} color="inherit">
              <span>Product Basic Details</span>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" className={classes.gridItem}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }} color="inherit">
                Product Type
              </InputLabel>
              <Select
                name="productTypeId"
                color="inherit"
                value={state.productBasicDetailsForm.productTypeId}
                // renderValue={()=> productTypes.find(p => p.name === 'Health')}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}>
                {productTypes.map(ele => {
                  return (
                    <MenuItem key={ele.code} value={ele.code}>
                      {ele.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <TextField
                id="product-name"
                name="name"
                value={state.productBasicDetailsForm.name}
                onChange={handleChange}
                label="Name"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                Product Market
              </InputLabel>
              <Select
                name="productMarketId"
                value={state.productBasicDetailsForm.productMarketId}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}>
                {productMarkets.map(ele => {
                  return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <TextField
                id="product-desc"
                label="Description"
                name="description"
                multiline
                maxRows={10}
                rows={1}
                value={state.productBasicDetailsForm.description}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

        </Grid>

        <Grid item container alignItems="center" className={classes.gridItem} >
          {/* <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.formControl}>
              <TextField
                id="product-desc"
                label="Description"
                name="description"
                multiline
                maxRows={10}
                rows={1}
                value={state.productBasicDetailsForm.description}
                onChange={handleChange}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl} style={{ alignSelf: 'flex-end' }}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                Client Type
              </InputLabel>
              <Select
                name="clientTypeId"
                value={state.productBasicDetailsForm.clientTypeId}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}>
                {clientTypes.map(ele => {
                  return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {state.productBasicDetailsForm.clientTypeId === 'GROUP' ? (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                  Group Type
                </InputLabel>
                <Select
                  name="groupTypeId"
                  value={state.productBasicDetailsForm.groupTypeId}
                  onChange={handleChange}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  {groupTypes.map(ele => {
                    return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ) : (
              `Client Type: ${state.productBasicDetailsForm.clientTypeId}`
            )}
          </Grid>
        </Grid>

        <Grid item container alignItems="center" className={classes.gridItem} >
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                Product Currency
              </InputLabel>
              <Select
                name="productCurrencyCd"
                value={state.productBasicDetailsForm.productCurrencyCd}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}>
                {currencyList.map(ele => {
                  return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="valid-from"
                  autoOk={true}
                  label="Valid From"
                  name="validFrom"
                  value={state.productBasicDetailsForm.validFrom}
                  onChange={handleValidFromChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <KeyboardDatePicker
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="valid-upto"
                  autoOk={true}
                  label="Valid Upto"
                  name="validUpTo"
                  value={state.productBasicDetailsForm.validUpTo}
                  onChange={handleValidToChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl} style={{ marginTop: '16px' }}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                Premium Currency
              </InputLabel>
              <Select
                name="premiumCurrencyCd"
                value={state.productBasicDetailsForm.premiumCurrencyCd}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}>
                {currencyList.map(ele => {
                  return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item container alignItems="center" className={classes.gridItem} >
          {/* <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.formControl} style={{ marginTop: '16px' }}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                Premium Currency
              </InputLabel>
              <Select
                name="premiumCurrencyCd"
                value={state.productBasicDetailsForm.premiumCurrencyCd}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'Without label' }}>
                {currencyList.map(ele => {
                  return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>

        <Grid item container alignItems="center" className={classes.gridItem}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
            {!localStorage.getItem('productId') && (
              <Button variant="contained" onClick={handleSubmit}>
                Save and Next
              </Button>
            )}
            {/* <Button variant="contained" onClick={handleClose} className="p-button-text">
                    Cancel
                  </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
