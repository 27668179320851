import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
    // url: "http://localhost:8080/auth",
    // url: "http://192.168.100.96:8080/auth",
    //url: "http://15.206.136.38:8080/auth",

    // url: 'https://shaidentity.eo2cloud.com',
    url: "https://identity.eoxegen.com/",
    realm: "eo2v2",
    clientId: "eo2v2-web-client",
});

export default keycloak;