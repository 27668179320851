import { withStyles } from "@material-ui/styles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import MemberListComponent from "./member.list.component";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = (theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 0'
    }
});

function MembersComponent(props) {
    const history = useHistory();
    const query = useQuery();

    const { classes } = props;

    const handleOpen = () => {
        // history.push("/masters/parameters?mode=create");
    }

    const handleEdit = (row) => {
        // history.push(`/masters/parameters/${row.id}?mode=edit`);
    }

    return (
        <div>
            {(() => {
                switch (query.get("mode")) {
                    case 'viewList':
                        return (
                            <MemberListComponent />
                        );
                    case 'create':
                        return (
                            <div>Work In Progress...</div>
                        );
                    default:
                        history.push("/member-upload/member?mode=viewList");
                }
            })()}
        </div>
    );
}

export default withStyles(useStyles)(MembersComponent);