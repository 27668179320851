import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PremiumDesignAction from "./components/premium.design.action";
import PremiumDesignComponent from "./components/premium.design.component";

export default function PremiumRoutersComponent(props) {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path} > <PremiumDesignComponent /> </Route>
        <Route exact path="/premium" component={PremiumDesignComponent} />
        <Route exact path="/premium/:productId" > <PremiumDesignAction /> </Route>
      </Switch>
    </React.Fragment>
  );
}
