import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Receipts from './receipts/receipts.component';
import ReceiptDetails from './receipts/receipts.details.component';

const roleService = new RoleService();

export default function ReceiptRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props, 'RECEIPT');
  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Receipts />{' '}
          </Route>
          <Route exact path="/receipts" component={Receipts} />
          <Route exact path="/receipts/:id" component={ReceiptDetails} />
        </Switch>
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
