import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Policy from './policy/policy.component';
import PolicyDetails from './policy/policy.details.component';

const roleService = new RoleService();

export default function ProposerRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props);
  // if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Policy />{' '}
          </Route>
          <Route exact path="/policies" component={Policy} />
          <Route exact path="/policies/:id" component={PolicyDetails} />
        </Switch>
      </React.Fragment>
    );
  // }

  // return (
  //   <Redirect
  //     to={{
  //       pathname: '/unauthorized',
  //       state: {
  //         from: props.location,
  //       },
  //     }}
  //   />
  // );
}
