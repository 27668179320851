import { Button } from 'primereact/button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import ChipInput from 'material-ui-chip-input';
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import {
  BenefitService,
  ParameterComparisonTypeService,
  ParameterDataTypeService,
  ParameterRenderTypeService,
  ParametersService,
} from '../../remote-api/api/master-services';
import { defaultPageRequest } from '../../remote-api/models/page.request';
import { width } from '@material-ui/system';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const paramRenderTypeService = new ParameterRenderTypeService();
const paramDataTypeService = new ParameterDataTypeService();
const paramComparisonTypeService = new ParameterComparisonTypeService();
const parametersService = new ParametersService();
const benefitService = new BenefitService();

let paramRenderType$ = paramRenderTypeService.getParameterRenderTypes();
let paramDataType$ = paramDataTypeService.getParameterDataTypes();
let paramComparisonTypeService$ = paramComparisonTypeService.getParameterComparisonTypes();

const pageRequest = { ...defaultPageRequest };
pageRequest.size = 100000;
let benefitService$ = benefitService.getAllBenefit(pageRequest);

const useStyles = theme => ({
  root: {
    padding: 20,
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
  formControl: {
    minWidth: 182,
  },
  chipInputList: {
    '& .chipItem': {
      color: 'rgba(0, 0, 0, 0.87)',
      border: 'none',
      height: 32,
      display: 'inline-flex',
      outline: 'none',
      padding: 0,
      fontSize: '0.8125rem',
      boxSizing: 'border-box',
      transition:
        'background - color 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms, box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms',
      alignItems: 'center',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans - serif',
      whiteSpace: 'nowrap',
      borderRadius: 16,
      verticalAlign: 'middle',
      justifyContent: 'center',
      textDecoration: 'none',
      backgroundColor: '#e0e0e0',
      margin: '0 8px 8px 0',
    },
  },
  formGroupOuter: {
    flexWrap: 'nowrap',
    '& .pctlabel': {
      flexDirection: 'row',
      marginBottom: 0,
    },
  },
  benefitAutoComplete: {
    width: 280,
    '& .MuiInputBase-formControl': {
      maxHeight: 200,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
});

const initForm = {
  id: '',
  name: '',
  paramterComparisonTypeIds: [],
  paramterDataTypeId: '',
  paramterUiRenderTypeId: '',
  internalName: 'Random',
  type: true,
  isEligibleForProduct: true,
  isEligibleForPremium: true,
  parameterValues: [],
  paramterComparisonTypeNames: [],
  paramterDataTypeName: '',
  paramterUiRenderTypeName: '',
  start: '',
  end: '',
  count: '',
  benefits: [],
};

const parameterSchema = Yup.object().shape(
  {
    name: Yup.string().required('Parameter name is required'),
    paramterUiRenderTypeId: Yup.string().required('Parameter type is required'),
    paramterDataTypeId: Yup.string().when('paramterUiRenderTypeId', {
      is: '847343507192987648',
      then: Yup.string().required('Parameter datatype is required'),
    }),
    parameterValues: Yup.array().when('paramterUiRenderTypeId', {
      is: '847343672402427904',
      then: Yup.array().min(1, 'Parameter values are required'),
    }),
    paramterComparisonTypeIds: Yup.array().min(1, 'Parameter comparison type required'),
    isEligibleForProduct: Yup.boolean().when('isEligibleForPremium', {
      is: false,
      then: Yup.boolean().oneOf([true], 'Select Product or Premium or both'),
    }),
    isEligibleForPremium: Yup.boolean().when('isEligibleForProduct', {
      is: false,
      then: Yup.boolean().oneOf([true], 'Select Product or Premium or both'),
    }),
  },
  [['isEligibleForProduct', 'isEligibleForPremium']],
);

class ParameterFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parameterForm: { ...initForm },
      parameterUIRenderType: [],
      parameterDataType: [],
      parameterComparisonTypeList: [],
      benefitList: [],
    };
  }

  componentDidMount() {
    paramRenderType$.subscribe(response => {
      this.setState({
        ...this.state,
        parameterUIRenderType: response.content,
      });
    });
    paramDataType$.subscribe(response => {
      this.setState({
        ...this.state,
        parameterDataType: response.content,
      });
    });
    paramComparisonTypeService$.subscribe(response => {
      this.setState({
        ...this.state,
        parameterComparisonTypeList: response.content.map(r => ({ ...r, checked: false })),
      });
    });
    benefitService$.subscribe(response => {
      this.setState({
        ...this.state,
        benefitList: response.content.map(({ id, name }) => ({ id, name })),
      });
    });

    if (this.props.match.params.parameterId) {
      parametersService.getParameterDetailsById(this.props.match.params.parameterId).subscribe(resp => {
        this.setState({
          ...this.state,
          parameterForm: {
            ...this.state.parameterForm,
            ...resp,
            benefits: resp.benefits ? resp.benefits.map(({ id, name }) => ({ id, name })) : [],
            paramterUiRenderTypeId: resp.paramterUiRenderType.id,
            paramterComparisonTypeIds: resp.paramterComparisonTypes.map(item => item.id),
            ...(resp.paramterDataType && { paramterDataTypeId: resp.paramterDataType.id }),
          },
          parameterComparisonTypeList: this.state.parameterComparisonTypeList.map(item => {
            return {
              ...item,
              checked: resp.paramterComparisonTypes.some(r => r.id === item.id),
            };
          }),
        });
      });
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      parameterForm: {
        ...this.state.parameterForm,
        [name]: value,
      },
    });
  };

  resetForm = () => {
    this.setState({
      ...this.state,
      parameterForm: initForm,
      parameterComparisonTypeList: this.state.parameterComparisonTypeList.map(p => ({ ...p, checked: false })),
    });
  };

  handleClose = () => {
    this.props.history.push('/masters/parameters');
  };

  render() {
    const { classes } = this.props;
    const { parameterUIRenderType, parameterDataType, parameterComparisonTypeList, parameterForm, benefitList } = this.state;

    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Parameter Management: {this.props.match.params.parameterId ? 'Edit' : 'Add'}
        </Typography>
        <Paper elevation="none">
          <div className={classes.root}>
            <Formik
              enableReinitialize={true}
              initialValues={{ ...this.state.parameterForm }}
              validationSchema={parameterSchema}
              onSubmit={(values, { resetForm }) => {
                const payload = {
                  ...initForm,
                  ...values,
                  benefitIds: values.benefits.map(item => item.id),
                };
                if (this.props.match.params.parameterId) {
                  parametersService.updateParameter(payload, this.props.match.params.parameterId).subscribe(res => {
                    if (res.status) {
                      this.handleClose();
                    }
                  });
                } else {
                  parametersService.saveParameter(payload).subscribe(res => {
                    if (res.status) {
                      this.resetForm();
                      resetForm();
                    }
                  });
                }
              }}>
              {({ touched, errors, handleSubmit, handleChange, values, setFieldValue }) => {
                const allSelected = benefitList.length > 0 && values.benefits.length === benefitList.length;

                const handleAddChip = chip => {
                  setFieldValue('parameterValues', [...values.parameterValues, chip]);
                };

                const handleDeleteChip = (chip, index) => {
                  const chipValues = [...values.parameterValues];
                  chipValues.splice(index, 1);

                  setFieldValue('parameterValues', chipValues);
                };

                const handleComparisonTypeChange = (event, idx) => {
                  const pclist = [...parameterComparisonTypeList];
                  pclist[idx].checked = event.target.checked;
                  this.setState({
                    ...this.state,
                    parameterComparisonTypeList: [...pclist],
                  });
                  setFieldValue(
                    'paramterComparisonTypeIds',
                    pclist.filter(item => item.checked).map(item => item.id),
                  );
                };

                const handleBenefitChange = (e, val) => {
                  let selectedBenefits = val;
                  const isSelecAll = selectedBenefits.some(item => item.id === 'selectall');
                  if (isSelecAll) {
                    if (benefitList.length > 0 && benefitList.length === values.benefits.length) {
                      selectedBenefits = [];
                    } else {
                      selectedBenefits = benefitList;
                    }
                  }
                  setFieldValue('benefits', selectedBenefits);
                };

                const autocompleteFilterChange = (options, state) => {
                  if (state.inputValue) {
                    return options.filter(item => item.name.toLowerCase().indexOf(state.inputValue) > -1);
                  }
                  return [{ id: 'selectall', name: 'Select all' }, ...options];
                };

                const getParameterTypeByID = id => {
                  if (!id) return;
                  return parameterUIRenderType.filter(item => item.id === id)[0].type;
                };

                const handleTypeChange = (name, e) => {
                  setFieldValue(name, e.target.checked);
                  if (name == 'type' && e.target.checked) {
                    setFieldValue('isEligibleForProduct', true);
                    setFieldValue('isEligibleForPremium', true);
                  }
                };

                return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={7}>
                      <Grid item xs={12} container spacing={1} >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="name"
                            label="Parameter Name"
                            value={values.name}
                            onChange={handleChange}
                            required
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={classes.formControl}
                            required
                            error={touched.paramterUiRenderTypeId && Boolean(errors.paramterUiRenderTypeId)}
                            helperText={touched.paramterUiRenderTypeId && errors.paramterUiRenderTypeId}>
                            <InputLabel id="parameter-type-label">Parameter Type</InputLabel>
                            <Select
                              labelId="parameter-type-label"
                              name="paramterUiRenderTypeId"
                              value={values.paramterUiRenderTypeId}
                              onChange={handleChange}
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                              }}>
                              {parameterUIRenderType.map(paramType => (
                                <MenuItem value={paramType.id}>{paramType.name}</MenuItem>
                              ))}
                            </Select>
                            {touched.paramterUiRenderTypeId && Boolean(errors.paramterUiRenderTypeId) && (
                              <FormHelperText>
                                {touched.paramterUiRenderTypeId && errors.paramterUiRenderTypeId}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        {(() => {
                          switch (getParameterTypeByID(values.paramterUiRenderTypeId)) {
                            case 'textbox':
                              return (
                                <Grid item xs={2}>
                                  <FormControl
                                    className={classes.formControl}
                                    required
                                    error={touched.paramterDataTypeId && Boolean(errors.paramterDataTypeId)}>
                                    <InputLabel id="text-datatype-label">Datatype</InputLabel>
                                    <Select
                                      labelId="text-datatype-label"
                                      name="paramterDataTypeId"
                                      value={values.paramterDataTypeId}
                                      onChange={handleChange}
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                      }}>
                                      {parameterDataType.map(paramType => (
                                        <MenuItem value={paramType.id}>{paramType.name}</MenuItem>
                                      ))}
                                    </Select>
                                    {touched.paramterDataTypeId && Boolean(errors.paramterDataTypeId) && (
                                      <FormHelperText>
                                        {touched.paramterUiRenderTypeId && errors.paramterDataTypeId}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              );
                            case 'dropdown':
                              return (
                                <Grid item xs={2}>
                                  <ChipInput
                                    required
                                    error={touched.parameterValues && Boolean(errors.parameterValues)}
                                    helperText={touched.parameterValues && errors.parameterValues}
                                    label="Values"
                                    value={values.parameterValues}
                                    onAdd={chip => handleAddChip(chip)}
                                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                  />
                                  <p style={{fontSize:"12px", color:"red"}}>*After entering value please press enter</p>
                                </Grid>
                              );
                            case 'textarea':
                              return (
                                <Grid item xs={2}>
                                  <TextField rows="2" multiline />
                                </Grid>
                              );
                            case 'dropdown_range':
                              return (
                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                  <TextField
                                    name="start"
                                    type="number"
                                    label="Start"
                                    value={values.start}
                                    onChange={handleChange}
                                    style={{ marginRight: '10px' }}
                                  />
                                  <TextField
                                    name="end"
                                    type="number"
                                    label="End"
                                    value={values.end}
                                    onChange={handleChange}
                                    style={{ marginRight: '10px' }}
                                  />
                                  <TextField
                                    name="count"
                                    type="number"
                                    label="Count"
                                    value={values.count}
                                    onChange={handleChange}
                                    style={{ marginRight: '10px' }}
                                  />
                                </Grid>
                              );
                            default:
                              return;
                          }
                        })()}
                      </Grid>
                      <Grid item xs={12} container  spacing={1} >
                        <Grid item xs={12} md={6}>
                          <FormControl
                            className={classes.formGroupOuter}
                            required
                            error={touched.paramterComparisonTypeIds && Boolean(errors.paramterComparisonTypeIds)}>
                            <FormLabel component="legend">Parameter Comparison Type</FormLabel>
                            <Grid container spacing={1} >
                              {parameterComparisonTypeList.map((pct, idx) => (
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={pct.checked}
                                        onChange={e => handleComparisonTypeChange(e, idx)}
                                        value={pct.id}
                                        name={pct.name}
                                        color="primary"
                                      />
                                    }
                                    label={pct.symbol}
                                    labelPlacement="end"
                                    className="pctlabel"
                                  />
                                </Grid>
                              ))}
                            </Grid>
                            {touched.paramterComparisonTypeIds && Boolean(errors.paramterComparisonTypeIds) && (
                              <FormHelperText>
                                {touched.paramterComparisonTypeIds && errors.paramterComparisonTypeIds}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            className={classes.benefitAutoComplete}
                            fullWidth
                            multiple
                            value={values.benefits}
                            onChange={handleBenefitChange}
                            id="checkboxes-tags-demo"
                            filterOptions={autocompleteFilterChange}
                            options={benefitList}
                            disableCloseOnSelect
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option, { selected }) => {
                              const selectedOpt = (option.id === 'selectall' && allSelected) || selected;
                              return (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, color: '#626bda' }}
                                    checked={selectedOpt}
                                  />
                                  {option.name}
                                </React.Fragment>
                              );
                            }}
                            renderInput={params => <TextField {...params} label="Benefit" placeholder="Select Benefit" />}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container spacing={1} >
                        <Grid item xs={12} >
                          <FormLabel component="legend">Parameter Eligible For</FormLabel>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.type}
                                onChange={e => handleTypeChange('type', e)}
                                name="type"
                                color="primary"
                              />
                            }
                            label="Product & Premium"
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12} >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.isEligibleForProduct}
                                    onChange={e => handleTypeChange('isEligibleForProduct', e)}
                                    name="isEligibleForProduct"
                                    color="primary"
                                  />
                                }
                                label="Product"
                                disabled={values.type}
                              />
                            </Grid>
                            <Grid item xs={12} >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.isEligibleForPremium}
                                    onChange={e => handleTypeChange('isEligibleForPremium', e)}
                                    name="isEligibleForPremium"
                                    color="primary"
                                  />
                                }
                                label="Premium"
                                disabled={values.type}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container spacing={1} >
                        <Grid item xs={12} sm={6}>
                          <Button type="submit" variant="contained" color="primary">
                            Save
                          </Button>
                          <Button variant="contained" style={{ marginLeft: 15 }} onClick={() => this.handleClose()} className="p-button-text">
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ParameterFormComponent));
