import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UsersFormComponent from './users.form.component';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UsersActionComponent(props) {
  const query = useQuery();
  const history = useHistory();

  return (
    <div>
      {(() => {
        switch (query.get('mode')) {
          case 'edit':
            return <UsersFormComponent />;
          default:
            history.push(`/user-management/users/${props.match.params.userName}?mode=edit`);
        }
      })()}
    </div>
  );
}
