import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import QuotationFormComponent from './quotation.form';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function QuotationManagementAction(props) {
    const query = useQuery();
    const history = useHistory();

    return (
        <div>
            {(() => {
                switch (query.get("mode")) {
                    case 'edit':
                        return (
                            <QuotationFormComponent />
                        );
                    default:
                        history.push(`/quotations/${props.match.params.quotationId}?mode=edit`);
                }
            })()}
        </div>
    );
}