import { THEME_TYPES } from '../@jumbo/constants/ThemeOptions';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      root: { fontSize: '12px' },
    },
    MuiInputLabel: {
      root: {
        fontSize: '13px',
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiTextField: {
      root: {
        fontSize: '13px',
      },
    },
    MuiSelect: {
      root: {
        fontSize: '13px',
      },
      selectMenu: {
        fontSize: '12px',
      },
    },
    MuiFormControl: {
      root: {
        fontSize: '13px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '13px',
      },
    },
    MuiCheckbox: {
      root: { fontSize: '12px' },
    },
    MuiInput: {
      root: {
        fontSize: '13px',
      },
    },
    MuiAutocomplete: {
      // inputRoot: {
      //   fontSize: '1rem', // Set the font size for the Autocomplete input field
      // },
    },
  },
});

export const semiDarkTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    sidebar: {
      bgColor: '#363636',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#fff',
      navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
      navActiveBgColor: '#3f51b5',
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#3f51b5',
      // menuHoverBgColor: 'rgb(229, 229, 229)',
      // menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    common: {
      dark: '#020202',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f7',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  overrides: {
    MuiCheckbox: {
      root: { fontSize: '12px' },
    },
    MuiInputLabel: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInput: {
      root: {
        fontSize: '13px',
      },
    },

    MuiMenuItem: {
      root: {
        fontSize: '13px',
      },
    },
    MuiFormControl: {
      root: {
        fontSize: '13px',
      },
    },
    MuiTextField: {
      root: {
        fontSize: '13px',
      },
    },
    MuiSelect: {
      root: {
        fontSize: '13px',
      },
      selectMenu: {
        fontSize: '12px',
      },
    },

    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
        fontSize: '12px',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
        fontSize: '12px',
      },
    },
  },
};
export const darkTheme = {
  palette: {
    type: THEME_TYPES.DARK,
    sidebar: {
      bgColor: '#363636',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#fff',
      navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
      navActiveBgColor: '#3f51b5',
      borderColor: 'rgba(255, 255, 255, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(255, 255, 255, 0.3)',
      textDarkColor: '#fff',
      textActiveColor: '#3f51b5',
      menuHoverBgColor: 'rgb(0, 0, 0, 0.8)',
      menuActiveBgColor: 'rgb(0, 0, 0, 0.5)',
    },
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
    },
    background: {
      paper: '#494646',
      default: '#2e2e2e',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.3)',
      white: '#fff',
    },
    borderColor: {
      main: 'rgba(255, 255, 255, 0.06)',
      dark: 'rgba(255, 255, 255, 0.12)',
    },
    lightBtn: {
      bgColor: '#535151',
      textColor: 'rgba(255, 255, 255, 0.38)',
    },
    popupColor: {
      main: '#363636',
    },
  },
  overrides: {
    MuiCheckbox: {
      root: { fontSize: '12px' },
    },
    MuiInputLabel: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInput: {
      root: {
        fontSize: '13px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '13px',
      },
    },
    MuiFormControl: {
      root: {
        fontSize: '13px',
      },
    },
    MuiTextField: {
      root: {
        fontSize: '13px',
      },
      input: {
        fontSize: '12px !important',
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: 'rgba(255, 255, 255, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#535050',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#2e2e2e',
      },
    },
  },
};

export const lightTheme = {
  palette: {
    type: THEME_TYPES.LIGHT,
    primary: { main: '#313c96' },
    secondary: { main: '#0edb8a' },
    sidebar: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#3f51b5',
      // navHoverBgColor: 'rgb(229, 229, 229)',
      // navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#3f51b5',
      // menuHoverBgColor: 'rgb(229, 229, 229)',
      // menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    common: {
      black: '#000',
      white: '#fff',
      dark: '#020202',
    },
    background: {
      paper: '#FFFFFF', // Paper background
      default: '#f6f8fc', //appbackground
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    lightBtn: {
      bgColor: '#f5f5f5', // Alternative to #FFFFFF
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    popupColor: {
      main: '#fff',
    },
  },
  overrides: {
    MuiCheckbox: {
      root: { fontSize: '12px' },
    },
    MuiInputLabel: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '13px',
      },
    },
    MuiInput: {
      root: {
        fontSize: '13px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '13px',
      },
    },
    MuiFormControl: {
      root: {
        fontSize: '13px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiTextField: {
      root: {
        fontSize: '13px',
      },
    },
    MuiSelect: {
      root: {
        fontSize: '13px',
      },
      selectMenu: {
        fontSize: '12px',
      },
    },

    MuiTab: {
      textColorPrimary: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
        fontSize: '12px',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
        fontSize: '12px',
      },
    },
  },
};
