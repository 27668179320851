export const clearSelectedRuleFromBenefitInNavPath = (navPath, startIndex) => {
  const tempNavPath = startIndex && startIndex > 0 && startIndex < navPath.length ? navPath.slice(startIndex) : navPath;
  tempNavPath.forEach(clearSelectedRuleFromBenefit);
};

export const clearSelectedRuleFromBenefit = benefit => {
  if (!benefit?.ruleList) return;

  benefit &&
    benefit.ruleList.every(r => {
      if (r.isSelected) {
        r.isSelected = false;
        return false;
      }
      return true;
    });
};

export const getSelectedRuleId = benefit => {
  if (!benefit?.ruleList) return null;

  return benefit.ruleList.filter(r => r.isSelected)[0]?.internalId;
};

export const hasAnyRuleInBenefitHierarchies = benefitStructures => {
  const fl = benefitStructures.filter(b => b?.ruleList && b?.ruleList.length > 0);
  return fl.length > 0;
};

export const extractRulesFromBenefit = benefit => {
  const rules = benefit.ruleList || [];
  if (benefit.child) {
    const rules = benefit.ruleList || [];
    const childRules = benefit.child.map(c => extractRulesFromBenefit(c)).flat(1);
    return [...rules, ...childRules];
  }
  return rules;
};

export const extractPremiumRulesFromBenefit = benefit => {
  const productRules = extractRulesFromBenefit(benefit);
  return productRules.map(pr => ({ productRuleId: pr.id, premiumRules: pr.premiumRules }));
};

export const extractRulesFromBenefitStructures = benefitStructures => {
  return benefitStructures
    .map(bs => bs)
    .map(extractRulesFromBenefit)
    .flat(1);
};

export const extractPremiumRulesFromBenefitStructures = benefitStructures => {
  return benefitStructures
    .map(bs => bs.hirearchy)
    .map(extractPremiumRulesFromBenefit)
    .flat(1)
    .filter(pr => pr.premiumRules && pr.premiumRules.length > 0);
};

export const productRulesGroupByBenefitId = (rules = []) => {
  return rules.reduce((group, rule) => {
    const { benefitId, benefitStructureId } = rule;
    let id = `${benefitStructureId}_${benefitId}`;

    group[id] = group[id] ?? [];
    group[id].push(rule);
    return group;
  }, {});
};

const recursivlyCheckBenefitAndSetProductRules = (benefitStructureId, benefit, productRulesGroupByBenefitId) => {
  benefit.ruleList = productRulesGroupByBenefitId[`${benefitStructureId}_${benefit.id}`] || [];
  if (benefit.child) {
    benefit.child.forEach(cb =>
      recursivlyCheckBenefitAndSetProductRules(benefitStructureId, cb, productRulesGroupByBenefitId),
    );
  }
};

export const setRulesInBenefitStructures = (benefitStructures, rules) => {
  const groupByRules = productRulesGroupByBenefitId(rules);
  benefitStructures.forEach(bs => {
    let rootBenefit = bs.hirearchy;
    recursivlyCheckBenefitAndSetProductRules(bs.id, rootBenefit, groupByRules);
  });
};

export const setPremiumDetailsInProductRules = (product, premiums) => {
  if (product.productRules && product.productRules.length > 0 && premiums && premiums.length > 0) {
    product.productRules.forEach(pr => {
      const selectedPremiumRuleObj = premiums.filter(premium => premium.productRuleId == pr.id)[0];
      pr.premiumRules = selectedPremiumRuleObj?.premiumRules || [];
    });
  }
};

export const isBenefitStructureContainsAnyPremiumRule = benefitStructureHirearchy => {
  let flagArray = [];
  if (benefitStructureHirearchy.child) {
    for (const c of benefitStructureHirearchy.child) {
      const returnArray = isBenefitStructureContainsAnyPremiumRule(c);
      flagArray = [...flagArray, ...returnArray];
    }
  }

  if (benefitStructureHirearchy.ruleList && benefitStructureHirearchy.ruleList.length > 0) {
    const count = benefitStructureHirearchy.ruleList.filter(pr => pr.premiumRules && pr.premiumRules.length > 0).length;
    flagArray = [...[count > 0], ...flagArray];
  }
  return flagArray;
};

export const hasAnyPremiumRuleInBenefitHierarchies = benefitStructures => {
  for (const bs of benefitStructures) {
    var responseArray = isBenefitStructureContainsAnyPremiumRule(bs.hirearchy);
    const containsAny = responseArray.filter(value => value).length > 0;
    if (containsAny) {
      return true;
    }
  }
  return false;
};

export const deleteRule = (benefit, rule) => {
  const ruleList = benefit?.ruleList || [];
  ruleList.splice(ruleList.indexOf(rule), 1);
  benefit.ruleList = ruleList;

  if (benefit.child && benefit.child.length > 0) {
    benefit.child.forEach(cb => {
      if (cb.ruleList && cb.ruleList.length > 0) {
        cb.ruleList.filter(r => r.parentInternalId === rule.internalId).forEach(r => deleteRule(cb, r));
      }
    });
  }
};
