import React from 'react';

import PropTypes from 'prop-types';

import LayoutContextProvider from './LayoutContextProvider';
import MainContainer from './MainContainer';
import SidebarThemeProvider from '../SidebarThemeContext';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  ContentBar: {
    // background:"white",
    margin:"0 3%",
    borderRadius:"8px",
    padding:"0 0",
    // boxShadow:"1px 2px 2px 3px rgba(128,128,128,0.3)",
  },
}));

const CmtVerticalLayout = ({ layoutOptions, children, header, sidebar, footer, ...rest }) => {
  const classes = useStyles();
  return (
    <LayoutContextProvider {...layoutOptions}>
      <SidebarThemeProvider>
        <MainContainer header={header} sidebar={sidebar} footer={footer} {...rest}>
          <Box className={classes.ContentBar}>{children}</Box>
        </MainContainer>
      </SidebarThemeProvider>
    </LayoutContextProvider>
  );
};

CmtVerticalLayout.prototype = {
  layoutOptions: PropTypes.object,
};

CmtVerticalLayout.defaultProps = {
  layoutOptions: {},
};

export default CmtVerticalLayout;
