import Grid from "@material-ui/core/Grid";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ProspectService } from "../../remote-api/api/client-services";
import ProspectListComponent from './prospect-list.component';
import ProspectManagementForm from './prospect-management-form';


const prospectService = new ProspectService();

const psTableData$ = prospectService.getProspects();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ProspectComponent() {
  const query = useQuery();
  const history = useHistory();

  const [rows, setRows] = React.useState([]);

  const clientTypeName = "Retail";

  const extractObject = (params) => {

    const api = params.api;
    const fields = api
      .getAllColumns()
      .map((c) => c.field)
      .filter((c) => c !== "__check__" && !!c);
    const thisRow = {};

    fields.forEach((f) => {
      thisRow[f] = params.getValue(f);
    });
    return thisRow;
  }

  let columns = [
    { field: "code", headerName: "Prospect Code", width: 350 },
    { field: "firstName", headerName: "Prospect Name", width: 350 },
    { field: "clientType", headerName: "Client Type", width: 300 },
    {
      field: "displayName",
      headerName: "Name",
      // type: 'number',
      width: 350,
    },
    {
      field: "mobileNo",
      headerName: "Contact Number",
      // type: 'number',
      width: 200,
    },
    {
      field: "Action",
      headerName: "Action",
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      width: 200,
      renderCell: (params) => {
        const onClickEdit = () => {
          // let obj = extractObject(params);
          const { id } = params.row;
          history.push(`/prospects/${id}?mode=edit`);
        };

        const onClickDelete = () => {
          let obj = extractObject(params);
        };

        return <div>
          <CreateIcon style={{ cursor: "pointer", color: "#626BDA" }} onClick={onClickEdit} />
          <DeleteIcon style={{ cursor: "pointer", color: "#626BDA", marginLeft: "5px" }} onClick={onClickDelete} />
        </div>
      }
      // valueGetter: (params) =>
      //   `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    },
  ];

  const useObservable = (observable, setter) => {
    useEffect(() => {
      const subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe()
    },
      [observable, setter]
    );
  };

  useObservable(psTableData$, setRows);

  return (
    <div>
      {query.get("mode") === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            height: "2em",
            color: "inherit",
            fontSize: "18px",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Prospect Management - Create Prospect
          </span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get("mode")) {
          case 'viewList':
            return (
              <ProspectListComponent rows={rows} columns={columns} />
            );
          case 'create':
            return (
              <ProspectManagementForm clientTypeName={clientTypeName} />
            );
          default:
            history.push("/client/prospects?mode=viewList");
        }
      })()}
    </div>
  );
}
