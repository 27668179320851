import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { AgentsService } from '../../../remote-api/eo2v2-remote-api';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  selectYear: {
    margin: theme.spacing(2),
    minWidth: 270,
  },
  submitButton: {
    marginLeft: theme.spacing(2),
  },
}));

const agentservice = new AgentsService();

const NodeComponent = ({ node, groupTypes, selectedYear, months }) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const columns = groupTypes.map(gr => gr.name); //['RETAIL', 'CORPORATE', 'SME', 'COMMUNITY'];

  const handleSubmit = row => {
    const targetRow = tableData.find(trow => trow.period === row.period);
    const period = targetRow.period;
    delete targetRow.period;
    delete targetRow.id;
    const filteredArray = Object.entries(targetRow)
      .filter(([key, value]) => value !== null && value !== undefined && value !== '')
      .map(([key, value]) => ({ [key]: value }));

    const findDataType = filteredArray.map(obj => {
      const key = Object.keys(obj)[0];
      return {
        ...groupTypes.find(group => group.name.toLowerCase() === key),
        value: +obj[key],
      };
    });

    const dataypes = [...findDataType];
    const dto = dataypes.map(type => {
      delete type.id;
      return type;
    });

    const payload = {
      period: period,
      hierarchyCode: node.id,
      childDto: dto,
    };

    agentservice.saveAgentTarget(payload).subscribe({
      next: result => {
        setIsSuccess(true);
        setOpen(true);
        initializeTable();
        setTimeout(() => setOpen(false), 3000);
      },
      error: error => {
        setIsSuccess(false);
        setOpen(true);
        initializeTable();
        setTimeout(() => setOpen(false), 3000);
      },
    });
  };

  const initializeTable = () => {
    if (groupTypes.length > 0) {
      const newTableData = months.map((month, index) => {
        const row = {
          id: index + 1,
          period: `${month}-${selectedYear}`,
        };
        groupTypes.forEach(group => {
          row[group.name.toLowerCase()] = null;
        });
        return row;
      });
      setTableData(newTableData);
    }
  };

  useEffect(() => {
    if (groupTypes.length > 0) {
      initializeTable();
    }
  }, [groupTypes, selectedYear]);

  const handleInputChange = (id, column, value) => {
    setTableData(prevData => prevData.map(row => (row.id === id ? { ...row, [column.toLowerCase()]: value } : row)));
  };

  return (
    <>
      {open && isSuccess && (
        <Alert ope severity="success" color="info">
          Target saved successfully!
        </Alert>
      )}
      {open && !isSuccess && (
        <Alert variant="outlined" severity="error">
          Something went wrong!
        </Alert>
      )}
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h6">{node.name}</Typography>
          <Typography color="textSecondary">Type: {node.type}</Typography>
          <div>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>PERIOD</TableCell>
                    {columns.map(column => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.period}</TableCell>
                      {columns.map(column => (
                        <TableCell key={`${row.id}-${column}`}>
                          <TextField
                            value={row[column.toLowerCase()]}
                            label="amount"
                            onChange={e => handleInputChange(row.id, column, e.target.value.replace(/\D/g, ''))}
                          />
                        </TableCell>
                      ))}
                      <TableCell>
                        <Button
                          variant="text"
                          color="primary"
                          className={classes.submitButton}
                          onClick={() => handleSubmit(row)}>
                          SUBMIT
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default NodeComponent;
