import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Invoices from "./invoice/invoice.component";
import InvoiceDetailsMain from "./invoice/invoice.details.main.component";

export default function InvoiceRoutersComponent(props) {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path} > <Invoices /> </Route>
        <Route exact path="/invoices" component={Invoices} />
          <Route exact path="/invoices/:id" component={InvoiceDetailsMain} />
      </Switch>
    </React.Fragment>
    
  );
}