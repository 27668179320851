import Grid from "@material-ui/core/Grid";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import FundConfigListComponent from "./fund.config.list.component";
import FundConfigForm from "./fund.config.form";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function FundConfigManagementComponent(props) {
  const query = useQuery();
  const history = useHistory();

  return (
    <div>
      {query.get("mode") === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            height: "2em",
            color: "#000",
            fontSize: "18px",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Fund Config Management - Create
        </span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get("mode")) {
          case 'viewList':
            return (
              <FundConfigListComponent />
            );
          case 'create':
            return (
              <FundConfigForm />
            );
          default:
            history.push("/funds/config?mode=viewList");
        }
      })()}
    </div>
  );
}
