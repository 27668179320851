import { FormControl } from '@material-ui/core';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { updateTbItemsStatus } from '@syncfusion/ej2-react-richtexteditor';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textArea: {
    width: '100%',
    minHeight: '100px', // Set the height as needed
    padding: '10px',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    fontSize: '16px',
    lineHeight: '1.5',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    '&:focus': {
      outline: '0',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
    },
  },
}));

const validationSchema = yup.object().shape({
  costSavings: yup
    .number('Cost Saving must be a number')
    .typeError('Cost Saving must be a number')
    .required('Cost Saving is required'),
  mobileNo: yup
    .string()
    .matches(/^\d{10}$/, 'Mobile Number must be 10 digits')
    .required('Mobile Number is required'),
  dod: yup
    .date('Admission Date must be a valid date')
    .typeError('Admission Date must be a valid date')
    .required('Admission Date is required'),
  remarks: yup.string().required('Remark is required'),
});

export default function DischargeUpdationModal(props) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      action: 'DISCHARGE_UPDATION',
      estimatedAmount: props.data?.estimatedCost || '',
      sanctionAmount: props.data?.approvedCost || '',
      costSavings: 0,
      mobileNo: '',
      dod: '',
      dischargeCertificate: null,
      feedbackForm: null,
      remarks: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      // console.log('here');
      // const dod = new Date(values.dod);
      // const timestampMilliseconds = dod.getTime();
      // const updatedValues = {
      //   ...values,
      //   dod: timestampMilliseconds,
      // };
      // // props.admissionUpdationModalSubmit(updatedValues);
      // // props.handleCloseClaimModal();
    },
  });
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClose = () => {
    props.handleCloseClaimModal();
  };

  const handleModalSubmit = () => {
    // console.log('sdfghjk');
    // formik.handleSubmit();
    const dod = new Date(formik.values.dod);
    const timestampMilliseconds = dod.getTime();
    
    const updatedValues = {
      ...formik.values,
      action: 'DISCHARGE_UPDATION',
      dod: timestampMilliseconds,
    };
    
    props.admissionUpdationModalSubmit(updatedValues);
    props.handleCloseClaimModal();
  };

  React.useEffect(() => {
    formik.setValues({ estimatedAmount: props.data?.estimatedCost, sanctionAmount: props.data?.approvedCost });
  }, [props]);

  return (
    <Dialog
      open={props.dishargeUpdationModal}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus>
      <DialogTitle id="form-dialog-title">Discharge Updation</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={6}>
            <TextField
              id="estimatedAmount"
              name="estimatedAmount"
              value={formik.values.estimatedAmount}
              onChange={formik.handleChange}
              label="Estimated Amount"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sanctionAmount"
              name="sanctionAmount"
              value={formik.values.sanctionAmount}
              onChange={formik.handleChange}
              label="Sanction Amount"
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl>
              <TextField
                id="costSavings"
                name="costSavings"
                value={formik.values.costSavings}
                onChange={formik.handleChange}
                type="number"
                label="Cost Savings *"
                helperText={formik.touched.costSavings && formik.errors.costSavings}
                error={formik.touched.costSavings && Boolean(formik.errors.costSavings)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <TextField
                id="mobileNo"
                name="mobileNo"
                value={formik.values.mobileNo}
                onChange={formik.handleChange}
                label="Mobile Number *"
                helperText={formik.touched.mobileNo && formik.errors.mobileNo}
                error={formik.touched.mobileNo && Boolean(formik.errors.mobileNo)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <TextField
                id="dod"
                name="dod"
                onChange={formik.handleChange}
                label="Admisssion Date *"
                type="datetime-local"
                // defaultValue="2017-05-24T10:30"
                value={formik.values.dod}
                className={classes.textField}
                helperText={formik.touched.dod && formik.errors.dod}
                error={formik.touched.dod && Boolean(formik.errors.dod)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <input
                accept=".pdf,.doc,.docx"
                id="dischargeCertificate"
                name="dischargeCertificate"
                type="file"
                style={{ display: 'none' }}
                onChange={event => {
                  formik.setFieldValue('dischargeCertificate', event.currentTarget.files[0]);
                }}
              />
              <label htmlFor="dischargeCertificate">
                <Button variant="contained" component="span">
                  Upload Discharge Certificate
                </Button>
              </label>
              <div>{formik.values.dischargeCertificate?.name}</div>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <input
                accept=".pdf,.doc,.docx"
                id="feedbackForm"
                name="feedbackForm"
                type="file"
                style={{ display: 'none' }}
                onChange={event => {
                  formik.setFieldValue('feedbackForm', event.currentTarget.files[0]);
                }}
              />
              <label htmlFor="feedbackForm">
                <Button variant="contained" component="span">
                  Upload Feedback Form
                </Button>
              </label>
              <div>{formik.values.feedbackForm?.name}</div>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                id="remarks"
                onChange={formik.handleChange}
                name="remarks"
                value={formik.values.remarks}
                label="Remark *"
                multiline
                minRows={5}
                helperText={formik.touched.remarks && formik.errors.remarks}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" className="p-button-text">
          Cancel
        </Button>
        <Button onClick={handleModalSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
