import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ClaimsReportComponent from './claims.reports.component';

export default function ClaimsReportsRouterComponent(props) {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <ClaimsReportComponent />{' '}
        </Route>
        {/* <Route exact path={`${path}/member`}>
          {' '}
          <MembersComponent />{' '}
        </Route>
        <Route exact path={`${path}/member/detail/:id`}>
          {' '}
          <MemberDetail />{' '}
        </Route> */}
      </Switch>
    </React.Fragment>
  );
}
