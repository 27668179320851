import Box from "@material-ui/core/Box";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from '@material-ui/icons/GetApp';
import * as React from "react";
import { ClientService, ProspectService } from "../../remote-api/api/client-services";
import { Eo2v2MultiFieldSearch } from '../../shared-components';



const useStyles = makeStyles((theme) => ({
    tableBg: {
        height: 400,
        width: "100%",
        backgroundColor: '#fff',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        borderRadius: '4px'
    },
    prospectImportOuterContainer: {
        padding: 20
    },
    prospectImportRadioGroup: {
        flexWrap: 'nowrap',
        '& label': {
            flexDirection: 'row'
        }
    }
}));

const prospectService = new ProspectService();
const clientService = new ClientService();

export default function ProspectImportComponent(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        showSearch: false,
        showProspectTable: false,
        selectedCode: "",
        selectedMobile: "",
        selectedDisplayName: "",
        rows: [],
        selectedChoice: ''
    })

    let columns = [
        { field: "code", headerName: "Prospect Code", width: 200 },
        { field: "firstName", headerName: "Prospect Name", width: 200 },
        // { field: "clientType", headerName: "Client Type", width: 300 },
        {
            field: "displayName",
            headerName: "Name",
            // type: 'number',
            width: 200,
        },
        {
            field: "mobileNo",
            headerName: "Contact Number",
            // type: 'number',
            width: 200,
        },
        {
            field: "Action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                const onClickImport = () => {
                    handleImport(params.row);
                };
                return <div>
                    <GetAppIcon style={{ cursor: "pointer", color: "#626BDA" }} onClick={onClickImport} />
                </div>
            }
        },
    ];


    const dataSource$ = (fields, pageRequest = { page: 0, size: 10 }) => {
        let pagerequestquery = {
            page: pageRequest.page, size: pageRequest.size, summary: false
        }
        Object.keys(fields).filter(key => !!fields[key])
            .forEach(key => pagerequestquery[key] = fields[key]);
        return prospectService.importProspectData(pagerequestquery);
    };
    const fields = [
        { label: "Code", propertyName: "code" },
        { label: "Name", propertyName: "displayName" },
        { label: "Contact", propertyName: "mobileNo" }];

    const columnsDefinations = [{ field: "code", headerName: "Prospect Code" },
    { field: "firstName", headerName: "Prospect Name" },
    { field: "clientType", headerName: "Client Type" },
    { field: "displayName", headerName: "Name" },
    { field: "mobileNo", headerName: "Contact Number" }];
    const handleImport = (e) => {
        props.handleProspectImport(e);
    }

    const getProspects = () => {
        let pgequery = {
            page: 0,
            size: 10,
            summary: false,
            // active: true,
            // sort: [
            //   string
            // ],            
        }

        if (state.selectedCode) {
            pgequery['code'] = state.selectedCode
        }
        if (state.selectedDisplayName) {
            pgequery['displayName'] = state.selectedDisplayName
        }
        if (state.selectedMobile) {
            pgequery['mobileNo'] = state.selectedMobile
        }
        prospectService.importProspectData(pgequery).subscribe(res => {
            setState({
                ...state,
                rows: res.content,
                showProspectTable: true
            })
        })
    }

    const handleCancel = () => {
        setState({
            showProspectTable: false,
            selectedChoice: "",
            selectedCode: "",
            selectedMobile: "",
            selectedDisplayName: ""
        })
        props.closeProspectimport();
    }

    const handleChange = (e) => {
        if (e.target.name === 'prosimport') {
            setState({
                ...state,
                selectedChoice: e.target.value
            });
            if (e.target.value === "No") {
                handleCancel();
            }
        }
        if (e.target.name === 'selectedCode') {
            setState({
                ...state,
                selectedCode: e.target.value
            });

        }
        if (e.target.name === 'selectedDisplayName') {
            setState({
                ...state,
                selectedDisplayName: e.target.value
            });

        }
        if (e.target.name === 'selectedMobile') {
            setState({
                ...state,
                selectedMobile: e.target.value
            });

        }

    }

    return (
        <div>
            <Paper elevation='none' className={classes.prospectImportOuterContainer}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Do you want to import data from Prospect</FormLabel>
                    <RadioGroup aria-label="prosimport" name="prosimport" value={state.selectedChoice} onChange={handleChange} row className={classes.prospectImportRadioGroup}>
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Paper>

            {state.selectedChoice === 'Yes' ? (
                <div>
                    {/* <Paper elevation='none'>
                        <Box p={3} my={2}>
                            <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                                <Grid item xs={3}>
                                    <TextField size="small"
                                        id="standard-basic"
                                        name="selectedCode"
                                        value={state.selectedCode}
                                        onChange={handleChange}
                                        label="Prospect Code"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField size="small"
                                        id="standard-basic"
                                        name="selectedDisplayName"
                                        value={state.selectedDisplayName}
                                        onChange={handleChange}
                                        label="Display Name"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField size="small"
                                        id="standard-basic"
                                        name="selectedMobile"
                                        value={state.selectedMobile}
                                        onChange={handleChange}
                                        label="Mobile"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={getProspects}
                                        startIcon={<SearchIcon />}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    {state.showProspectTable ? (
                        
                            <DataGrid
                                rows={state.rows}
                                columns={columns}
                                pageSize={5}
                            />
                        </div>
                    ) : null} */}
                    <Paper elevation='none'>
                        <Box p={3} my={2}>
                            <Eo2v2MultiFieldSearch
                                $dataSource={dataSource$}
                                fields={fields}
                                onSelect={(item) => { handleImport(item) }}
                                columnsDefinations={columnsDefinations}
                                dataGridPageSize={10}
                                dataGridScrollHeight='400px'
                            />
                        </Box>
                    </Paper>

                </div>
            ) : null}
        </div>

    )
}