import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect } from "react";
import { BenefitStructureService } from "../../../remote-api/api/benefit-structure-services";
import { EO2V2BenefitRuleTreeViewComponent } from '../../../shared-components';
import ServiceDesignTable from '../service-design/service-design-table';

const benefitStructureService = new BenefitStructureService();

const benefitStructureService$ = benefitStructureService.getAllBenefitStructures();

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        minHeight: 100,
        padding: 30,
    },
    header: {
        paddingTop: 10,
        paddingBottom: 10,
        color: "#4472C4",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export default function PreviewComponent(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState("panel2");
    const handleAccordianToggle = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [benefitStructures, setBenefitStructures] = React.useState([]);
    const [previewBenefitHierarchy, setPreviewBenefitHierarchy] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        if (props.productDetails && props.productDetails.productServices) {
            setSeviceDesignData();
        }
    },
        [props.productDetails]
    );

    const useObservable = (observable, setter, type) => {
        useEffect(() => {
            let subscription = observable.subscribe(result => {
                setter(result.content);

                if (type === "benefitStructure") {
                    buildPreviewHierarchy(result.content);
                }

            });
            return () => subscription.unsubscribe()
        },
            [observable, setter]
        );
    };

    useObservable(benefitStructureService$, setBenefitStructures, "benefitStructure");

    const buildRuleObject = (rule) => {
        return {
            ...rule,
            config: JSON.parse(rule.expressionConfiugrationStub),
            ruleName: rule.name,
            ruleTextArea: rule.expression,
            internalId: rule.id,
            parentInternalId: rule.parentId
        }
    };

    const setRulesInParent = (benefit, parentId, rule) => {
        if (benefit.rules) {
            const parentIndex = benefit.rules.findIndex(item => item.id === parentId);
            if (parentIndex === -1) {

                for (let i = 0; i < benefit.child.length; i++) {
                    const item = benefit.child[i];
                    setRulesInParent(item, parentId, rule);
                }
            } else {
                if (benefit.child && benefit.child.length > 0) {

                    const childIndex = benefit.child.findIndex(item => item.id === rule.benefitId);
                    if (childIndex > -1) {
                        if (!benefit.child[childIndex].rules) {
                            benefit.child[childIndex].rules = [];
                        }
                        benefit.child[childIndex].rules.push(buildRuleObject(rule));
                    }
                }
            }
        }
    };

    const getChildRuleHierarchy = (parentHierarchy, mainBenefitIndex, rule, newBenefitElm) => {
        const parentIdx = parentHierarchy.child.findIndex(item => item.id === rule.parentId);
        if (parentIdx > -1) {
            let benefitIdx = parentHierarchy.child[parentIdx].child.findIndex(benefit => benefit.id === newBenefitElm.id);
            if (benefitIdx === -1) {
                parentHierarchy.child[parentIdx].child.push(newBenefitElm);
                benefitIdx = 0;
            }
            parentHierarchy.child[parentIdx].child[benefitIdx].child.push({ ...rule, child: [], type: "rule" });
        } else {
            parentHierarchy.child.forEach(item => {
                getChildRuleHierarchy(item, mainBenefitIndex, rule, newBenefitElm);
            });
        }
    };

    const getChildBenefitHierarchy = (benefitElm, rule, previewHierarchy, mainBenefitIndex) => {
        if (benefitElm.child && benefitElm.child.length > 0) {
            const subBenefitIndex = benefitElm.child.findIndex(item => item.id === rule.benefitId);
            if (subBenefitIndex > -1) {
                const newBenefitElm = { ...benefitElm.child[subBenefitIndex], child: [], type: "benefit" };
                if (rule.parentId) {
                    getChildRuleHierarchy(previewHierarchy[mainBenefitIndex].hirearchy, mainBenefitIndex, rule, newBenefitElm);
                }
            } else {
                benefitElm.child.forEach(item => {
                    getChildBenefitHierarchy(item, rule, previewHierarchy, mainBenefitIndex)
                });
            }
        }
    };

    const buildPreviewHierarchy = (benefitStructures) => {
        const previewHierarchy = benefitStructures.map((benefit) => {
            return { ...benefit, hirearchy: { ...benefit.hirearchy, child: [] } };
        });

        if (props.productDetails.productConfigurations) {
            props.productDetails.productConfigurations.rules.forEach(rule => {
                const mainBenefitIndex = benefitStructures.findIndex(b => b.id === rule.benefitStructureId);
                if (mainBenefitIndex > -1) {
                    const benefitElm = benefitStructures[mainBenefitIndex].hirearchy;
                    if (benefitElm.id === rule.benefitId) {
                        if (!rule.parentId) {
                            previewHierarchy[mainBenefitIndex].hirearchy.child.push({ ...rule, child: [], type: "rule" });
                        }
                    } else {
                        getChildBenefitHierarchy(benefitElm, rule, previewHierarchy, mainBenefitIndex);
                    }
                }
            });
        }

        setPreviewBenefitHierarchy(previewHierarchy);
    };

    const setSeviceDesignData = () => {
        const productDetails = props.productDetails;
        if (productDetails && productDetails.productServices) {
            let servicesRows = productDetails?.productServices?.services && JSON.parse(JSON.stringify(productDetails?.productServices?.services));
            setRows(servicesRows);
        }
    };

    return (
        <div className={classes.root}>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid item xs={3} className={classes.header}>
                        <h3>Preview & Save</h3>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianToggle('panel1')}>
                        <AccordionSummary
 className={classes.AccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className={classes.heading}>Basic Details</Typography>
                            <Typography className={classes.secondaryHeading}></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ width: '100%' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Product Type : </strong></label><span>{props?.productDetails?.productBasicDetails?.productTypeName}klkjknk;hnkjhbjb</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Product Name : </strong></label><span>{props?.productDetails?.productBasicDetails?.name}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Product Market : </strong></label><span>{props?.productDetails?.productBasicDetails?.productMarketName}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Description : </strong></label><span>{props?.productDetails?.productBasicDetails?.description}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Client Type : </strong></label><span>{props?.productDetails?.productBasicDetails?.clientTypeName}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Group Type : </strong></label><span>{props?.productDetails?.productBasicDetails?.groupTypeName}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Product Currency : </strong></label><span>{props?.productDetails?.productBasicDetails?.productCurrencyCd}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Valid From : </strong></label><span>{props?.productDetails?.productBasicDetails?.validFrom}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Valid Upto : </strong></label><span>{props?.productDetails?.productBasicDetails?.validUpTo}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <label><strong>Premium Currency : </strong></label><span>{props?.productDetails?.productBasicDetails?.premiumCurrencyCd}</span>
                                    </Grid>
                                </Grid>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleAccordianToggle('panel2')}>
                        <AccordionSummary
 className={classes.AccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography className={classes.heading}>Benefit Design</Typography>
                            <Typography className={classes.secondaryHeading}>

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EO2V2BenefitRuleTreeViewComponent hierarchy={previewBenefitHierarchy} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleAccordianToggle('panel3')}>
                        <AccordionSummary
 className={classes.AccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography className={classes.heading}>Service Design</Typography>
                            <Typography className={classes.secondaryHeading}>

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ width: '100%' }}>
                                <ServiceDesignTable designList={rows} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
            </Grid>

        </div>
    );
}