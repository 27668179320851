import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AgentHierarchyComponent from "./hr/agent.hierarchy.component";
import ClientHierarchyComponent from "./hr/client.hierarchy.component";

export default function HRRoutersComponent(props) {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/hr/agent-hierarchy" component={AgentHierarchyComponent} />
        <Route exact path="/hr/employee-hierarchy" component={ClientHierarchyComponent} />
      </Switch>
    </React.Fragment>
  );
}