import { Button } from 'primereact/button';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { QuotationService } from '../../../remote-api/api/quotation-services';
import QuotationBasicDetailsComponent from './basic.deatils';
import MemberUploadComponent from './member.upload.component';
import QuotationDesignComponent from './quotation.design';

const quotationService = new QuotationService();

const useStyles = theme => ({
  quotationFormRoot: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  labelContainer: {
    borderRadius: 4,
    boxShadow: 'none',
    // '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important',
  },
  stepperContent: {
    borderRadius: 4,
    margin: '15px 0',
    /* boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)', */
  },
  steperAction: {
    textAlign: 'right',
    padding: '20px 30px',
    paddingTop: 0,
    margin: '15px 0',
  },
});

const ColorlibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

function getSteps() {
  return ['Basic Details', 'Member Upload', 'Quotation Design'];
}

function getStepContent(step, handleNext, quotationDetails, updateQuotation, getQuoationDetailsByID) {
  switch (step) {
    case 0:
      return <QuotationBasicDetailsComponent handleNext={handleNext} quotationDetails={quotationDetails} />;
    case 1:
      return <MemberUploadComponent quotationDetails={quotationDetails} getQuoationDetailsByID={getQuoationDetailsByID} />;
    case 2:
      return <QuotationDesignComponent quotationDetails={quotationDetails} updateQuotation={updateQuotation} />;
    default:
      return <div>Default</div>;
  }
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.iconRoot, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      <strong>{props.icon}</strong>
    </div>
  );
}

const useColorlibStepIconStyles = makeStyles(theme => ({
  iconRoot: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.light}`,
    color: theme.palette.common.white,
  },
}));

const steps = getSteps();

class QuotationFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      quotationDetails: {},
    };
  }

  componentDidMount() {
    if (localStorage.getItem('quotationId')) {
      localStorage.removeItem('quotationId');
    }
    if (this.props.match.params.quotationId) {
      localStorage.setItem('quotationId', this.props.match.params.quotationId);
      quotationService.getQuoationDetailsByID(this.props.match.params.quotationId).subscribe(res => {
        this.setState({
          ...this.state,
          quotationDetails: res,
        });
      });
    }
  }

  componentWillUnmount() {
    if (localStorage.getItem('quotationId')) {
      localStorage.removeItem('quotationId');
      localStorage.removeItem('prospectID');
      localStorage.removeItem('proposerid');
    }
  }

  navigateToList = () => {
    this.props.history.push(`/quotations?mode=viewList`);
  };

  getQuoationDetailsByID = () => {
    const quotationId = localStorage.getItem('quotationId');
    quotationService.getQuoationDetailsByID(quotationId).subscribe(res => {
      this.setState({ quotationDetails: res });
    });
  };

  handleNext = () => {
    if (this.state.activeStep > 1) {
      this.navigateToList();
    } else {
      this.setState({
        ...this.state,
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  handleBack = () => {
    this.setState({
      ...this.state,
      activeStep: this.state.activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      ...this.state,
      activeStep: 0,
    });
  };

  updateQuotation = (o, changeType = '') => {
    this.setState({
      ...this.state,
      quotationDetails: { ...o, changeType },
    });
  };

  render() {
    const { classes } = this.props;
    const { activeStep, quotationDetails } = this.state;

    return (
      <div className={classes.quotationFormRoot}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              className={classes.labelContainer}>
              {steps.map(label => {
                return (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className={classes.stepperContent}>
              {activeStep === steps.length ? (
                <div className={classes.steperAction}>
                  <Typography className={classes.instructions}>All steps completed</Typography>
                  <Button onClick={this.handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography className={classes.instructions}>
                    {getStepContent(
                      activeStep,
                      this.handleNext,
                      quotationDetails,
                      this.updateQuotation,
                      this.getQuoationDetailsByID,
                    )}
                  </Typography>
                  <div className={classes.steperAction}>
                    <Button disabled={activeStep === 0} onClick={this.handleBack} className={`p-button-text ${classes.backButton}`}>
                      Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(QuotationFormComponent));
