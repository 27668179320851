import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import "date-fns";
import * as React from "react";
import { ClientService, ProspectService } from "../../../remote-api/api/client-services";



const clientservice = new ClientService();
const prospectservice = new ProspectService();



export default function InvoiceAgentListModal(props) {


    const handleClose = () => {
        props.handleCloseAgentListModal();
    }




    return (

        <Dialog open={props.openAgentListModal} onClose={handleClose} aria-labelledby="form-dialog-title" disableEnforceFocus>
            <DialogTitle id="form-dialog-title">Agents</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Agent name</TableCell>
                                <TableCell>Commission value</TableCell>
                                <TableCell align="right">Final value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.selectedAgentsList.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                        {row.commissionValue}%
                                    </TableCell>
                                    <TableCell align="right">{row.finalValue}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="p-button-text" color="primary">
                    Cancel
          </Button>

            </DialogActions>
        </Dialog>
    );
}