import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import FundListComponent from './components/fund.list.component';
import FundInvoiceDetails from './components/fund.invoice.details.component';
import FundConfigForm from './components/fund-config/fund.config.form';
import FundConfigManagementComponent from './components/fund-config/fund.config.management.component';

const roleService = new RoleService();

export default function FundsRoutersComponent(props) {
  const history = useHistory();
  const { path } = useRouteMatch();
  const hasAccess = roleService.checkRouteAccess(props, 'PROVIDER');
  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path} component={FundInvoiceDetails} />
          <Route exact path={`${path}/statement`} component={FundInvoiceDetails} />
          <Route exact path={`${path}/statement/details`} component={FundListComponent} />
          <Route exact path={`${path}/config`} component={FundConfigManagementComponent} />
          <Route exact path={`${path}/config/:id`} component={FundConfigForm} />
        </Switch>
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
