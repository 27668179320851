import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { MemberFieldConstants } from '../../@jumbo/constants/MemberFieldConstants';
import { replaceAll, toTitleCase } from '../../services/utility';
import { ProspectService } from '../remote-api/api/client-services';
import { MemberProcessService, MemberService } from '../remote-api/api/member-services';
import { Eo2v2DataGrid } from '../shared-components';
import moment from 'moment';
import RoleService from '../../services/utility/role';
import { Button } from 'primereact/button';
import { Box, Checkbox, CircularProgress, Icon, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Toast } from 'primereact/toast';
import { CloseOutlined } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const PAGE_NAME = 'MEMBER';
const roleService = new RoleService();
const prospectService = new ProspectService();
const memberService = new MemberService();
const memberProcessService = new MemberProcessService();

const prospectDataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['code'] = pageRequest.searchKey.trim();
    pageRequest['displayName'] = pageRequest.searchKey.trim();
    pageRequest['mobileNo'] = pageRequest.searchKey.trim();
  }
  delete pageRequest.searchKey;
  return prospectService.getProspects(pageRequest);
};

const useStyles = theme => ({
  formControl: {
    width: '100%',
  },
  searchBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  searchMemberBox: {
    width: '100%',
  },
});

const s = new BehaviorSubject({});
const observable = s.asObservable();
let memberPageRequest = {
  page: 0,
  size: 10,
  summary: true,
  active: true,
  sort: ['rowCreatedDate dsc'],
};

const handleState2Change = (component, key, value) => {
  component.setState(
    prevState => ({
      state2: {
        ...prevState.state2,
        [key]: value,
      },
    }),
    () => {
      component.logState();
    },
  );
};

class MemberListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toast = React.createRef();
    this.state = {
      searchType: 1,
      searchResult: [],
      columnsDefinations: [],
      selectedId: '',
      textInput: '',
      reloadTable: false,
      vipOrPoliticalData: [],
      openSnack: false,
      alertMsg: '',
      sourceId: '',
      memberId: '',
      isLoading: false,
      selectedImage: {
        documentType: '',
        docFormat: '',
        documentName: '',
        documentOriginalName: '',
      },

      state2: {
        policyStartDate: new Date(),
        policyEndDate: new Date(),
      },
    };
    this.handleState2Change = (key, value) => handleState2Change(this, key, value);
    this.logState = this.logState.bind(this);
    this.configuration = {
      enableSelection: false,
      scrollHeight: '200px',
      pageSize: 10,
      isCheckbox: true,
      actionButtons: roleService.checkActionPermission(PAGE_NAME, '', () => {}, this.actionBtnList),
      header: {
        enable: true,
        addCreateButton: this.hasAccess('CREATE', this.actionBtnList),
        onCreateButtonClick: this.handleOpen,
        text: 'Member Management',
        enableGlobalSearch: false,
        searchText: 'Search by code, name, mobile',
        //   onSelectionChange: handleSelectedRows,
        //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
        //   selectionMenuButtonText: "Action"
      },
    };

    this.getMemberConfiguration();
  }
  openEditSection = receipt => {
    // let id = receipt.membershipNo.replace(/\//g, "-");
    this.props.history.push(`/member-upload/member/detail/${receipt?.id}`);
  };
  logState() {
    console.log(this.state);
  }

  actionBtnList = [
    {
      key: 'update_receipt',
      icon: 'pi pi-eye',
      className: 'ui-button-warning',
      onClick: this.openEditSection,
    },
  ];

  doChangeSearchType = e => {
    const { value } = e.target;

    this.setState({ ...this.state, searchType: value });
    memberPageRequest.page = 0
    // this.setState({ ...this.state, textInput: '' });
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if 'count' has been updated
    // if (prevState.searchType !== this.state.searchType) {
      console.log('State has been updated:', this.state.searchType);
      // You can add additional logic here if needed
    // }
  }


  onButtonClick2 = () => {
    this.setState({ ...this.state2, isLoading: true });
    this.setState({ ...this.state2, reloadTable: true });
  };
  onButtonClick = () => {
    this.setState({ ...this.state, isLoading: true });
    this.setState({ ...this.state, reloadTable: true });
  };

  doSearch = e => {
    const txt = e.target.value;
    observable
      .pipe(
        filter(searchTerm => txt && txt.length > 2),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchKey => {
          return prospectDataSource$({ searchKey: txt, page: 0, size: 10 });
        }),
      )
      .subscribe(res => {
        if (res?.content?.length) {
          this.setState({ searchResult: res.content });
        }
      });
  };

  doSelectValue = (e, newValue) => {
    if (newValue && newValue.id) {
      memberPageRequest.prospectId = newValue.id;
      memberPageRequest.status = 'POLICY_CREATED';
      this.setState({
        ...this.state,
        selectedId: newValue.id,
      });
    }
  };

  handleCheckboxChange = (e, id) => {
    const { name, checked } = e.target;
    let data = [...this.state.vipOrPoliticalData];
    let index = data.findIndex(item => item.id === id);
    data[index][name] = checked;
    this.setState({ ...this.state, vipOrPoliticalData: data });
  };

  //Profile upload an image function
  handleImage = e => {
    const file = e.target['files'][0];
    const reader = new FileReader();
    reader.onload = () => {
      const { selectedImage } = this.state;
      const formData = new FormData();
      formData.append('docType', 'memberUpload');
      formData.append('filePart', file);
      memberService.uploadProfile(formData, this.state.memberId).subscribe(response => {
        selectedImage['documentName'] = response.id;
        selectedImage['docFormat'] = response.docFormat;
        this.setState({ selectedImage });
        this.toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'File Uploaded Successfully!!',
          life: 3000,
        });
        window.location.reload();
      });
    };
    reader.readAsDataURL(file);
  };


  getMemberConfiguration = () => {
    memberService.getMemberConfiguration().subscribe(res => {
      if (res.content && res.content.length > 0) {
        let newData6 = res.content[0].fields.splice(0, 11, { name: 'POLICY_NUMBER' });
        let data = res.content[0].fields.splice(1, 11, { name: 'MEMBERSHIP_NO' });
        let newData = res.content[0].fields.splice(2, 11, { name: 'NAME' });
        let newData2 = res.content[0].fields.splice(3, 11, { name: 'DATE_OF_BIRTH' });
        let newData3 = res.content[0].fields.splice(4, 11, { name: 'GENDER' });
        let newData4 = res.content[0].fields.splice(5, 11, { name: 'MOBILE_NO' });
        let newData5 = res.content[0].fields.splice(6, 11, { name: 'EMAIL' });

        const colDef = res.content[0].fields.map(r => ({
          field: MemberFieldConstants[r.name.toUpperCase()],
          headerName: toTitleCase(replaceAll(r.name, '_', ' ')),
        }));
        let newObj1 = {
          field: 'hello',
          headerName: 'Is Political?',
          checkbox: true,
          body: rowData => {
            const data = this.state.vipOrPoliticalData.filter(item => item.id === rowData.id);
            return (
              <Checkbox
                name="political"
                checked={data[0]?.political}
                onChange={e => this.handleCheckboxChange(e, rowData.id)}
              />
            );
          },
        };
        let newObj2 = {
          field: 'hello',
          headerName: 'Is VIP?',
          checkbox: true,
          body: rowData => {
            const data = this.state.vipOrPoliticalData.filter(item => item.id === rowData.id);
            return <Checkbox name="vip" checked={data[0]?.vip} onChange={e => this.handleCheckboxChange(e, rowData.id)} />;
          },
        };

        const newObj3 = {
          field: 'image',
          headerName: 'PROFILE UPLOAD',
          body: rowData => {
            return (
              <div>
                {' '}
                <AddAPhotoIcon
                  onClick={() => {
                    this.setState({ ...this.state, memberId: rowData?.memberId });
                    this.fileInput.click();
                  }}
                />
                <input
                  type="file"
                  ref={input => (this.fileInput = input)}
                  onChange={e => this.handleImage(e)}
                  accept="image/jpeg, image/png"
                  style={{ display: 'none' }}
                />
              </div>
            );
          },
        };

        colDef.push(newObj1);
        colDef.push(newObj2);
        colDef.push(newObj3);
        this.setState({
          ...this.state,
          columnsDefinations: colDef,
        });
      }
    });
  };

  hasAccess = accessName => {};

  handleOpen = () => {};

  style = {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    // width: "100%",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  handleSave = () => {
    memberService.saveMemberType(this.state.vipOrPoliticalData).subscribe(res => {
      // memberService.saveMemberType(this.state.vipOrPoliticalData, this.state.sourceId).subscribe(res => {
      this.setState({ ...this.state, openSnack: true, alertMsg: 'Member types updated' });
      window.location.reload();
    });
  };

  handleMsgErrorClose = () => {
    this.setState({ ...this.state, openSnack: false, alertMsg: '' });
  };

  render() {
    const { classes } = this.props;
    const { searchType, searchResult, selectedId, textInput, reloadTable, state2 } = this.state;

    const dataSource1$ = (pageRequest) => {
      memberPageRequest={...memberPageRequest, ...pageRequest}
      if (searchType != 1 && searchType != 2) {
        memberPageRequest.key = searchType === 3 ? 'MEMBERSHIP_No' : 'NAME';
        memberPageRequest.value = textInput.trim();
        delete memberPageRequest['prospectId'];
        delete memberPageRequest.status
      }
      if (searchType === 2) {
        memberPageRequest['qutationNumber'] = textInput.trim();
      }
      if (searchType === 4) {
        delete memberPageRequest.key;
        delete memberPageRequest.value;
        memberPageRequest['name'] = textInput.trim();
      }
      if (searchType === 5) {
        const formatDate = value => {
          const renewalDate = new Date(value);
          renewalDate.setTime(renewalDate.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours
          return renewalDate.getTime();
        };

        // Remove unnecessary properties
        delete memberPageRequest.key;
        delete memberPageRequest.value;
        delete memberPageRequest.sort;

        // Format the dates and set them in the request
        memberPageRequest['fromDate'] = formatDate(state2.policyStartDate);
        memberPageRequest['toDate'] = formatDate(state2.policyEndDate);
      }
      if (searchType === 1) {
        delete memberPageRequest.key;
        delete memberPageRequest.value;
        delete memberPageRequest['qutationNumber'];
      }
      return memberProcessService.getMemberRequests(memberPageRequest).pipe(
        tap(data => {
          this.setState({ ...this.state, sourceId: data?.content[0]?.sourceId, memberId: data?.content[0]?.memberId });
        }),
        map(data => {
          this.setState({ ...this.state, reloadTable: false });
          let content = data.content;
          let temp = [];
          content.map(el => {
            let obj = {
              id: el?.id,
              vip: el?.vip,
              political: el?.political,
            };
            temp.push(obj);
          });
          this.setState({ ...this.state, vipOrPoliticalData: temp });
          let records = content.map(item => {
            item['dateOfBirth'] = moment(item.dateOfBirth).format('DD MMM YYYY');
            return item;
          });
          data.content = records;
          this.setState({ ...this.state, isLoading: false });
          return data;
        }),
      );
    };
    
    return (
      <>
        <Toast ref={this.toast} />{' '}
        <div className="grid-type">
          <Snackbar
            open={this.state.openSnack}
            autoHideDuration={4000}
            onClose={this.handleMsgErrorClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={this.handleMsgErrorClose} severity="error">
              {this.statealertMsg}
            </Alert>
          </Snackbar>
          <Grid container spacing={7}>
            <Grid item xs={12} className={classes.searchBoxContainer}>
              <Grid
                item
                xs={2}
                className="grid-type-drop"
                style={{ marginInline: '17px', marginTop: '0px', paddingBottom: '13px' }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="member-type-select-outlined-label">Type</InputLabel>
                  <Select
                    labelId="member-type-select-outlined-label"
                    id="member-type-select-outlined"
                    label="Type"
                    value={searchType}
                    onChange={this.doChangeSearchType}>
                    <MenuItem value={1}>Proposer</MenuItem>
                    <MenuItem value={2}>Quotation</MenuItem>
                    <MenuItem value={3}>Membership Number</MenuItem>
                    <MenuItem value={4}>Member Name</MenuItem>
                    <MenuItem value={5}>Date</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                {searchType === 1 ? (
                  <Autocomplete
                    id="search-member-box"
                    options={searchResult}
                    getOptionLabel={option => option.displayName}
                    renderOption={option => (
                      <React.Fragment>
                        {option.displayName} ({option.mobileNo})
                      </React.Fragment>
                    )}
                    className={classes.searchMemberBox}
                    onChange={this.doSelectValue}
                    renderInput={params => <TextField {...params} variant="outlined" onChange={this.doSearch} />}
                  />
                ) : searchType === 5 ? (
                  <Box display={'flex'} alignItems={'center'} minWidth={'400px'}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        views={['year', 'month', 'date']}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        autoOk={true}
                        id="date-picker-inline"
                        label="Policy Start Date"
                        style={{ marginTop: 0 }}
                        // value={policyEndDate}
                        // onChange={handleEndDateChange}
                        value={this.state.state2.policyStartDate}
                        onChange={date => this.handleState2Change('policyStartDate', date)}
                        // disabled={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change ing date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        views={['year', 'month', 'date']}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        autoOk={true}
                        label="Policy End Date"
                        id="date-picker-inline"
                        style={{ marginTop: 0 }}
                        // value={policyEndDate}
                        // onChange={handleEndDateChange}
                        // disabled={true}

                        value={this.state.state2.policyEndDate}
                        onChange={date => this.handleState2Change('policyEndDate', date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change ing date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <Button
                      type="button"
                      onClick={this.onButtonClick2}
                      style={{ margin: '10px 0px 10px 10px', minWidth: '65px', textAlign: 'center' }}>
                      {this.state.isLoading ? (
                        <CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} />
                      ) : (
                        'Search'
                      )}
                    </Button>
                  </Box>
                ) : (
                  <Box display={'flex'}>
                    <TextField
                      variant="outlined"
                      onChange={e => this.setState({ ...this.state, textInput: e.target.value })}
                    />
                    <Button type="button" onClick={this.onButtonClick} style={{ margin: '10px 0px 10px 10px' }}>
                      {this.state.isLoading ? (
                        <CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} />
                      ) : (
                        'Search'
                      )}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box display={'flex'} justifyContent={'flex-end'} marginBottom={'10px'}>
            <Button onClick={this.handleSave}>Save changes</Button>
          </Box>

          <Eo2v2DataGrid
            $dataSource={dataSource1$}
            config={this.configuration}
            columnsDefination={this.state.columnsDefinations}
            onEdit={this.openEditSection}
            selectedId={selectedId}
            style={this.style}
            reloadTable={reloadTable}
          />
        </div>
      </>
    );
  }
}
export default withRouter(withStyles(useStyles)(MemberListComponent));
