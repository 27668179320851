export const config = {
  // rootUrl: "http://192.168.100.102",
  // rootUrl: "http://192.168.100.160",
  // rootUrl: "http://192.168.100.103"
  // rootUrl: "http://localhost:8012"
  // rootUrl: "http://192.168.100.96:8012",
  rootUrl: "https://api.eoxegen.com"
  // rootUrl: 'https://shaapi.eo2cloud.com',
  //rootUrl: "http://15.206.136.38:8012"
  // rootUrl: "http://localhost:8012"
};
