import DateFnsUtils from '@date-io/date-fns';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import "date-fns";
import { useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";


const validationSchema = yup.object({
    categoryId: yup.string("Select Category").required("Category is required"),
    planId: yup.string("Select Plan").required("Plan is required")
});

const useStyles = makeStyles((theme) => ({
    input1: {
        width: "50%",
    },
    clientTypeRadioGroup: {
        flexWrap: "nowrap",
        "& label": {
            flexDirection: "row",
        },
    },
    formControl: {
        width: "100%"
    },
    formControl1: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

export default function ProviderCategorizeModal(props) {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            categoryId: "",
            // planId: "",
            startDate: new Date().getTime()
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleModalSubmit();
        },
    });

    const handleModalSubmit = (e) => {
        let payload = {
            startDate: formik.values.startDate,
            planId: formik.values.planId,
            categoryId: formik.values.categoryId,
            // providerIds: props.providerIds
        }
        props.handleCategorizeSubmit(payload);
    }

    const handleClose = () => {
        props.closeCategorizeModal();
    }

    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        const timestamp = new Date(date).getTime();
        formik.setFieldValue('startDate', timestamp);
    };

    return (

        <Dialog open={props.openCategoryModal} onClose={handleClose} aria-labelledby="form-dialog-title" disableEnforceFocus>
            <DialogTitle id="form-dialog-title">Provider Category</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                        {/* <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel
                                    id="demo-simple-select-label"
                                    style={{ marginBottom: "0px" }}
                                >
                                    Plan
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    name="planId"
                                    id="demo-simple-select"
                                    value={formik.values.planId}
                                    onChange={formik.handleChange}
                                    error={formik.touched.planId && Boolean(formik.errors.planId)}
                                    helperText={formik.touched.planId && formik.errors.planId}
                                >
                                    {props.planList.map((ele) => {
                                        return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel
                                    id="demo-simple-select-label"
                                    style={{ marginBottom: "0px" }}
                                >
                                    Category
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    name="categoryId"
                                    id="demo-simple-select"
                                    value={formik.values.categoryId}
                                    onChange={formik.handleChange}
                                    error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                                    helperText={formik.touched.categoryId && formik.errors.categoryId}
                                >
                                    {props.categoryList.map((ele) => {
                                        return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Start date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change ing date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </form>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button onClick={handleModalSubmit} color="primary">
                    Submit
          </Button>
            </DialogActions>
        </Dialog>
    );
}