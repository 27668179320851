import Grid from "@material-ui/core/Grid";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ProvidersService } from "../../remote-api/api/provider-services";
import ProviderDetails from "./provider.details.component";
import ProviderTabComponent from "./provider.list.main.component";

const providerService = new ProvidersService();
let csTableData$ = providerService.getProviders();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Provider() {
  const history = useHistory();
  const query = useQuery();
  const [rows, setRows] = React.useState([]);
  const columns = [
    { field: "code", headerName: "Provider Code", width: 350 },
    { field: "name", headerName: "Provider Name", width: 350 },
    { field: "type", headerName: "Provider type", width: 350 },
    {
      field: "contact",
      headerName: "Contact Number",
      width: 300,
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        const onClickEdit = () => {
          const { id } = params.row;
          history.push(`/provider/${id}?mode=edit`);
        };

        const onClickDelete = () => { };

        return (
          <div>
            <CreateIcon
              style={{ cursor: "pointer", color: "#626BDA" }}
              onClick={onClickEdit}
            />
            <DeleteIcon
              style={{ cursor: "pointer", color: "#626BDA", marginLeft: "5px" }}
              onClick={onClickDelete}
            />
          </div>
        );
      },
      // valueGetter: (params) =>
      //   `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    },
  ];

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe((result) => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach((ele) => {
            tableArr.push({
              code: ele.providerBasicDetails.code,
              name: ele.providerBasicDetails.name,
              type: ele.providerBasicDetails.type,
              contact:
                ele.providerBasicDetails.contactNos.length > 0
                  ? ele.providerBasicDetails.contactNos[0].contactNo
                  : "",
              id: ele.id,
            });
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  // useObservable(csTableData$, setRows);

  return (
    <div>
      {query.get("mode") === "create" ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            height: "2em",
            color: "#000",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >

          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Provider Management- Create Provider
          </span>

        </Grid>
      ) : null}

      {(() => {
        switch (query.get("mode")) {
          case "viewList":
            return <ProviderTabComponent/>;
            // return <ProviderListComponent/>;
          case "create":
            return <ProviderDetails />;
          default:
            history.push("/provider?mode=viewList");
        }
      })()}
    </div>
  );
}
