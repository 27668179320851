export const MemberFieldConstants = {
    id: "id",
    RELATIONS: "relations",
    EMPLOYEE_ID: "employeeId",
    NAME: "name",
    DATE_OF_BIRTH: "dateOfBirth",
    AGE: "age",
    GENDER: "gender",
    EMAIL: "email",
    MOBILE_NO: "mobileNo",
    MEMBER_ADDRESS_LINE_1: "memberAddressLine1",
    MEMBER_ADDRESS_LINE_2: "memberAddressLine2",
    DESIGNATION: "designation",
    UNIT: "unit",
    DEPARTMENT: "department",
    POSTAL_CODE: "postalCode",
    IDENTIFICATION_DOC_TYPE: "identificationDocType",
    IDENTIFICATION_DOC_NUMBER: "identificationDocNumber",
    PLAN_SCHEME: "planScheme",
    MEMBERSHIP_NO: "membershipNo",
    POLICY_NUMBER:"policyNumber",
    NOMINEE_NAME: "nomineeName",
    NOMINEE_RELATION: "nomineeRelation",
    NOMINEE_DOB: "nomineeDob",
    DATE_OF_JOINING: "dateOfJoining",
    EFT_DETAILS: "eftDetails",
    BANK_NAME: "bankName",
    BANK_BRANCH_CODE: "bankBranchCode",
    ACCOUNT_NAME: "accountName",
    ACCOUNT_NO: "accountNo",
    IFSC_CODE: "ifscCode",
    EMPLOYEE_NAME: "employeeName",
    prospectId: "prospectId",
    processRequestId: "processRequestId",
    sourceType: "sourceType",
    sourceId: "sourceId"
};