import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import "date-fns";
import * as React from "react";
import { InvoiceService } from "../../../remote-api/api/invoice-services";




const invoiceservice = new InvoiceService();



export default function InvoiceReversalModal(props) {
    const [remarks, setRemarks] = React.useState("");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const handleClose = () => {
        props.handleCloseReversalModal();
    }

    const handleModalSubmit = () => {
        props.submitReversalModal(remarks)
        // invoiceservice.revertInvoice(remarks,props.selectedInvoiceForReversal).subscribe(ele=> {
        //     props.handleCloseReversalModal();
        // })
        
    }

    const handleChange = (e) => {
        setRemarks(e.target.value);
    }


    return (

        <Dialog open={props.reversalModal} onClose={handleClose} fullWidth={fullWidth}
        maxWidth={maxWidth} aria-labelledby="form-dialog-title" disableEnforceFocus>
            <DialogTitle id="form-dialog-title">Invoice Reversal</DialogTitle>
            <DialogContent>
                <TextField
                    id="standard-multiline-flexible"
                    required
                    multiline
                    name="remarks"
                    value={remarks}
                    onChange={handleChange}
                    label="Remarks"
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="p-button-text" color="primary">
                    Cancel
          </Button>
                <Button onClick={handleModalSubmit} color="primary">
                    Submit
          </Button>

            </DialogActions>
        </Dialog>
    );
}