import { Box } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const Logo = ({ color, ...props }) => {
  const logoUrl = '/images/logo-full.png';
  // const logoUrl = color === 'white' ? '/images/logo-main.png' : '/images/logo-main.png';
  // const logoSymbolUrl = color === 'white' ? '/images/logo-main.png' : '/images/logo-main.png';
  const logoSymbolUrl = '/images/logo-full.png';

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <Box display={"flex"} justifyContent={"space-between"}>
            <CmtImage src={logoUrl} alt="logo" style={{ height: 38, padding: '2% 5%' }} />
          </Box>
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoSymbolUrl} alt="logo" style={{ height: 38, padding: '5%' }} />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
