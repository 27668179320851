import { Snackbar } from '@material-ui/core';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import 'date-fns';
import * as React from 'react';
import { HierarchyService } from '../../../remote-api/api/hierarchy-services';
import Asterisk from '../../../shared-components/components/red-asterisk';
const orgtypeservice = new HierarchyService();
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PositionAddModal(props) {
  const [remarks, setRemarks] = React.useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleClose = () => {
    props.closePositionModal();
  };

  const handleModalSubmit = () => {
    if (remarks !== null && remarks !== '') {
      let payload = {
        name: remarks,
        type: props.type,
        parentPosition: props.selectedNode?.id ? props.selectedNode.id : null,
      };
      orgtypeservice.addPosition(payload).subscribe(res => {
        props.submitPositionModal();
        setRemarks('');
      });
    }

    if (remarks === null || remarks === '') {
      setSnackbarMessage('Please enter position');
      setSnackbarOpen(true);
    }
    // props.submitPositionModal(remarks)
    // invoiceservice.revertInvoice(remarks,props.selectedInvoiceForReversal).subscribe(ele=> {
    //     props.closePositionModal();
    // })
  };

  const handleChange = e => {
    setRemarks(e.target.value);
  };

  return (
    <Dialog
      open={props.positionModal}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus>
      <DialogTitle id="form-dialog-title">Add Position</DialogTitle>
      <DialogContent>
        <TextField
          id="standard-multiline-flexible"
          // required
          multiline
          name="remarks"
          value={remarks}
          onChange={handleChange}
          label={<span>Position Name <Asterisk/></span>}
        />
        <Snackbar
          open={snackbarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}>
          <Alert onClose={handleSnackbarClose} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" className="p-button-text">
          Cancel
        </Button>
        <Button onClick={handleModalSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
