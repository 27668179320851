import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import TaxDetails from "./tax.details.component";
import TaxListComponent from "./tax.list.component";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Taxes() {
  const history = useHistory();
  const query = useQuery();


  return (
    <div>
      {query.get("mode") === "create" ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            height: "2em",
            color: "#000",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >

          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Tax Management- Create Tax
          </span>

        </Grid>
      ) : null}

      {(() => {
        switch (query.get("mode")) {
          case "viewList":
            return <TaxListComponent />;
          case "create":
            return <TaxDetails />;
          default:
            history.push("/taxes?mode=viewList");
        }
      })()}
    </div>
  );
}

