import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "date-fns";
import * as React from "react";
import { InvoiceService } from "../../remote-api/api/invoice-services";



const invoiceservice = new InvoiceService();



export default function DenominationConfirmModal(props) {
    const [remarks, setRemarks] = React.useState("");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const handleClose = () => {
        props.closeDeleteModal();
    }

    const handleModalSubmit = () => {
        props.handleDelete();
        // props.submitReversalModal(remarks)
        // invoiceservice.revertInvoice(remarks,props.selectedInvoiceForReversal).subscribe(ele=> {
        //     props.handleCloseReversalModal();
        // })
        
    }

    const handleChange = (e) => {
        setRemarks(e.target.value);
    }


    return (

        <Dialog open={props.openConfirmModal} onClose={handleClose} fullWidth={fullWidth}
        maxWidth={maxWidth} aria-labelledby="form-dialog-title" disableEnforceFocus>
            <DialogTitle id="form-dialog-title">Disable Denomination</DialogTitle>
            <DialogContent>
               <span>Are you sure to disable this denomination?</span>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    No
          </Button>
                <Button onClick={handleModalSubmit} color="primary">
                    Yes
          </Button>

            </DialogActions>
        </Dialog>
    );
}