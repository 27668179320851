import { withStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UsersFormComponent from './users.form.component';
import UsersListComponent from './users.list';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0',
  },
});

function UsersComponent(props) {
  const history = useHistory();
  const query = useQuery();

  const { classes } = props;

  const handleOpen = () => {
    history.push('/user-management/users?mode=create');
  };

  const handleEdit = row => {
    history.push(`/user-management/users/${row.userName}?mode=edit`);
  };

  return (
    <div>
      {(() => {
        switch (query.get('mode')) {
          case 'viewList':
            return <UsersListComponent handleEdit={handleEdit} handleOpen={handleOpen} />;
          case 'create':
            return <UsersFormComponent />;
          default:
            history.push('/user-management/users?mode=viewList');
        }
      })()}
    </div>
  );
}

export default withStyles(useStyles)(UsersComponent);
