export const errorCodes = {
  0: "No error",
  1: "Creation failed (fingerprint reader not correctly installed or driver files error)",
  2: "Function failed (wrong type of fingerprint reader or not correctly installed)",
  3: "Internal (invalid parameters to sensor API)",
  5: "DLL load failed",
  6: "DLL load failed for driver",
  7: "DLL load failed for algorithm",
  51: "System file load failure",
  52: "Sensor chip initialization failed",
  53: "Sensor line dropped",
  54: "Timeout",
  55: "Device not found",
  56: "Driver load failed",
  57: "Wrong image",
  58: "Lack of bandwidth",
  59: "Device busy",
  60: "Cannot get serial number of the device",
  61: "Unsupported device",
  101: "Very low minutiae count",
  102: "Wrong template type",
  103: "Invalid template",
  104: "Invalid template",
  105: "Could not extract features",
  106: "Match failed",
  1000: "No memory",
  4000: "Invalid parameter passed to service",
  2000: "Internal error",
  3000: "Internal error extended",
  6000: "Certificate error cannot decode",
  10001: "License error",
  10002: "Invalid domain",
  10003: "License expired",
  10004: "WebAPI may not have received the origin header from the browser",
  500: "Failed to fetch."
};