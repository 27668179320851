import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { map, switchMap } from 'rxjs/operators';
import { AgentsService } from '../../remote-api/api/agents-services';
import { ClientService } from '../../remote-api/api/client-services';
import { InvoiceService } from '../../remote-api/api/invoice-services';
import { ClientTypeService } from '../../remote-api/eo2v2-remote-api';
import { Eo2v2DataGrid } from '../../shared-components';
import InvoiceAgentListModal from './modals/invoice.agent.list.modal.component';
import InvoiceReversalModal from './modals/invoice.reversal.modal.component';

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 505,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  agentListButton: {
    marginLeft: '5px',
  },
}));

const invoiceService = new InvoiceService();
const agentsService = new AgentsService();
const clientservice = new ClientService();
const clienttypeervice = new ClientTypeService();

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  let clientData;
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['name'] = pageRequest.searchKey.trim();
  }
  return clientservice
    .getClients(pageRequest)
    .pipe(
      map(data => {
        let content = data.content;
        clientData = content
        let clientIds = content.map(item => item.id);

        return clientIds;
      }),
    )
    .pipe(
      switchMap(clientIds => {
        
        let pagerequestquery2 = {
          page: pageRequest.page,
          size: pageRequest.size,
          summary: false,
        };
        pagerequestquery2.sort = ['rowCreatedDate dsc'];
        // pagerequestquery2.clientOrProspectId= "1143540719000559616";
        if (pageRequest.searchKey) {
          
          // pagerequestquery2["invoiceDate"] = pageRequest.searchKey;
          pagerequestquery2['invoiceNumber'] = pageRequest.searchKey.trim();
          pagerequestquery2['clientIds'] = clientIds.trim();
        }
        delete pageRequest.searchKey;

        return invoiceService.getFundInvoice(pagerequestquery2).pipe(
          map(data2 => {
            let content = data2.content;
            let records = content.map(item => {
              let clientName = clientData?.find(ele=>ele?.id == item?.clientOrProspectId)
              item['dateOfInvoice'] = new Date(item.invoiceDate).toLocaleDateString();
              item['isReverted'] = item.reverted ? 'Yes' + ' ' + '(' + item.type + ')' : 'No';
              item['clientName'] = clientName?.clientBasicDetails?.displayName;
              return item;
            });
            data2.content = records;
            return data2;
          }),
        ).pipe(
          switchMap(data => {
            return clienttypeervice.getCleintTypes().pipe(
              map(ct => {
              data.content.forEach(cl => {
                ct.content.forEach(clienttype => {
                  if(cl.clientOrProspectType === clienttype.code || cl.clientOrProspectType === clienttype.id){
                    cl['clientOrProspectType'] = clienttype.name
                  }
                })
              })
              return data;
            }))
          }));
      }),
    );
};

// const dataSource$ = (pageRequest = {
//   page: 0,
//   size: 5,
//   summary: true,
//   active: true
// }) => {
//   agentsService.getAgents(pageRequest).
//     map(val => {
//     val.content.forEach(ele => {
//       ele['primaryContact'] = ele.agentBasicDetails.contactNos[0].contactNo
//     })
//     return val
//   })
// };
const columnsDefinations = [
  { field: 'invoiceNumber', headerName: 'Invoice Number' , body: (rowData) => (
    <span style={{ lineBreak: "anywhere" }}>
      {rowData.invoiceNumber}
    </span>)},
    { field: 'clientName', headerName: 'Client Name' },
  { field: 'invoiceType', headerName: 'Invoice Type' },
  { field: 'clientOrProspectType', headerName: 'Client Type' },
  { field: 'dateOfInvoice', headerName: 'Invoice date' },
  { field: 'totalInvoiceAmount', headerName: 'Total Amount' },
];

export default function FundInvoiceListComponent(props) {
  const history = useHistory();
  const [rows, setRows] = React.useState(props.rows);
  const [openAgentListModal, setOpenAgentListModal] = React.useState(false);
  const [reversalModal, setReversalModal] = React.useState(false);
  const [selectedAgentsList, setSelectedAgentsList] = React.useState([]);
  const [selectedInvoiceForReversal, setSelectedInvoiceForReversal] = React.useState('');
  const [reloadTable, setReloadTable] = React.useState(false);

  const classes = useStyles();

  const handleOpen = () => {
    history.push('/invoices?mode=create&type=fund');
  };

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const openEditSection = invoice => {
    
    localStorage.setItem("InvoiceNumber", invoice.invoiceNumber)
    history.push(`/invoices/${invoice.id}?mode=view&type=fund`);
  };

  const openAgentModal = invoice => {
    
    let arr = [];
    invoice.invoiceAgents.forEach(ag => {
      arr.push(ag.agentId);
    });
    
    let pageRequest = {
      page: 0,
      size: 100,
      agentIds: arr,
    };

    agentsService.getAgents(pageRequest).subscribe(res => {
      
      if (res.content.length > 0) {
        invoice.invoiceAgents.forEach(ag => {
          res.content.forEach(item => {
            if (ag.agentId === item.id) {
              ag['name'] = item.agentBasicDetails.name;
            }
          });
        });
        setSelectedAgentsList(invoice.invoiceAgents);
      }
      setOpenAgentListModal(true);
    });
  };

  const handleCloseAgentListModal = () => {
    setOpenAgentListModal(false);
    setSelectedAgentsList([]);
  };

  const handleCloseReversalModal = () => {
    setReversalModal(false);
  };

  const submitReversalModal = remarks => {
    invoiceService.revertFundInvoice(remarks, selectedInvoiceForReversal).subscribe(ele => {
      handleCloseReversalModal();
      setReloadTable(true)
      setTimeout(() => {
        setReloadTable(false)
      }, [3000]);
    });
  };

  const openReversalModal = item => {
    setSelectedInvoiceForReversal(item.id);
    setReversalModal(true);
  };

  const disableMenu = item => {
    return item.reverted;
  };
  const xlsColumns = [
    'invoiceNumber',
    'clientName',
    'invoiceType',
    'clientOrProspectType',
    'dateOfInvoice',
    'totalInvoiceAmount'
  ];
  
  const configuration = {
    enableSelection: false,
    scrollHeight: '285px',
    pageSize: 10,
    actionButtons: [
      {
        icon: 'pi pi-eye',
        className: 'ui-button-warning',
        onClick: openEditSection,
      },
      {
        icon: 'pi pi-users',
        className: classes.agentListButton,
        onClick: openAgentModal,
      },
      {
        icon: 'pi pi-replay',
        disabled: disableMenu,
        className: classes.agentListButton,
        onClick: openReversalModal,
      },
    ],
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: true,
      onCreateButtonClick: handleOpen,
      text: 'Fund Invoice Management',
      enableGlobalSearch: true,
      // searchText:"Search by code,name,type,contact"
      //   onSelectionChange: handleSelectedRows,
      //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
      //   selectionMenuButtonText: "Action"
    },
  };

  const style = {
    fontSize: "14px"
  }

  return (
    <div>
      
        {/* <DataGrid rows={rows} columns={props.columns} pageSize={10} /> */}
        <Eo2v2DataGrid
          $dataSource={dataSource$}
          config={configuration}
          columnsDefination={columnsDefinations}
          onEdit={openEditSection}
          // style={style}
          reloadTable={reloadTable}
        />
      <InvoiceAgentListModal
        openAgentListModal={openAgentListModal}
        handleCloseAgentListModal={handleCloseAgentListModal}
        selectedAgentsList={selectedAgentsList}
      />
      <InvoiceReversalModal
        reversalModal={reversalModal}
        handleCloseReversalModal={handleCloseReversalModal}
        selectedInvoiceForReversal={selectedInvoiceForReversal}
        submitReversalModal={submitReversalModal}
      />
    </div>
  );
}
