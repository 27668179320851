import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GuidelinesComponent from './guidelines.component';

export default function GuidelinesRouterComponent(props) {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <GuidelinesComponent />{' '}
        </Route>
        {/* <Route exact path={`${path}/member`}>
          {' '}
          <MembersComponent />{' '}
        </Route>
        <Route exact path={`${path}/member/detail/:id`}>
          {' '}
          <MemberDetail />{' '}
        </Route> */}
      </Switch>
    </React.Fragment>
  );
}
