import { Button } from 'primereact/button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { withRouter } from 'react-router-dom';
import { PermissionsService, RolesService } from '../../remote-api/api/user-management-service';
import SidemenuDraggable from './sidemenu.draggable';

const permissionsService = new PermissionsService();
const rolesService = new RolesService();

let rolesService$ = rolesService.getRoles();

const useStyles = theme => ({
  accessRightsListRoot: {
    padding: 20,
  },
  listOuterContainer: {
    padding: 15,
  },
  table: {
    minWidth: 650,
  },
  headerSection: {
    justifyContent: 'space-between',
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

/* const rolesList = [
    { id: 1, value: 'Front End Developer' },
    { id: 2, value: 'Developer' },
    { id: 3, value: 'Manager' },
    { id: 4, value: 'Ast. Manager' },
    { id: 5, value: 'Sr. Developer' },
    { id: 6, value: 'Jr. Developer' }
];
 */
class AccessRightsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      rolesList: [],
      selectedRoleId: '',
      accessTypes: [],
      menuList: [],
    };
  }
  componentDidMount() {
    rolesService$.subscribe(response => {
      this.setState({
        ...this.state,
        rolesList: response.map(({ name }) => ({ id: name, value: name })),
      });
    });

    permissionsService.getPermissions().subscribe(res => {
      const menuList = Object.keys(res).map((value, i) => ({
        id: `m${i + 1}`,
        value,
      }));

      const accessTypes = [];
      for (let i in res) {
        res[i].map(r => {
          const col = r.split('_')[0].trim();
          const isExist = accessTypes.some(c => c.name === col);
          if (!isExist) {
            accessTypes.push({ id: accessTypes.length + 1, name: col });
          }
        });
      }

      this.setState({
        ...this.state,
        permissions: res,
        menuList,
        accessTypes,
      });
    });
  }

  handleRoleChange = role => {

    rolesService.getRoleDetails(role.value).subscribe(res => {
      let rows = [];
      for (let key in res.permission) {
        const values = res.permission[key];
        const accessList = values.map(v => v.split('_')[0]).reduce((a, v) => ({ ...a, [v]: true }), {});
        const accessCheckedList = this.state.accessTypes.filter(a =>
          this.state.permissions[key].some(p => p.indexOf(a.name) > -1),
        );
        const fullAccess = accessCheckedList.every(({ name }) => accessList[name]);
        rows.push({
          menuCode: key,
          menuName: key,
          access: accessList,
          full: fullAccess,
        });
      }

      this.setState({
        ...this.state,
        selectedRoleId: res.name,
        rows,
      });
    });
  };

  createAccessRights = () => {
    this.props.history.push('/user-management/access-rights?mode=create');
  };

  editAccessRights = () => {
    this.props.history.push(`/user-management/access-rights/${this.state.selectedRoleId}?mode=edit`);
  };

  render() {
    const { classes } = this.props;
    const { rows, selectedRoleId, rolesList, accessTypes } = this.state;

    return (
      <div className={classes.accessRightsListRoot}>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={7} className={classes.headerSection}>
            <Grid item xs={3}>
              <Typography variant="h6" gutterBottom>
                Access Rights
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.actionBlock}>
              {selectedRoleId !== '' && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  style={{ marginRight: 5 }}
                  onClick={this.editAccessRights}>
                  Edit Access Rights
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={this.createAccessRights}>
                Create New Access Rights
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={7}>
            <Grid item xs={3}>
              <Paper elevation='none' className={classes.listOuterContainer}>
                <SidemenuDraggable
                  data={rolesList}
                  type="role"
                  selectedRoleId={selectedRoleId}
                  handleClick={this.handleRoleChange}
                />
              </Paper>
            </Grid>
            <Grid item xs={9}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Menu Code</TableCell>
                      <TableCell align="center">Menu Name</TableCell>
                      {accessTypes.map(acc => (
                        <TableCell key={acc.id} align="center">
                          {acc.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow key={row.menuCode}>
                        <TableCell component="th" scope="row">
                          {row.menuCode}
                        </TableCell>
                        <TableCell align="center">{row.menuName}</TableCell>
                        {accessTypes.map(acc => (
                          <TableCell key={acc.id} align="center">
                            {(row.access[acc.name] && <VerifiedUserIcon style={{ color: '#4caf50' }} />) || (
                              <CancelIcon style={{ color: 'rgb(220, 0, 78)' }} />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DndProvider>
      </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(AccessRightsList));
