import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AgentsService } from "../../remote-api/api/agents-services";
import InvoiceDetails from "./invoice.details.component";
import InvoiceViewListComponent from "./invoice.viewList.component";
import InvoiceDetailsMain from "./invoice.details.main.component";

const agentsService = new AgentsService();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Invoices() {
  const history = useHistory();
  const query = useQuery();
  
  return (
    <div>
       

      {(() => {
        switch (query.get("mode")) {
          case "viewList":
            return <InvoiceViewListComponent />;
          case "create":
            return <InvoiceDetailsMain />;
          default:
            history.push("/invoices?mode=viewList");
        }
      })()}
    </div>
  );
}
