import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Agents from './agents/agents.component';
import AgentsDetails from './agents/agents.details.component';
import CommissionDetailsComponent from './commission/commision.details.component';
import CommissionComponent from './commission/commision.component';
import TargetComponent from './agents/agents.target.details.component';

const roleService = new RoleService();

export default function AgentsRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props, 'AGENT');

  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Agents />{' '}
          </Route>
          <Route exact path={`${path}/commission`} component={CommissionComponent} />
          <Route exact path={`${path}/commission/:id`} component={CommissionDetailsComponent} />
          <Route exact path={`${path}/management`} component={Agents} />
          <Route exact path={`${path}/management/:id`} component={AgentsDetails} />
          <Route exact path={`${path}/target`} component={TargetComponent} />

        </Switch>
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
