import { Box, Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const ClientLogo = ({ color, ...props }) => {
  const clientLogo = '/images/sha_logo.png';
  // const logoUrl = color === 'white' ? '/images/logo-main.png' : '/images/logo-main.png';
  // const logoSymbolUrl = color === 'white' ? '/images/logo-main.png' : '/images/logo-main.png';
  const logoSymbolUrl = '/images/logo-full.png';

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
        {/* <Typography style={{fontSize:"24px", fontFamily:"monospace", color:"#000", fontWeight:"bold"}}>TORUS</Typography> */}
            {/* <CmtImage src={"https://aar-insurance.com/media/2023/05/aar-insurance-high-res-logo.png"} alt="logo" style={{ height: 38, padding: '2% 5%' }} /> */}
            <CmtImage src={clientLogo} alt="logo" style={{ height: 38, padding: '2% 5%' }} />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoSymbolUrl} alt="logo" style={{ height: 38, padding: '5%' }} />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default ClientLogo;
