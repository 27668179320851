import { Button } from 'primereact/button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import 'date-fns';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BankService } from '../../remote-api/api/banks-services';
import { AddressService, OrganizationTypeService } from '../../remote-api/api/master-services';
import BankAddressDetailsComponent from './bank.address.details.component';
import BankBasicDetailsComponent from './bank.basic.details.component';

const bankservice = new BankService();
const addressservice = new AddressService();
const orgtypeservice = new OrganizationTypeService();
let ot$ = orgtypeservice.getOrganizationTypes();

let pt$ = bankservice.getParentBanks();
let addr$ = addressservice.getAddressConfig();

const useStyles = makeStyles(theme => ({
  root: {
    // width: '100%',
    flexDirection: 'column',
    /* marginLeft: '5%', */
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepText: {
    '& span': {
      fontSize: '16px',
    },
  },
}));

function getSteps() {
  return ['Basic Details', 'Address details'];
}

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

export default function BankDetails(props) {
  const query1 = useQuery1();
  const history = useHistory();
  const { id } = useParams();

  const [parentBanks, setParentBanks] = React.useState([]);
  const [addressConfig, setAddressConfig] = React.useState([]);
  const [orgTypes, setOrgTypes] = React.useState([]);

  const [bankID, setBankID] = React.useState('');

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const isStepOptional = step => {
    // return step === 1;
  };

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.bankBasicDetails.bankName,
              id: ele.id,
            });
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  const useObservable1 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };
  // useObservable1(ot$, setOrgTypes);

  useEffect(() => {
    let subscription = addr$.subscribe(result => {
      if(result.length !== 0){
      result.forEach((prop, i) => {
        prop.addressConfigurationFieldMappings.forEach((field, j) => {
          // let fname = "field"+i+j;
          // field['fieldName'] = fname;
          field['value'] = '';
          if (field.sourceId !== null && field.sourceId !== '') {
            field['sourceList'] = [];
          }
          if (field.type === 'dropdown' && field.sourceId === null) {
            if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
              field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
            }
            // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
            //   field['sourceList'] = [];
            // }
          }
        });
      });
      setAddressConfig(result);
    }
    });
    return () => subscription.unsubscribe();
  }, [addr$, setAddressConfig]);

  useObservable(pt$, setParentBanks);

  const handleClose = event => {
    history.push(`/bank-management/banks?mode=viewList`);
    // window.location.reload();
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      //API call 1st step
    }
    if (activeStep === 1) {
      //API call 2nd step
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <BankBasicDetailsComponent
            pageMode={query1.get('mode')}
            handleClose={handleClose}
            handleNext={handleNext}
            parentBanks={parentBanks}
            setBankID={setBankID}
            orgTypes={orgTypes}
          />
        );
      case 1:
        return (
          <BankAddressDetailsComponent
            handleClose={handleClose}
            bankID={bankID}
            handleNext={handleNext}
            addressConfig={addressConfig}
          />
        );

      default:
        return 'Unknown step';
    }
  };

  return (
    <div>
      {query1.get('mode') === 'edit' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: '#000',
            fontSize: '18px',
          }}>
          <span
            style={{
              fontWeight: '600',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '5px',
            }}>
            Bank Management- Edit Bank
          </span>
        </Grid>
      ) : null}

      <div className={classes.root}>
        <Paper elevation='none'>
          <Stepper activeStep={activeStep} style={{ backgroundColor: 'transparent' }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} className={classes.stepText}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Paper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
              <Button onClick={handleClose} variant="contained" color="primary" className={classes.button}>
                Go to Table
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={`p-button-text ${classes.button}`}
                  style={{ marginRight: '5px' }}>
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button variant="contained" color="primary" onClick={handleSkip} className={`p-button-text ${classes.button}`}>
                    Skip
                  </Button>
                )}

                <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
