import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Eo2v2DataGrid } from '../../shared-components';
import { Observable, map } from 'rxjs';
import { MemberService } from '../../remote-api/api/member-services';
import moment from 'moment';
import { BenefitService, ProvidersService, ServiceTypeService } from '../../remote-api/eo2v2-remote-api';
import { makeStyles } from '@material-ui/styles';
import { PreAuthService } from '../../remote-api/api/claims-services';
import { Button } from 'primereact/button';

const memberService = new MemberService();
const benefitService = new BenefitService();
const preauthService = new PreAuthService();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  AccordionSummary: {
    backgroundColor: theme.palette.background.default,
  },
}));

const TypographyStyle2 = {
  fontSize: '12px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans - serif',
  fontWeight: '400',
  alignItems: 'end',
  display: 'flex',
  textTransform: 'capitalize',
};

const TypographyStyle1 = {
  fontSize: '13px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans - serif',
  alignItems: 'end',
  fontWeight: '600',
  display: 'flex',
  textTransform: 'capitalize',
};

const providerService = new ProvidersService();
const serviceDiagnosis = new ServiceTypeService();

let ps$ = providerService.getProviders();
let ad$ = serviceDiagnosis.getServicesbyId('867854874246590464', {
  page: 0,
  size: 1000,
  summary: true,
  active: true,
  nonGroupedServices: false,
});

const StyledTableCellRow = withStyles(theme => ({
  head: {
    padding: '8px',
  },
  body: {
    padding: '8px',
    backgroundColor: '#FFF',
    color: '#3C3C3C !important',
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCellHeader = withStyles(theme => ({
  head: {
    backgroundColor: '#F1F1F1',
    color: '#A1A1A1',
    padding: '8px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function MemberBalance() {
  const history = useHistory();
  const query = useQuery();
  const theme = useTheme();
  const [enteredMembershipNo, setEnteredMembershipNo] = React.useState();
  const [showBalanceDetails, setShowBalanceDetails] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [tableData, setTableData] = React.useState();
  const [claimTableData, setClaimTableData] = React.useState();
  const [memberData, setMemberData] = React.useState();
  const [benefitData, setBenefitData] = React.useState();
  const [showServices, setShowServices] = React.useState(false);
  const [diagnosisList, setDiagnosisList] = React.useState([]);
  const [providerList, setProviderList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const useObservable = (observable, setter) => {
    React.useEffect(() => {
      let subscription = observable.subscribe(result => {
        let arr = [];
        result.content.forEach(ele => {
          arr.push({ id: ele.id, diagnosisName: ele.name });
        });
        setter(arr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  const useObservable1 = (observable, setter) => {
    React.useEffect(() => {
      let subscription = observable.subscribe(result => {
        let arr = [];
        result.content.forEach(ele => {
          if (!ele.blackListed) {
            arr.push(ele);
          }
        });
        setter(arr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable(ad$, setDiagnosisList);
  useObservable1(ps$, setProviderList);

  const columnsDefinations = [
    { field: 'benefit', headerName: 'Benefit' },
    { field: 'waitingPeriod', headerName: 'Waiting Period' },
    { field: 'maxLimit', headerName: 'Max Limit(KSH)' },
    {
      field: 'consumed',
      headerName: 'Consumed(KSH)',
      body: rowData => (
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
          onClick={() => {
            setShowServices(false);
            getClaimsByBenefit(rowData?.benefitStructureId);
          }}>
          {rowData.consumed}
        </span>
      ),
    },
    { field: 'balance', headerName: 'Balance(KSH)' },
  ];

  const claimColumnsDefinations = [
    { field: 'claimNo', headerName: 'Claim No' },
    {
      field: 'lossDate',
      headerName: 'Loss Date',
      body: rowData => <span>{new Date(rowData?.lossDate).toLocaleDateString('en-GB')}</span>,
    },
    {
      field: 'serviceProviders',
      headerName: 'Service Provider',
      body: rowData => (
        <span>
          {rowData?.serviceProviders?.map((ele, idx) => {
            return (
              <p>
                {idx + 1}. {ele?.providerBasicDetails?.name}
              </p>
            );
          })}
        </span>
      ),
    },
    {
      field: 'diagnosis',
      headerName: 'Diagnosis',
      body: rowData => (
        <span>
          {rowData?.diagnosis.map((ele, idx) => {
            return (
              <p>
                {idx + 1}. {ele?.diagnosisName}
              </p>
            );
          })}
        </span>
      ),
    },
    { field: 'relation', headerName: 'Relation' },
    { field: 'claimStatus', headerName: 'Claim Status' },
    { field: 'amount', headerName: 'Amount (KSH)' },
  ];

  React.useEffect(() => {
    benefitService.getAllBenefit({ page: 0, size: 1000 }).subscribe(response => {
      setBenefitData(response.content);
    });
  }, []);

  const getClaimsByBenefit = id => {
    preauthService.getClaimsByBenefit(id, enteredMembershipNo).subscribe(response => {
      // const temp = response.map(item => {
      //   console.log(item)
      //   // const diagnosis = diagnosisList.filter(ele => item.diagnosis.includes(ele.id));
      //   let provider = [];
      //   providerList.map(ele => {
      //     item.serviceProviders.map(item => {
      //       if (ele.id === item.providerId) provider.push(ele);
      //     });
      //   });
      //   // item.diagnosis = diagnosis;
      //   item.serviceProviders = provider;
      //   return item;
      // });
      setClaimTableData(response);
      setShowServices(true);
    });
  };

  const handleSearch = () => {
    setLoading(true);
    if (enteredMembershipNo) {
      memberService.getMemberBalance(enteredMembershipNo).subscribe(res => {
        const temp = res.map(item => {
          const benefit = benefitData.find(ele => ele.id === item.benefit);
          item.benefitId = benefit.id;
          item.benefit = benefit.name;
          item.consumed = item.maxLimit - item.balance;
          return item;
        });
        setTableData(temp);
        getMemberDetails();
        setShowBalanceDetails(true);
        setLoading(false);
      });
    } else {
      alert('Enter membership number');
    }
  };

  const getMemberDetails = () => {
    let pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      key: 'MEMBERSHIP_NO',
      value: enteredMembershipNo,
      active: true,
    };
    memberService.getMember(pageRequest).subscribe(res => {
      // if (res.content?.length > 0) {
      setMemberData(res.content[0]);
      // }
    });
  };

  const clickHandler = () => {
    setShowBalanceDetails(true);
  };

  const configuration = {
    enableSelection: false,
    scrollHeight: '285px',
    pageSize: 10,
  };

  const claimConfiguration = {
    enableSelection: false,
    scrollHeight: '285px',
    pageSize: 10,
  };

  const claimData$ = new Observable(subscriber => {
    subscriber.next(claimTableData);
  });

  const data$ = new Observable(subscriber => {
    subscriber.next(tableData);
  });

  const dataSource$ = () => {
    return data$.pipe(
      map(data => {
        data.content = data;
        return data;
      }),
    );
  };

  const claimDataSource$ = () => {
    return claimData$.pipe(
      map(data => {
        data.content = data;
        return data;
      }),
    );
  };

  return (
    <>
      <Paper elevation="none" style={{ padding: 15 }}>
        <Grid container alignItems="flex-end">
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id="membershipNumber"
              name="membershipNumber"
              label="Search by Membership Number"
              style={{ width: '90%' }}
              value={enteredMembershipNo}
              onChange={e => setEnteredMembershipNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              color="secondary"
              style={{
                background: theme.palette.primary.main,
                color: '#fff',
                textAlign: 'center',
                minWidth: '68px',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={handleSearch}>
              {loading ? <CircularProgress style={{ color: 'white', width: '17px', height: '17px' }} /> : 'Search'}
            </Button>
          </Grid>
        </Grid>
        {showBalanceDetails && (
          <>
            <Accordion elevation="none" expanded={expanded} style={{ marginTop: '10px' }}>
              <AccordionSummary
                className={classes.AccordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                onClick={() => {
                  setExpanded(!expanded);
                }}>
                <Typography component="h6">Member Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid xs={12} sm={6} md={4}>
                    {/* <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>corporate name</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.corporate}</Typography>
                    </Box> */}
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>name of the member</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.name}</Typography>
                    </Box>
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>membership no</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.membershipNo}</Typography>
                    </Box>
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>gender</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.gender}</Typography>
                    </Box>
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>policy code</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.policyNumber}</Typography>
                    </Box>
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>first enrollment date</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>
                        {memberData?.dateOfJoining && moment(memberData?.dateOfJoining).format('DD/MM/YYYY')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} md={4}>
                    {/* <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>name of the member</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.name}</Typography>
                    </Box> */}
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>age</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.age}</Typography>
                    </Box>
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>type of policy</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>{memberData?.clientType}</Typography>
                    </Box>
                    <Box display={'flex'} marginLeft={'10%'} marginY={'10px'}>
                      <Typography style={TypographyStyle1}>policy period</Typography>&nbsp;
                      <span>:</span>&nbsp;
                      <Typography style={TypographyStyle2}>
                        {moment(memberData?.policyStartDate).format('DD/MM/YYYY')} -{' '}
                        {moment(memberData?.policyEndDate).format('DD/MM/YYYY')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Paper elevation="none" style={{ padding: 15, marginTop: '10px' }}>
              {/* <Eo2v2DataGrid $dataSource={dataSource$} config={configuration} columnsDefination={columnsDefinations} /> */}
              <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCellHeader>Benefit</StyledTableCellHeader>
                      <StyledTableCellHeader>Waiting Period</StyledTableCellHeader>
                      <StyledTableCellHeader>Max Limit(KSH)</StyledTableCellHeader>
                      <StyledTableCellHeader>Consumed(KSH)</StyledTableCellHeader>
                      <StyledTableCellHeader>Balance(KSH)</StyledTableCellHeader>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {tableData ? (
                      tableData?.map(row => (
                        <StyledTableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <StyledTableCellRow component="th" scope="row">
                            {row.benefit}
                          </StyledTableCellRow>
                          <StyledTableCellRow>{row.waitingPeriod}</StyledTableCellRow>
                          <StyledTableCellRow>{row.maxLimit}</StyledTableCellRow>
                          <StyledTableCellRow
                            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                            onClick={() => {
                              setShowServices(false);
                              getClaimsByBenefit(row?.benefitStructureId);
                            }}>
                            {row.consumed}
                          </StyledTableCellRow>
                          <StyledTableCellRow>{row.balance}</StyledTableCellRow>
                        </StyledTableRow>
                      ))
                    ) : (
                      <p style={{ color: '#3c3c3c', padding: '1%' }}>No data</p>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Paper>

      {showServices && (
        <Paper elevation="none" style={{ padding: '15px 30px', marginTop: '10px' }}>
          <Eo2v2DataGrid
            $dataSource={claimDataSource$}
            config={claimConfiguration}
            columnsDefination={claimColumnsDefinations}
          />
        </Paper>
      )}
    </>
  );
}
