import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import MemberBalance from './components/member.balance.component';
import RoleService from '../../services/utility/role';

const roleService = new RoleService();

export default function MemberBalanceRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props, 'MEMBER');
  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <MemberBalance />{' '}
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
