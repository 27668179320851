import { withStyles } from "@material-ui/styles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ServiceGroupingFormComponent from './service.grouping.form';
import ServiceGroupingList from './service.grouping.list';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = (theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 0'
    }
});

function ServiceGroupingComponent(props) {
    const history = useHistory();
    const query = useQuery();

    const { classes } = props;

    const handleOpen = () => {
        history.push("/masters/service-grouping?mode=create");
    }

    const handleEdit = (row) => {
        history.push(`/masters/service-grouping/${row.id}?mode=edit`);
    }

    return (
        <div>
            {(() => {
                switch (query.get("mode")) {
                    case 'viewList':
                        return (
                            <ServiceGroupingList handleEdit={handleEdit} handleOpen={handleOpen} />
                        );
                    case 'create':
                        return (
                            <ServiceGroupingFormComponent />
                        );
                    default:
                        history.push("/masters/service-grouping?mode=viewList");
                }
            })()}
        </div>
    );
}

export default withStyles(useStyles)(ServiceGroupingComponent);