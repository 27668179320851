import Grid from "@material-ui/core/Grid";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import RenewalListComponent from "./renewal.list.component"; 
import RenewalConfigForm from "./renewal.config";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function RenewalManagementComponent(props) {
  const query = useQuery();
  const history = useHistory();
// console.log(query.get("mode"))
  return (
    <div>
      {query.get("mode") === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            height: "2em",
            color: "#000",
            fontSize: "18px",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Renewal Management - Create Renewal
        </span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get("mode")) {
          case 'viewList':
            return (
              <RenewalListComponent />
              // <h4>Comming Soon....</h4>
            );
          case 'create':
            return (
              <RenewalListComponent />
              // <h4>Comming Soon....</h4>
            );
          default:
            history.push("/renewals/pending?mode=viewList");
        }
      })()}
    </div>
  );
}
