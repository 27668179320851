import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ClaimIntimationListComponent from './claim.intimation.table.component';
import ClaimsIntimationDetailsComponent from './intimation.component';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Intimation() {
  const history = useHistory();
  const query = useQuery();

  return (
    <div>
      {query.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: '#000',
            fontSize: '18px',
            fontWeight: 600,
          }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            Claim Management- Create Claim Intimation
          </span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get('mode')) {
          case 'viewList':
            return <ClaimIntimationListComponent />;
          case 'create':
            return <ClaimsIntimationDetailsComponent />;
          default:
            history.push('/claims/claims-intimation?mode=viewList');
        }
      })()}
    </div>
  );
}
