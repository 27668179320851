import { FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { getDateElements } from '../../../../../@jumbo/utils/dateHelper';
import { PreAuthService } from '../../../../remote-api/api/claims-services';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  headerCell: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

const preAuthService = new PreAuthService();


const validationSchema = yup.object().shape({
  costSavings: yup
    .number('Cost Saving must be a number')
    .typeError('Cost Saving must be a number')
    .required('Cost Saving is required'),
  remarks: yup.string().required('Remark is required'),
});

export default function HospitalVisitUpdationModal(props) {
  const classes = useStyles();
  const [visitHistory, setVisitHistory] = React.useState([]);
  const formik = useFormik({
    initialValues: {
      action: 'HOSPITAL_VISIT_UPDATION',
      estimatedAmount: props.data?.estimatedAmount || '',
      sanctionAmount: props.data?.sanctionAmount || '',
      costSavings: 0,
      remarks: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.admissionUpdationModalSubmit(values);
      props.handleCloseClaimModal();

    },
  });
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');


  const handleClose = () => {
    
    props.handleCloseClaimModal();
    setVisitHistory([])
  };
  const handleEdit = (e) => {
    // setState({ [`${e.target.name}`]: e.target.value });
  }
  const handleModalSubmit = () => {
    formik.handleSubmit();

  };

  React.useEffect(() => {
    if (props.preAuthId && props.admissionUpdationModal) {
      preAuthService.getHospitalVisitList(props.preAuthId).subscribe(res => {
        setVisitHistory(res.data);
      });
    }
  }, [props.preAuthId, props.admissionUpdationModal]);

  return (
    <Dialog
      open={props.admissionUpdationModal}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus>
      <DialogTitle id="form-dialog-title">Hospital Visit Updation</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={6}>
            <TextField
              id="estimatedAmount"
              name="estimatedAmount"
              value={formik.values.estimatedAmount}
              onChange={formik.handleChange}
              label="Estimated Amount"
              inputProps={
                { readOnly: true, }
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sanctionAmount"
              name="sanctionAmount"
              value={formik.values.sanctionAmount}
              onChange={formik.handleChange}
              label="Sanction Amount"
              inputProps={
                { readOnly: true, }
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <TextField
                id="costSavings"
                name="costSavings"
                value={formik.values.costSavings}
                onChange={formik.handleChange}
                type="number"
                label="Cost Savings *"
                helperText={formik.touched.costSavings && formik.errors.costSavings}
                error={formik.touched.costSavings && Boolean(formik.errors.costSavings)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField id="remarks"
                onChange={formik.handleChange}
                name="remarks"
                value={formik.values.remarks}
                label="Remark *"
                multiline
                minRows={5}
                helperText={formik.touched.remarks && formik.errors.remarks}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
              />
            </FormControl>
          </Grid>

        </Grid>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" className='p-button-text'>
          Cancel
        </Button>
        <Button onClick={handleModalSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
      {visitHistory.length > 0 && <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>TIME OF VISIT</TableCell>
              <TableCell className={classes.headerCell}>REMARKS</TableCell>
              <TableCell className={classes.headerCell}>COST SAVINGS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visitHistory.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{`${getDateElements(row.rowCreatedDate).date.numerical}`}</TableCell>
                <TableCell>{row.remarks}</TableCell>
                <TableCell>{row.costSavings}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
    </Dialog>
  );
}
