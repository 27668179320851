import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


const validationSchema = yup.object().shape({
  dod: yup
    .date('Admission Date must be a valid date')
    .typeError('Admission Date must be a valid date')
    .required('Admission Date is required'),

});

export default function DischargeCanellationModal(props) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      action: 'AWAITING_DISCHARGE_CANCELATION',
      reasonForDischargeCancelation: 'MEMBER HAS NOT TAKEN ADMISSION AS CONFIRMED BY THE HOSPITAL',
      dod: '',
      remarks: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const dod = new Date(values.dod);
      const timestampMilliseconds = dod.getTime();

      const updatedValues = {
        ...values,
        dod: timestampMilliseconds,
      };

      props.admissionUpdationModalSubmit(updatedValues)
      props.handleCloseClaimModal();
    },
  });
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');


  const handleClose = () => {
    props.handleCloseClaimModal();
  };
  const handleEdit = (e) => {
    // setState({ [`${e.target.name}`]: e.target.value });
  }
  const handleModalSubmit = () => {
    formik.handleSubmit();

  };

  return (
    <Dialog
      open={props.admissionUpdationModal}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus>
      <DialogTitle id="form-dialog-title">Discharge Cancellation</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>

          <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              id="demo-simple-select-label"
              style={{ marginBottom: "0px" }}
            >
              Reason For Admission Cancellation
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="reasonForDischargeCancelation"
              name="reasonForDischargeCancelation"
              value={formik.values.reasonForDischargeCancelation}
              onChange={formik.handleChange}
            >
              <MenuItem value="MEMBER HAS NOT TAKEN ADMISSION AS CONFIRMED BY THE HOSPITAL">
                MEMBER HAS NOT TAKEN ADMISSION AS CONFIRMED BY THE HOSPITAL
              </MenuItem>
              <MenuItem value="MEMBER WENT FOR REIMBURSEMENT">
                MEMBER WENT FOR REIMBURSEMENT
              </MenuItem>
              <MenuItem value="MEMBER HAS SENT A LETTER TO CANCEL HIS CASHLESS APPROVAL">
                MEMBER HAS SENT A LETTER TO CANCEL HIS CASHLESS APPROVAL
              </MenuItem>
              <MenuItem value="WE ARE NOT THE TPA OF THIS DO">
                WE ARE NOT THE TPA OF THIS DO
              </MenuItem>
              <MenuItem value="SELF PAYMENT">
                SELF PAYMENT
              </MenuItem>
            </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl>
              <TextField
                id="dod"
                name="dod"
                onChange={formik.handleChange}
                label="Discharge Date *"
                type="datetime-local"
                defaultValue="2017-05-24T10:30"
                value={formik.values.dod}
                className={classes.textField}
                helperText={formik.touched.dod && formik.errors.dod}
                error={formik.touched.dod && Boolean(formik.errors.dod)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField id="remarks"
                onChange={formik.handleChange}
                name="remarks"
                value={formik.values.remarks}
                label="Remark"
                multiline
                minRows={5}
                helperText={formik.touched.remarks && formik.errors.remarks}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
              />
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" className='p-button-text'>
          Cancel
        </Button>
        <Button onClick={handleModalSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
