import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import DynamicAddressComponent from './address-management/dynamic-address.component';
import BenefitHierarchyAction from './benefit-management/benefit.hierarchy.component';
import BenefitManagement from './benefit-management/benefit.management';
import DenominationComponent from './denomination-management/denomination.main.component';
import ParametersActionComponent from './parameters-management/parameters.action.component';
import ParametersComponent from './parameters-management/parameters.component';
import ServiceGroupingAction from './service-grouping/service.grouping.action';
import ServiceGroupingComponent from './service-grouping/service.grouping.component';
import ProviderAddressConfig from './provider-config/provider-address.component';
import FundAddressConfig from './fund-config/fund-address.component';
import BenefitAddressComponent from './benefit-config/benefit-address.component';
import CommissionConfig from './commision-config/comission-config.component';
import BasicDetailComponent from './insurance-config/basic-detail.component';
import CallDetailComponent from './insurance-config/endorsement-detail.component';

const roleService = new RoleService();

export default function MastersRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props);

  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            <ParametersComponent />
          </Route>
          <Route exact path={`${path}/parameters`}>
            <ParametersComponent />
          </Route>
          <Route exact path={`${path}/parameters/:parameterId`}>
            <ParametersActionComponent />
          </Route>
          <Route exact path={`${path}/benefit-hierarchy`}>
            <BenefitManagement />
          </Route>
          <Route exact path={`${path}/benefit-hierarchy/:hierarchyId`}>
            <BenefitHierarchyAction />
          </Route>
          <Route exact path={`${path}/service-grouping`}>
            <ServiceGroupingComponent />
          </Route>
          <Route exact path={`${path}/service-grouping/:id`}>
            <ServiceGroupingAction />
          </Route>
          <Route exact path={`${path}/denominations`}>
            <DenominationComponent />
          </Route>
          <Route exact path={`${path}/insurance-config/address-config`}>
            <DynamicAddressComponent />
          </Route>
          <Route exact path={`${path}/insurance-config/benefit-config`}>
            <BenefitAddressComponent />
          </Route>
          <Route exact path={`${path}/commissionrole-config`}>
            <CommissionConfig />
          </Route>
          <Route exact path={`${path}/provider-config`}>
            <ProviderAddressConfig />
          </Route>
          <Route exact path={`${path}/fund-config`}>
            <FundAddressConfig />
          </Route>
          <Route exact path={`${path}/insurance-config/basic-details`}>
            <BasicDetailComponent />
          </Route>
          <Route exact path={`${path}/insurance-config/call-management`}>
            <CallDetailComponent />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
