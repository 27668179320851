import React from 'react';
import { useHistory } from 'react-router-dom';
import { map } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { Eo2v2DataGrid } from '../../shared-components';
import { QuestionnaireService } from '../../remote-api/api/master-services/questionnaire.service';

const PAGE_NAME = 'Questionnaire';
const roleService = new RoleService();

const questionnaireService = new QuestionnaireService();

const columnsDefinations = [
  { field: 'question', headerName: 'Question' },
  { field: 'gender', headerName: 'Gender' },
  { field: 'minimumAge', headerName: 'Age From' },
  { field: 'maximumAge', headerName: 'Age To' },
];

export default function QuestionnaireListComponent(props) {
  const history = useHistory();

  const dataSource$ = (
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
    },
  ) => {
    pageRequest.sort = ['rowLastUpdatedDate dsc'];
    if (!pageRequest.searchKey) {
      return questionnaireService
        .getQuestionnaireList(
            pageRequest,
        )
        .pipe(
          map(data => {
            return data;
          }),
        );
    }
  };

  const handleOpen = () => {
    history.push('/questionnaire?mode=create');
  };

  const openEditSection = data => {
      history.push(`/questionnaire/${data.id}?mode=edit`);
  };

  const actionBtnList = [
    {
      key: 'update_policy',
      icon: 'pi pi-user-edit',
      className: 'ui-button-warning',
      onClick: openEditSection,
    },
  ];

  const xlsColumns = [
    'clientName',
    'policyNumber',
    'policyStartDate',
    'policyInitDate',
    'policyStatus'
  ];
  
  const configuration = {
    enableSelection: false,
    scrollHeight: '300px',
    pageSize: 10,
    actionButtons: roleService.checkActionPermission(PAGE_NAME, '', () => {}, actionBtnList),
    header: {
      enable: true,
      // enableDownload: true,
      // downloadbleColumns: xlsColumns,
      addCreateButton: 'CREATE',
      // addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      onCreateButtonClick: handleOpen,
      text: 'Questionnaire Management',
      // enableGlobalSearch: true,
      // searchText: 'Search by Name,Policy Number',
      // selectionMenuButtonText: 'Advance Search',
    },
  };

  return (
    <div>
      <Eo2v2DataGrid
        $dataSource={dataSource$}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
        config={configuration}
      />
    </div>
  );
}
