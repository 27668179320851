import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccessRightsFormComponent from './access.rights.form';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AccessRightsAction(props) {
  const query = useQuery();
  const history = useHistory();

  return (
    <div>
      {(() => {
        switch (query.get('mode')) {
          case 'edit':
            return <AccessRightsFormComponent />;
          default:
            history.push(`/user-management/access-rights/${props.match.params.roleName}?mode=edit`);
        }
      })()}
    </div>
  );
}
