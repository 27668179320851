import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import PremiumDesignFormComponent from './premium.design.form';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PremiumDesignAction(props) {
    const query = useQuery();
    const history = useHistory();

    return (
        <div>
            {(() => {
                switch (query.get("mode")) {
                    case 'edit':
                        return (
                            <PremiumDesignFormComponent />
                        );
                    default:
                        history.push(`/premium/${props.match.params.productId}?mode=edit`);
                }
            })()}
        </div>
    );
}