
// import * as React from "react";
// import * as yup from "yup";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import "date-fns";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DenominationService } from "../../remote-api/api/master-services";



const useStyles = makeStyles((theme) => ({
    input1: {
        width: "50%",
    },
    clientTypeRadioGroup: {
        flexWrap: "nowrap",
        "& label": {
            flexDirection: "row",
        },
    },
    formControl: {
        minWidth: 182,
    },
}));

function useQuery1() {
    return new URLSearchParams(useLocation().search);
}
const denoService = new DenominationService();

export default function DenominationsAddComponent(props) {

    const query2 = useQuery1();
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [denominationList, setDenominationList] = React.useState([
        {
            currencyType: "",
            currencyValue: "",
        },
    ]);
    const [denominationTypes, setDenominationTypes] = React.useState([{
        id: "1111", name: "Note"
    }, {
        id: "2222", name: "Metal Coin"
    }]);

    const handleInputChangeIndentification = (e, index) => {
        const { name, value } = e.target;
        const list = [...denominationList];
        list[index][name] = value;
        setDenominationList(list);
    };

    const handleRemoveClickIndentification = (index) => {
        const list = [...denominationList];
        list.splice(index, 1);
        setDenominationList(list);
    };

    const handleAddClickIndentification = () => {
        setDenominationList([
            ...denominationList,
            { currencyType: "", currencyValue: "" },
        ]);
    };



    const handleSubmit = () => {
        denominationList.forEach(ele => {
            if (ele.currencyType === '' || ele.currencyValue === '' || ele.currencyType === null || ele.currencyValue === null) {
                return
            }
        })
        denoService.saveDenomination(denominationList).subscribe(ele => {
            history.push(`/masters/denominations?mode=viewList`);
            // window.location.reload();
        })
    }


    const handleClose = () => {
        history.push(`/masters/denominations?mode=viewList`);
        // window.location.reload();
    }



    return (
        <Paper elevation='none'>
            <Box p={3} my={2}>
                {/* <form onSubmit={formik.handleSubmit}> */}
                {denominationList.map((x, i) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        Denomination type
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="currencyType"
                                        value={x.currencyType}
                                        onChange={(e) => handleInputChangeIndentification(e, i)}
                                        error={
                                            x.currencyType === null || x.currencyType === ''
                                        }
                                        helperText={x.currencyType === '' ? 'required field' : ' '}
                                    >
                                        {denominationTypes.map((ele) => {
                                            return <MenuItem key={ele.id} value={ele.name}>{ele.name}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="standard-basic"
                                    name="currencyValue"
                                    type="number"
                                    required
                                    value={x.currencyValue}
                                    onChange={(e) => handleInputChangeIndentification(e, i)}
                                    label="Denomination value"
                                    error={
                                        x.currencyValue === null || x.currencyValue === ''
                                    }
                                    helperText={x.currencyValue === '' ? 'required field' : ' '}

                                />
                            </Grid>

                            <Grid
                                item
                                xs={2}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                {denominationList.length !== 1 && (
                                    <Button
                                        className="mr10"
                                        onClick={() => handleRemoveClickIndentification(i)}
                                        variant="contained"
                                        color="secondary"
                                        style={{ marginLeft: "5px" }}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                )}
                                {denominationList.length - 1 === i && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginLeft: "5px" }}
                                        onClick={handleAddClickIndentification}
                                    >
                                        <AddIcon />
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    );
                })}
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "5px" }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                        <Button variant="contained" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
                {/* </form> */}
            </Box>
        </Paper>
    )
}