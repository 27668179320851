import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import QuotationManagementAction from './components/quotation.action';
import QuotationsComponent from './components/quotation.component';

export default function QuotationRouterComponent(props) {
  const { path } = useRouteMatch();
  return (
    // <React.Fragment>
    //   <Switch>
    //     <Route exact path="/quotations" component={QuotationFormComponent} />
    //   </Switch>
    // </React.Fragment>

    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <QuotationsComponent />{' '}
        </Route>
        <Route exact path="/quotations" component={QuotationsComponent} />
        <Route exact path="/quotations/:quotationId" > <QuotationManagementAction /> </Route>
      </Switch>
    </React.Fragment>
  );
}
