import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Provider from './providers/provider.component';
import ProviderDetails from './providers/provider.details.component';
import NegotiationComponent from './providers/negotiation/negotiation.component';
import VisitFeeComponent from './providers/visitFee/visitFee.component';

const roleService = new RoleService();

export default function ProvidersRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props, 'PROVIDER');
  
  if (hasAccess) {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <Provider />{' '}
        </Route>
        {/* <Route exact path="/provider" component={Provider} /> */}
        {/* <Route exact path="/provider/:id" component={ProviderDetails} /> */}

        <Route exact path={`${path}/negotiation`}>
          <NegotiationComponent />
        </Route>
        <Route exact path={`${path}/visit-fee`}>
          <VisitFeeComponent />
        </Route>
        <Route exact path={`${path}/:id`}>
          <ProviderDetails />
        </Route>
      </Switch>
    </React.Fragment>
  );
  }
  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
