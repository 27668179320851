import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
/* import { of } from 'rxjs'; */
import RoleService from '../../../services/utility/role';
import { BenefitStructureService } from '../../remote-api/api/benefit-structure-services';
import { Eo2v2DataGrid } from '../../shared-components';

const PAGE_NAME = 'BENEFIT';
const roleService = new RoleService();

const benefitStructureService = new BenefitStructureService();

const useStyles = theme => ({
  tableBg: {
    height: 505,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
});

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['code'] = pageRequest.searchKey.trim();
    // pageRequest['type'] = pageRequest.searchKey;
    pageRequest['description'] = pageRequest.searchKey.trim();
    // pageRequest['contactNo'] = pageRequest.searchKey;
  }
  delete pageRequest.searchKey;
  return benefitStructureService.getAllBenefitStructures(pageRequest);
};

const columnsDefinations = [
  { field: 'hierarchyCd', headerName: 'Hierarchy Code' },
  { field: 'description', headerName: 'Hierarchy Name' },
];

class BenefitHierarchyListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedRow: [] };
    this.selectedRow = [];

    this.initConfig();
  }

  initConfig = () => {
    this.configuration = {
      enableSelection: true,
      scrollHeight: '285px',
      pageSize: 10,
      actionButtons: roleService.checkActionPermission(PAGE_NAME, 'UPDATE', this.openEditSection),
      header: {
        enable: true,
        addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
        onCreateButtonClick: this.handleOpen,
        text: 'Benefit Management',
        enableGlobalSearch: true,
        onSelectionChange: this.handleSelectedRows,
        selectionMenus: [
          {
            icon: '',
            text: 'Sign Off',
            disabled: this.selectedRow.length == 0,
            onClick: this.approveBenefit,
          },
        ],
        searchText: 'Search by Hierarchy Code, Hierarchy Name',
        //   onSelectionChange: handleSelectedRows,
        //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
        //   selectionMenuButtonText: "Action"
      },
    };
  };

  hasAccess = accessName => {
    const access_details = JSON.parse(localStorage.getItem('access_details'));
    const accessList = access_details.filter(ad => ad.indexOf(PAGE_NAME) > -1).map(ac => ac.split('_')[0]);
    const status = accessList.some(a => a.indexOf(accessName) > -1);
    if (accessName === 'UPDATE') {
      if (status) {
        return [
          {
            icon: 'pi pi-user-edit',
            className: 'ui-button-warning',
            onClick: this.openEditSection,
          },
        ];
      } else {
        return [];
      }
    } else {
      return status;
    }
  };

  handleSelectedRows = selected => {
    this.selectedRow = selected;
    this.setState({ ...this.state, selectedRow: selected });
    this.initConfig();
  };

  approveBenefit = () => {
    this.state.selectedRow.forEach(row => {
      if (row.benefitStructureStatus !== 'APPROVED') {
        benefitStructureService.approveBenefit(row.id).subscribe(resp => { });
      } else {
        alert(`${row.description} already approved`);
      }
    });
  };

  handleOpen = () => {
    this.props.history.push('/masters/benefit-hierarchy?mode=create');
  };

  openEditSection = benefit => {
    this.props.history.push(`/masters/benefit-hierarchy/${benefit.id}?mode=edit`);
  };

  render() {
    const { classes } = this.props;
    return (
      
        <Eo2v2DataGrid
          $dataSource={dataSource$}
          config={this.configuration}
          columnsDefination={columnsDefinations}
          onEdit={this.openEditSection}
        />
    );
  }
}

export default withRouter(withStyles(useStyles)(BenefitHierarchyListComponent));
