import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import DenominationsAddComponent from "./denomination.create.component";
import DenominationListComponent from "./denomination.list.component";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function DenominationComponent() {
  const history = useHistory();
  const query = useQuery();
  
  return (
    <div>
       {query.get("mode") === "create" ? (
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "20px",
          height: "2em",
          color: "#000",
          fontSize: "18px",
          fontWeight: 600,
        }}
      >
       
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Denomination Management- Add Denomination
          </span>
        
      </Grid>
      ) : null}

      {(() => {
        switch (query.get("mode")) {
          case "viewList":
            return <DenominationListComponent />;
          case "create":
            return <DenominationsAddComponent />;
          default:
            history.push("/masters/denominations?mode=viewList");
        }
      })()}
    </div>
  );
}
