import { AxiosResponse } from "axios";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { http } from "../../http.client";
import { Page } from "../../models/page";

export class UsersService {
    readonly CONTEXT = `/user-management-service/v1/users`;

    saveUsers(payload: any): Observable<AxiosResponse<Map<string, any>>> {
        return http
            .post<Map<string, any>>(`${this.CONTEXT}`, payload)
            .pipe(map((response) => response));
    }

    getUsers(): Observable<Page<any>> {
        return http
            .get<Page<any>>(`${this.CONTEXT}?page=0&size=10&summary=true&active=true`)
            .pipe(map((response) => response.data));
    }
    updateUsers(roleName: string, payload: any): Observable<AxiosResponse<Map<string, any>>> {
        return http
            .put<Map<string, any>>(`${this.CONTEXT}/${roleName}`, payload)
            .pipe(map((response) => response));
    }

    getUserDetails(userName: string): Observable<Page<any>> {
        return http
            .get<Page<any>>(`${this.CONTEXT}/${userName}`)
            .pipe(map((response) => response.data));
    }
}
