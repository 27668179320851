import Paper from '@material-ui/core/Paper';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#fff',
  borderRadius: 4,
  boxShadow: isDragging
    ? '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'
    : '',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  width: '100%',
  /* minHeight: '300px' */
});

export class EO2V2DragNDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items || [],
    }; /* id and content is mandatory */

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items != this.props.items) {
      this.setState({ ...this.state, items: this.props.items });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.state.items, result.source.index, result.destination.index);

    this.setState({
      items,
    });

    Object.prototype.toString.call(this.props.onChange) == '[object Function]' && this.props.onChange(items);
  }

  renderChild = (chidElm, idx) => {
    const elmId = chidElm.props
      ? chidElm.props.id
        ? chidElm.props.id
        : chidElm.key
      : chidElm.id
      ? chidElm.id
      : chidElm.key;
    return (
      <Draggable key={elmId} draggableId={elmId} index={idx}>
        {(provided, snapshot) => (
          <Paper elevation="none">
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
              {chidElm.props ? chidElm : chidElm.content}
            </div>
          </Paper>
        )}
      </Draggable>
    );
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    let childrenWithProps = '';
    if (this.props.children) {
      childrenWithProps = React.Children.map(this.props.children, child => {
        let elements = React.Children.toArray(child.props.children);
        return elements.map((chidElm, idx) => this.renderChild(chidElm, idx));
      });
    } else {
      childrenWithProps = this.state.items.map((chidElm, idx) => this.renderChild(chidElm, idx));
    }

    return (
      <div className="eo2v2-drag-drop-container">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {childrenWithProps}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
