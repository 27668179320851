import { Box, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgba(90, 99, 171, 0.5)',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  actionBlock: {
    display: "flex",
    justifyContent: "space-evenly",
  },
});

export default function BenifitDesignRuleTable({onRuleEdit, ruleList, onRequestForChildRule, onRuleDelete ,hasChild=true}, ) {
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();
  const [selectedRow, setSelectedRow] = React.useState();
  const isSelected = (idx) => selectedRow === idx;

  const handleChange = (row, idx) => (e) => {
    setSelectedRow(idx);
  };

  const deleteTableRuleEvent = (row, idx) => (e) => {
    if(onRuleDelete){
      onRuleDelete(row, idx);
    } 
  }

  let response = null;

  if (ruleList && ruleList.length > 0) {
    response = (<TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="center">Benefit Name</StyledTableCell>
            <StyledTableCell align="center">Coverage</StyledTableCell>
            <StyledTableCell align="center">Cover Type</StyledTableCell>
            <StyledTableCell align="center">Rule Definition</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {ruleList.map((row, idx) => {
            const isItemSelected = isSelected(idx);

            return (
              <StyledTableRow key={row?.ruleName}>
                <StyledTableCell align="center" component="th" scope="row">
                  {row?.name}
                </StyledTableCell>
                <StyledTableCell align="center">{row?.coverageAmount}</StyledTableCell>
                <StyledTableCell align="center">{row?.coverType}</StyledTableCell>
                <StyledTableCell align="center">{row?.expression}</StyledTableCell>
                <StyledTableCell align="center" className={classes.actionBlock}>

                  <IconButton  onClick={() => onRuleEdit(row, idx)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton  onClick={() => { onRequestForChildRule(row)}} disabled={!hasChild}>
                    <ExpandMoreIcon />
                  </IconButton>
                  <IconButton onClick={deleteTableRuleEvent(row, idx)}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>)
  }
  else {
    response = <Box alignItems="center"
      justifyContent="center" display="flex" width="100%" height="100%">
      <span>No records are available</span>
    </Box>
  }
  return response;
}

