import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Questionnaire from './questionnaire/questionnaire.main.component';
import QuestionnaireComponent from './questionnaire/questionnaire.component';

const roleService = new RoleService();

export default function QuestionnaireRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props);
  // if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Questionnaire />{' '}
          </Route>
          <Route exact path="/questionnaire" component={Questionnaire} />
          <Route exact path="/questionnaire/:id" component={QuestionnaireComponent} />
        </Switch>
      </React.Fragment>
    );
  // }

  // return (
  //   <Redirect
  //     to={{
  //       pathname: '/unauthorized',
  //       state: {
  //         from: props.location,
  //       },
  //     }}
  //   />
  // );
}
