import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UnderwritingsReportComponent from './underwritings.reports.component';
import Reports from './reports';

export default function UnderwritingsReportsRouterComponent(props) {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <UnderwritingsReportComponent />{' '}
        </Route>
        {/* <Route exact path={`${path}/member`}>
          {' '}
          <MembersComponent />{' '}
        </Route> */}
        <Route exact path={`${path}/:id`}>
          {' '}
          <Reports />{' '}
        </Route>
      </Switch>
    </React.Fragment>
  );
}
