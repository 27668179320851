import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: 400,
  },
  actionBlock: {
    /*  display: "flex",
     justifyContent: "space-evenly", */
  },
});

export default function ServiceDesignTable(props) {
  const classes = useStyles();
  const designList = props.designList;
  const preventDefault = event => event.preventDefault();

  const editTableRule = (row, idx) => e => {
    props.editTableRule(row, idx);
  };

  const deleteTableRule = (row, idx) => e => {
    props.deleteTableRule(row, idx);
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader className={classes.table} aria-label="service design table">
        <TableHead>
          <TableRow>
            <TableCell>Service Type</TableCell>
            <TableCell align="center">Service Name</TableCell>
            <TableCell align="right">Max Limit</TableCell>
            <TableCell align="right">Waiting Period</TableCell>
            <TableCell align="right">Co-Share/Co-Pay</TableCell>
            <TableCell align="right">Mark As Exclude</TableCell>
            {props.action && <TableCell align="center">Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {designList &&
            designList?.map((row, idx) => {
              return (
                <TableRow key={row?.serviceType}>
                  <TableCell component="th" scope="row">
                    {row?.serviceTypeName}
                  </TableCell>
                  <TableCell align="center">{row?.serviceName}</TableCell>
                  <TableCell align="right">{row?.maxLimitValue}</TableCell>
                  <TableCell align="right">{row?.waitingPeriod}</TableCell>
                  <TableCell align="right">{row?.coShareOrPayPercentage}</TableCell>
                  <TableCell align="right">{(row?.toBeExcluded && 'Yes') || 'No'}</TableCell>
                  {props.action && (
                    <TableCell align="center" className={classes.actionBlock}>
                      {/* <Link href="javascript:void(0)" onClick={preventDefault}>
                      <VisibilityIcon />
                    </Link> */}
                      <Link href="javascript:void(0)" onClick={editTableRule(row, idx)}>
                        <EditIcon />
                      </Link>
                      <Link href="javascript:void(0)" onClick={deleteTableRule(row, idx)}>
                        <DeleteIcon style={{ color: 'red' }} />
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
