import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Typography, IconButton } from '@material-ui/core';
import BiometricComponent from './biometric';
import ClearIcon from '@material-ui/icons/Clear';
import { ContactSupport } from '@material-ui/icons';
// import OtpComponent from "./otp-component";
// import { MemberService } from "../remote-api/api/member-services";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '80%',
  width: { xs: '95%', sm: '75%', md: '50%' },
  backgroundColor: '#ffffffff',
  borderRadius: '10px',
  boxShadow: 50,
  padding: 4,
};

// const memberservice = new MemberService();
const BioModal = ({ open, setOpen, memberId }) => {
  const handleClose = () => setOpen(false);

  const matchResult = value => {
    console.log(value);
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open Modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Box style={modalStyle}>
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}>
            {/* <ClearIcon /> */}
          </IconButton>

          <BiometricComponent
            matchResult={matchResult}
            memberId={memberId}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default BioModal;
