import React from 'react';
// import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import CaseManagement from './case-management/case.management.list.component';
// import CaseRoutersComponent from './case-management/root.component';
// import CaseManagementComponent from './case-management/components/case-management.component';
import InHospital from './case-management/components/inhospital.list.component';
import WaitingForDischarge from './case-management/components/waitingfordischarge.list.component';
import WaitingForHospitalization from './case-management/components/waitingforhospitalization.list.component';
import ClaimsAuditDetailsComponent from './claim-audit/audit.component';
import Intimation from './claim-intimation/claim.intimation.component';
import ClaimsIntimationDetailsComponent from './claim-intimation/intimation.component';
import ClaimsPreAuthDetails from './claim-preauth/preauth.details.component';
import PreAuthReview from './claim-preauth/preauth.review.component';
import ClaimsPreAuth from './claim-preauth/preauth.root.component';
import ClaimReimReview from './claim-reimbursement/claim.reim.review.component';
import ClaimsReimbursement from './claim-reimbursement/cliam.reim.component';
import ClaimsReimDetails from './claim-reimbursement/cliam.reim.details.component';
import ClaimId from './claim-to-be-processed/claim.invoice.list.componnet';
import ClaimsToBeProcessed from './claim-to-be-processed/cliam.to.be.processed.component';
import MemberDetailsComponent from './member-details/member.details.component';
import ViewPolicyHistory from './member-details/member.details.view.policy.history';
import ReadyForPaymentRoutersComponent from './ready-for-payment/root.component';
import LetterDetailsComponent from './letter-management/components/letter.details.component';
import Letter from './letter-management/components/letter.component';
import Claims from './claims/cliam.component';
import ClaimReview from './claims/claim.review.component';
import ClaimsDetails from './claims/cliam.details.component';
import ClaimDashboard from './../../routes/Pages/ClaimDashboard';
import RejectedClaimsPreAuth from './claim-preauth-rejected/preauth.root.component';
import Credit from './claims/credit.component';

const roleService = new RoleService();

export default function ClaimsRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService?.checkRouteAccess(props);
  //   if (hasAccess) {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <ClaimDashboard />{' '}
        </Route>
        <Route exact path={`${path}/claims-preauth`}>
          {' '}
          <ClaimsPreAuth />
        </Route>
        <Route exact path={`${path}/rejected-claims`}>
          {' '}
          <RejectedClaimsPreAuth />
        </Route>
        <Route exact path={`${path}/claims-preauth/:id`}>
          <ClaimsPreAuthDetails />
        </Route>
        <Route exact path={`${path}/claims-preauth/review/:id`}>
          <PreAuthReview />
        </Route>
        <Route exact path={`${path}/claims-reimbursement/review/:id`}>
          <ClaimReimReview />
        </Route>
        <Route exact path={`${path}/claims-intimation`}>
          <Intimation />
        </Route>
        <Route exact path={`${path}/claims-intimation/:id`}>
          <ClaimsIntimationDetailsComponent />
        </Route>
        <Route exact path={`${path}/claims-reimbursement/review/:id`}>
          <ClaimReimReview />
        </Route>
        <Route exact path={`${path}/claims-reimbursement/:id`}>
          <ClaimsReimDetails />
        </Route>
        <Route exact path={`${path}/case-management`}>
          <CaseManagement />
        </Route>
        <Route path={`${path}/case-management/waitingforhospitalization/:id`}>
          <WaitingForHospitalization />
        </Route>
        <Route path={`${path}/case-management/inhospital/:id`}>
          <InHospital />
        </Route>
        <Route path={`${path}/case-management/waitingfordischarge/:id`}>
          <WaitingForDischarge />
        </Route>
        <Route path={`${path}/claims-reimbursement`}>
          <ClaimsReimbursement />
        </Route>
        <Route path={`${path}/claims`}>
          <Claims />
        </Route>
        <Route path={`${path}/credit`}>
          <Credit />
        </Route>
        <Route exact path={`${path}/claims-details/:id`}>
          <ClaimsDetails />
        </Route>
        <Route path={`${path}/claims-audit`}>
          <ClaimsAuditDetailsComponent />
        </Route>
        <Route path={`${path}/claims-to-be-processed/:id`}>
          <ClaimId />
        </Route>
        <Route path={`${path}/claims-to-be-processed`}>
          <ClaimsToBeProcessed />
        </Route>
        <Route path={`${path}/letter/:id`}>
          <LetterDetailsComponent />
        </Route>
        <Route path={`${path}/letter`}>
          <Letter />
        </Route>
        {/* <Route path={`${path}/member-details/view-policy-history/:id`}>
          <ViewPolicyHistory />
        </Route> */}
        <Route path={`${path}/ready-for-payment`}>
          <ReadyForPaymentRoutersComponent />
        </Route>
        <Route exact path={`${path}/:id`}>
          <ClaimsReimDetails />
        </Route>
        {/* <Route path={`${path}/member-enquiry`}>
          <MemberDetailsComponent />
        </Route> */}
      </Switch>
    </React.Fragment>
  );
  //   }
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/unauthorized',
  //         state: {
  //           from: props.location,
  //         },
  //       }}
  //     />
  //   );
}
