import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import React, { useEffect, useRef, useState } from 'react';
import { ProviderTypeService } from '../../remote-api/api/master-services';
import { Button } from 'primereact/button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { Toast } from 'primereact/toast';
import { Delete, Edit } from '@material-ui/icons';

const providertypeservice = new ProviderTypeService();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function FundAddressConfig() {
  const [active, setActive] = React.useState(1);
  const [showInput, setShowInput] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [fundData,setFundData] = useState([]);
  const [idEdit,setEditId] = useState();
  const[handleEditName,setHandleEditName] = useState("");
  const [showError, setShowError] = useState(false);

  const toast = useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setEditId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // make an active button on proposer section
  const makeActive = select => {
    if (select === 1) {
      setActive(1);
      setShowInput(false);
    }
  };
  const handleAddFieldClick = () => {
    setShowInput(true);
  };

  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  const fetchFundData = () => {
    try {
      providertypeservice.getProviderList(0).subscribe(res => {
        setFundData(res?.content)
      });

      // setShowInput(false);
    } catch (error) {
      console.error('Error saving the value:', error);
    }
  };

  const handleDeleteFund = (id) => {
    try {
      providertypeservice.deleteFundList(id).subscribe(res => {
        // setFundData(res?.content)
        fetchFundData();
      });

      // setShowInput(false);
    } catch (error) {
      console.error('Error saving the value:', error);
    }
  }

  const handleEditApi = (id) => {
  
    try {
      handleEditName.length>0 && providertypeservice.editFundList(id,handleEditName).subscribe(res => {
        // setFundData(res?.content)
        fetchFundData();
        handleClose();
        setHandleEditName("")
      });

      // setShowInput(false);
    } catch (error) {
      console.error('Error saving the value:', error);
    }
  }

  const handleSaveClickCategory = async () => {
    if (selectedOption) {
      const payload = {
        name: selectedOption,
      };
      try {
        providertypeservice.addFundCategoryType(payload).subscribe(res => {
          if (res?.id) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'SucessFully Added Fund', life: 2000 });
            setShowInput(false);
          }
        });
        fetchFundData();
        setShowInput(false);
      } catch (error) {
        console.error('Error saving the value:', error);
      }
    } else {
      setShowError(true)
    }
  };

  useEffect(() => {
    fetchFundData();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{display:"flex",alignItems:"center"}}><Edit/><h1 style={{fontSize:"1.5rem"}}>Edit Name</h1></Box>
            <Box sx={{display:"flex",alignItems:"center",gap:20,marginTop:"1.5rem"}}>
            <TextField onChange={(e)=>setHandleEditName(e.target.value)} id="outlined-basic" value={handleEditName} label="Edit Name" variant="outlined" style={{width:"400px"}}/>
            <Button variant="contained" style={{padding:"15px",fontSize:"1rem"}} onClick={()=>handleEditApi(idEdit)}>Save</Button>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button> */}
        </DialogActions>
      </Dialog>
      <Paper elevation="none">
        <Box p={3} my={2}>
          <>
          {active === 1 && (
              <>
                <Box sx={{display:"flex",alignItems:"end",flexDirection:"column",paddingRight:"20px"}}>
                  {!showInput && (
                    <Button variant="contained" color="primary" onClick={handleAddFieldClick}>
                      Add New
                    </Button>
                   
                    
                  )}

                  {showInput && (
                    <>
                      <FormControl variant="outlined" margin="normal" style={{ width: '200px', marginTop: '-12px' }}>
                        <TextField
                          label="Fund Type"
                          value={selectedOption}
                          onChange={handleSelectChange}
                          variant="outlined"
                          margin="normal"
                          error={showError}
                          helperText={showError ? 'Name Should have Atleast 3 words' : ''}
                        />
                      </FormControl>

                      <Button
                        style={{ display: 'flex' }}
                        variant="contained"
                        color="secondary"
                        onClick={handleSaveClickCategory}>
                        Save
                      </Button>
                    </>
                  )}
                </Box>
              </>
            )}
            <Grid container item xs={12}>
              {/* <Button
                style={active === 1 ? { color: 'white', backgroundColor: '#41e276', margin: '2px' } : { margin: '2px' }}
                onClick={() => makeActive(1)}>
                FUND TYPE
              </Button> */}
            </Grid>
            <Box sx={{marginTop:"10px"}}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Code</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fundData?.map((item)=>{

                    return(<TableRow>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell align='center'>{item?.code}</TableCell>
                      <TableCell align='center'><Edit onClick={()=>handleClickOpen(item?.id)} style={{marginRight:"25px",cursor:"pointer"}}/><Delete onClick={()=>handleDeleteFund(item?.id)} style={{cursor:"pointer"}}/></TableCell>
                      </TableRow> )

                  })}
                </TableBody>
              </Table>
            </TableContainer>
            </Box>
            <span></span>&nbsp;
           
          </>
        </Box>
      </Paper>
    </>
  );
}
