import { Box, FormControl, Grid, InputLabel, Paper, Select, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import { Button } from 'primereact/button';

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: 182,
  },
  formControl1: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
    benifitAutoComplete: {
      width: 500,
      '& .MuiInputBase-formControl': {
        maxHeight: 200,
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    },
  },
  disabled: {},
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveBtn: {
    marginRight: '5px',
  },
  buttonPrimary: {
    backgroundColor: '#313c96',
    color: '#f1f1f1',
  },
  buttonSecondary: {
    backgroundColor: '#01de74',
    color: '#f1f1f1',
  },
}));

const Reports = () => {
  const classes = useStyles();
  return (
    <>
      <Paper elevation="none" style={{padding:"2%"}}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={6} md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                views={['year', 'month', 'date']}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Start Date"
                KeyboardButtonProps={{
                  'aria-label': 'change ing date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                views={['year', 'month', 'date']}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="End Date"
                KeyboardButtonProps={{
                  'aria-label': 'change ing date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                Category
              </InputLabel>
              <Select
                label="Service Type"
                name="serviceType"
                //   value={b.serviceType}
                fullWidth
                //   onChange={e => {
                //     getExpenseHead(e.target.value);
                //     // changeInvoiceItems(e, selectedInvoiceItemIndex, idx);
                //   }}
              >
                {/* {serviceTypeList?.map(ele => {
                      return <MenuItem value={ele?.id}>{ele?.displayName}</MenuItem>;
                    })} */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              className={`responsiveButton ${classes.buttonPrimary}`}
              variant="contained"
              //   onClick={() => populateMemberFromSearch('number')}
              color="#313c96"
              type="button"
              style={{ borderRadius: '10px' }}>
              Download
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Reports;
