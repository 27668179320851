import React, { useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { EO2V2RulePreviewGraph } from "../../../shared-components";
import { Button } from "primereact/button";


const RuleDesignPreviewModal = ({ openDialog, handleClose, benefitStructures=[] }) => {

    const [previewBenefitStructure,setPreviewBenefitStructure]=[];

    useEffect(()=>{
       
    },[])

    return (<Dialog
        fullWidth
        maxWidth='lg'
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {/* {JSON.stringify(props.benefitStructures[value])} */}
                <EO2V2RulePreviewGraph benefitStructures={previewBenefitStructure} />
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus className='p-button-text'>
                Exit
            </Button>
        </DialogActions>
    </Dialog>)
}


export default RuleDesignPreviewModal;