import React from 'react';
import { useHistory } from 'react-router-dom';
import { map, switchMap } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { AgentsService } from '../../remote-api/api/agents-services';
import { AgentTypeService } from '../../remote-api/api/master-services';
import { Eo2v2DataGrid } from '../../shared-components';
import CommissionListComponent from '../commission/commission.list.component';
import { TabView, TabPanel } from 'primereact/tabview';

const agenttypeservice = new AgentTypeService();

const roleService = new RoleService();

const PAGE_NAME = 'AGENT';

const agentsService = new AgentsService();

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['code'] = pageRequest.searchKey.trim();
    pageRequest['type'] = pageRequest.searchKey.trim();
    pageRequest['name'] = pageRequest.searchKey.trim();
    pageRequest['contactNo'] = pageRequest.searchKey.trim();
  }
  delete pageRequest.searchKey;
  return agentsService
    .getAgents(pageRequest)
    .pipe(
      map(data => {
        let content = data.content;
        let records = content.map(item => {
          item['agentBasicDetails']['primaryContact'] = item.agentBasicDetails.contactNos[0].contactNo;
          return item;
        });
        data.content = records;
        return data;
      }),
    )
    .pipe(
      switchMap(data => {
        return agenttypeservice.getAgentTypes().pipe(
          map(at => {
            data.content.forEach(ag => {
              at.content.forEach(agenttype => {
                if (ag.agentBasicDetails.type === agenttype.code) {
                  ag['agentType'] = agenttype.name;
                }
              });
            });
            return data;
          }),
        );
      }),
    );
};

export default function AgentsListComponent(props) {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [rows, setRows] = React.useState(props.rows);

  if (localStorage.getItem('agentId')) {
    localStorage.removeItem('agentId');
  }

  const handleOpen = () => {
    history.push('/agents/management?mode=create');
  };

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const openEditSection = agent => {
    history.push(`/agents/management/${agent.id}?mode=edit`);
  };

  const columnsDefinationsActive = [
    { field: 'agentBasicDetails.name', headerName: 'Agent Name' },
    { field: 'agentBasicDetails.code', headerName: 'Agent Code' },
    { field: 'agentType', headerName: 'Agent Type' },
    { field: 'agentBasicDetails.primaryContact', headerName: 'Contact Number' },
    { field: 'agentBasicDetails.status', headerName: 'Status', body: rowData => <span>{rowData.agentBasicDetails.status ? "Active" : "Inactive"}</span> },
    { field: 'agentBasicDetails.joiningDate', headerName: 'Joining Date', body: rowData => <span>{new Date(rowData.agentBasicDetails.joiningDate).toLocaleDateString()}</span> },
  ];

  const columnsDefinationsTerminated = [
    { field: 'agentBasicDetails.name', headerName: 'Agent Name' },
    { field: 'agentBasicDetails.code', headerName: 'Agent Code' },
    { field: 'agentType', headerName: 'Agent Type' },
    { field: 'agentBasicDetails.primaryContact', headerName: 'Contact Number' },
    { field: 'agentBasicDetails.status', headerName: 'Status', body: rowData => <span>{rowData.agentBasicDetails.status ? "Active" : "Inactive"}</span> },
    { field: 'agentBasicDetails.joiningDate', headerName: 'Joining Date', body: rowData => <span>{new Date(rowData.agentBasicDetails.joiningDate).toLocaleDateString()}</span> },
    { field: 'agentBasicDetails.terminationDate', headerName: 'Termination Date', body: rowData => <span>{new Date(rowData.agentBasicDetails.terminationDate).toLocaleDateString()}</span> },
  ];

  const configuration = {
    enableSelection: false,
    scrollHeight: '300px',
    pageSize: 10,
    actionButtons: roleService.checkActionPermission(PAGE_NAME, 'UPDATE', openEditSection),
    header: {
      enable: true,
      enableDownload: true,
      addCreateButton: value == 0 && roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      onCreateButtonClick: handleOpen,
      text: 'Agent Management',
      enableGlobalSearch: true,
      searchText: 'Search by code, name, type, contact',
    },
  };

  return (
    <TabView scrollable style={{ fontSize: '14px' }} activeIndex={activeIndex} onTabChange={e => setActiveIndex(e.index)}>
      <TabPanel leftIcon="pi pi-user mr-2" header="Active Agent">
        <Eo2v2DataGrid
          $dataSource={dataSource$}
          config={configuration}
          columnsDefination={columnsDefinationsActive}
          onEdit={openEditSection}
        />
      </TabPanel>
      <TabPanel leftIcon="pi pi-user-minus mr-2" header="Terminated Agent">
        <Eo2v2DataGrid
          $dataSource={dataSource$}
          config={configuration}
          columnsDefination={columnsDefinationsTerminated}
          onEdit={openEditSection}
        />
        {/* </div> */}
      </TabPanel>
      <TabPanel leftIcon="pi pi-percentage mr-2" header="Agent Commission">
        <CommissionListComponent />
      </TabPanel>
    </TabView>
  );
}
