import { withStyles } from "@material-ui/styles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProductPremiumListComponent from "./product.premium.list.component";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = (theme) => ({

});

function PremiumDesignComponent(props) {
    const history = useHistory();
    const query = useQuery();

    

    return (
        <div>
            {(() => {
                switch (query.get("mode")) {
                    case 'viewList':
                        return (
                            <ProductPremiumListComponent  />
                        );
                    default:
                        history.push("/premium?mode=viewList");
                }
            })()}
        </div>
    );
}
export default withStyles(useStyles)(PremiumDesignComponent);