import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import SLAConfiguration from './sla/sla.configuration.main.component';
import SLAConfigurationComponent from './sla/sla.configuration.component';

const roleService = new RoleService();

export default function SLAConfigurationRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props);
  // if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <SLAConfiguration />{' '}
          </Route>
          <Route exact path="/sla/configuration" component={SLAConfiguration} />
          <Route exact path="/sla/configuration/:id" component={SLAConfigurationComponent} />
        </Switch>
      </React.Fragment>
    );
  // }

  // return (
  //   <Redirect
  //     to={{
  //       pathname: '/unauthorized',
  //       state: {
  //         from: props.location,
  //       },
  //     }}
  //   />
  // );
}
