import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { PreAuthService } from '../../remote-api/api/claims-services';
import { MemberService } from '../../remote-api/api/member-services';
import { BenefitService, ProvidersService, ServiceTypeService } from '../../remote-api/eo2v2-remote-api';
import { Eo2v2DataGrid } from '../../shared-components';
import PreAuthTimeLineModal from './modals/preauth.timeline.modal.component';
import preAuthReviewModel, { PRE_AUTH_STATUS_MSG_MAP } from './preauth.shared';
import { Box, Divider, Grid, Modal, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CloseOutlined } from '@material-ui/icons';
import { Button } from 'primereact/button';
import { PoliticalDot, VIPDot } from '../common/dot.comnponent';
import { TreeItem, TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

localStorage.removeItem('preauthid');
const roleService = new RoleService();

const PAGE_NAME = 'PRE_AUTH';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  background: '#fff',
  // border: '2px solid #000',
  boxShadow: 24,
  padding: '2% 3%',
};

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 505,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  agentListButton: {
    marginLeft: '5px',
  },
  categoryButton: {
    marginLeft: '5px',
    marginBottom: '5px',
  },
}));

const memberservice = new MemberService();
const preAuthService = new PreAuthService();
const benefitService = new BenefitService();
const providerService = new ProvidersService();
const serviceDiagnosis = new ServiceTypeService();

const getColor = status => {
  switch (status) {
    case 'Pending Evaluation':
      return { background: 'rgba(149,48,55,0.5)', border: 'rgba(149,48,55,1)' };
    case 'Evaluation in progress':
      return {
        background: 'rgba(255, 252, 127, 0.5)',
      };
    case 'Requested for evaluation':
      return {
        background: '#002776',
        border: 'rgba(4, 59, 92, 1)',
        color: '#f1f1f1',
      };
    case 'Approved':
      return {
        background: 'rgba(1, 222, 116, 0.5)',
        border: 'rgba(1, 222, 116, 1)',
      };
    case 'Rejected':
      return { background: 'rgba(255,50,67,0.5)', border: 'rgba(255,50,67,1)' };
    case 'Document Requested':
      return {
        background: '#ffc107',
        color: '#212529',
      };
    case 'Approved failed':
      return { background: 'rgb(139, 0, 0,0.5)', border: 'rgb(139, 0, 0)' };
    case 'Draft':
      return {
        background: '#17a2b8',
        color: '#f1f1f1',
      };
    case 'Waiting for Claim':
      return {
        background: '#ffc107',
        color: '#212529',
      };
    case 'Cancelled':
      return { background: '#c70000', color: '#f1f1f1' };
    case 'Reverted':
      return {
        background: '#808000',
        color: '#f1f1f1',
      };
    case 'Claim Initiated':
      return {
        background: 'rgba(38,194, 129, 0.5)',
        border: 'rgba(38, 194, 129, 1)',
      };
    case 'Document Submited':
      return {
        background: '#313c96',
        color: '#f1f1f1',
      };
    default:
      return {
        background: 'rgba(227, 61, 148, 0.5)',
        border: 'rgba(227, 61, 148, 1)',
      };
  }
};
export default function PreAuthOPDListComponent(props) {
  const history = useHistory();
  const [rows, setRows] = React.useState(props.rows);
  const [openReviewModal, setOpenReviewModal] = React.useState(false);
  const [cancelModal, setCancelModal] = React.useState(false);
  const [cancelReason, setCancelReason] = React.useState();
  const [openTimeLineModal, setOpenTimeLineModal] = React.useState(false);
  const [cancelPreAuthId, setCancelPreAuthId] = React.useState();
  const [selectedPreAuthForReview, setSelectedPreAuthForReview] = React.useState(preAuthReviewModel());
  const [selectedPreAuth, setSelectedPreAuth] = React.useState({});
  const [searchType, setSearchType] = React.useState();
  const [searchModal, setSearchModal] = React.useState(false);
  const [fromExpectedDOA, setFromExpectedDOA] = React.useState(null);
  const [toExpectedDOA, setToExpectedDOA] = React.useState(null);
  const [fromExpectedDOD, setFromExpectedDOD] = React.useState(null);
  const [toExpectedDOD, setToExpectedDOD] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [reloadTable, setReloadTable] = React.useState(false);
  const [state, setState] = React.useState();
  const [benefits, setBenefits] = useState();
  const [providers, setProviders] = useState();
  const classes = useStyles();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const utclongDate = date => {
    if (!date) return undefined;
    return date.getTime();
  };

  useEffect(() => {
    let subscription = benefitService
      .getAllBenefit({ page: 0, size: 1000, summary: true, active: true, sort: ['rowCreatedDate dsc'] })
      .subscribe(result => {
        setBenefits(result.content);
      });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    let subscription = providerService
      .getProviders({ page: 0, size: 1000, summary: true, active: true, sort: ['rowCreatedDate dsc'] })
      .subscribe(result => {
        setProviders(result.content);
      });
    return () => subscription.unsubscribe();
  }, []);

  const handleProvider = rowData => {
    const length = rowData?.providers?.length;
    const invoiceProviders = rowData?.providers?.map(prov => {
      const provider = providers?.find(p => p.id === prov.providerId);

      if (provider) {
        if (prov.benefit.length) {
          return (
            <TreeItem
              itemID={prov.providerId}
              nodeId="999"
              label={
                <Typography
                  sx={{ fontSize: '12px' }}>{`${provider?.providerBasicDetails?.name}: ${prov.estimatedCost}`}</Typography>
              }>
              {renderBenefitWithCost({ benefitsWithCost: prov.benefit })}
            </TreeItem>
          );
        } else {
          return (
            <TreeItem
              itemID={prov.providerId}
              label={
                <Typography
                  sx={{ fontSize: '12px' }}>{`${provider?.providerBasicDetails?.name}: ${prov.estimatedCost}`}</Typography>
              }></TreeItem>
          );
        }
      } else {
        return (
          <TreeItem
            itemID={prov.providerId}
            label={<Typography sx={{ fontSize: '12px' }}>{`Unknown: ${prov.estimatedCost || null}`}</Typography>}></TreeItem>
        );
      }
    });

    const totalAmount = rowData.providers.reduce((acc, curr) => acc + curr.estimatedCost, 0);

    return (
      <TreeView className={classes.root} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        <TreeItem
          itemID="grid"
          nodeId="1"
          label={
            <Typography sx={{ fontSize: '12px' }}>{`${length} ${
              length === 1 ? 'Provider: ' : 'Providers: '
            } ${totalAmount}`}</Typography>
          }>
          {invoiceProviders}
        </TreeItem>
      </TreeView>
    );
  };

  const renderBenefitWithCost = rowData => {
    const benefitsWithCost = rowData?.benefitsWithCost?.map(ben => {
      const benefitName = benefits?.find(item => item.id === ben?.benefitId)?.name;
      return benefitName ? (
        <TreeItem
          itemID={ben?.benefitId}
          label={<Typography sx={{ fontSize: '12px' }}>{`${benefitName}: ${ben?.estimatedCost}`}</Typography>}></TreeItem>
      ) : (
        <TreeItem
          itemID={ben?.benefitId}
          label={<Typography sx={{ fontSize: '12px' }}>{`Unknown: ${ben?.estimatedCost || null}`}</Typography>}></TreeItem>
      );
    });

    const totalAmount = rowData.benefitsWithCost.reduce((acc, curr) => acc + curr.estimatedCost, 0);

    return (
      <TreeView className={classes.root} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        <TreeItem
          nodeId="1"
          itemID="grid"
          label={<Typography sx={{ fontSize: '12px' }}>{`Benifits: ${totalAmount}`}</Typography>}>
          {benefitsWithCost}
        </TreeItem>
      </TreeView>
    );
  };

  const columnsDefinations = [
    {
      field: 'id',
      headerName: 'Pre-Auth No.',
      body: rowData => (
        <span
          style={{ lineBreak: 'anywhere', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            history.push(`/claims/claims-preauth/review/${rowData.id}?mode=viewOnly&auth=OPD`);
            // history.push(`/claims/claims-preauth/${rowData?.id}?mode=viewOnly&auth=OPD`);
          }}>
          {rowData.id}
        </span>
      ),
    },
    { field: 'memberShipNo', headerName: 'Membership No.' },
    {
      field: 'memberName',
      headerName: 'Name',
      body: rowData => (
        <div>
          <span>{rowData?.memberName}</span>
          <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {rowData.vip && <VIPDot />}
          </span>
          {/* {isHovered && <span style={{ marginLeft: '10px' }}>Additional text displayed on hover</span>} */}
          <span>
            {rowData.political && (
              // <Tooltip title="Political">
              <PoliticalDot />
              // </Tooltip>
            )}
          </span>
          {/* </Tooltip> */}
        </div>
      ),
    },
    { field: 'policyNumber', headerName: 'Policy No.', expand: true },
    { field: 'admissionDate', headerName: 'Admission Date', expand: true },
    { field: 'dischargeDate', headerName: 'Discharge Date', expand: true },
    {
      field: 'benefitWithCost',
      headerName: 'Benefit & Cost',
      body: renderBenefitWithCost,
    },
    {
      field: 'provider',
      headerName: 'Providers & Cost',
      body: handleProvider,
    },
    // {
    //   field: 'estimatedCose',
    //   headerName: 'Estimated Cost',
    //   body: rowData => (
    //     <span>
    //       {rowData.providers?.map(el => {
    //         let pName = providers.find(i => i.id === el?.providerId).providerBasicDetails;
    //         return (
    //           <>
    //             <div>
    //               <strong>{pName?.name}</strong>&nbsp; :&nbsp;
    //               {el?.benefit?.map(item => {
    //                 let name = benefits.find(i => i.id === item?.benefitId).name;
    //                 return (
    //                   <div>
    //                     <span>{name}</span>&nbsp; :&nbsp;
    //                     <span>{item?.estimatedCost}</span>
    //                   </div>
    //                 );
    //               })}
    //             </div>
    //           </>
    //         );
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   field: 'vip',
    //   headerName: 'Is Vip ?',
    //   body: rowData => <span>{rowData.vip ? 'Yes' : 'No'}</span>,
    // },
    // {
    //   field: 'political',
    //   headerName: 'Is Political ?',
    //   body: rowData => <span>{rowData.political ? 'Yes' : 'No'}</span>,
    // },
    {
      field: 'status',
      headerName: 'Status',
      body: rowData =>
        rowData.status == 'Document Requested' ? (
          <Tooltip title={rowData?.addDocRemark}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <span
                style={{
                  backgroundColor: getColor(rowData.status).background,
                  // opacity: '0.9',
                  color: getColor(rowData.status).color ? getColor(rowData.status).color : '#3c3c3c',
                  fontSize: '12px',
                  fontWeight: '600',
                  borderRadius: '8px',
                  padding: '6px',
                }}>
                {rowData.status}
              </span>
            </div>
          </Tooltip>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <span
              style={{
                backgroundColor: getColor(rowData.status).background,
                // opacity: '0.9',
                color: getColor(rowData.status).color ? getColor(rowData.status).color : '#3c3c3c',
                fontSize: '12px',
                fontWeight: '600',
                borderRadius: '8px',
                padding: '6px',
              }}>
              {rowData.status}
            </span>
          </div>
        ),
    },
  ];

  const dataSource$ = (
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      preAuthType: 'OPD',
      // fromExpectedDOA: fromExpectedDOA,
      // toExpectedDOA: fromExpectedDOA,
      // fromExpectedDOD: fromExpectedDOD,
      // toExpectedDOD: toExpectedDOD,
      // fromDate: fromDate,
      // toDate: toDate,
    },
  ) => {
    pageRequest.sort = ['rowCreatedDate dsc'];
    if (pageRequest.searchKey) {
      pageRequest['memberShipNo'] = pageRequest.searchKey.toUpperCase().trim();
      pageRequest['preAuthStatus'] = pageRequest.searchKey.toUpperCase().trim();
      pageRequest['policyNumber'] = pageRequest.searchKey.toUpperCase().trim();
      pageRequest['id'] = pageRequest.searchKey.toUpperCase().trim();
      pageRequest['memberName'] = pageRequest.searchKey.toUpperCase().trim();
      delete pageRequest.searchKey;
    }

    const querytype = {
      1: {
        fromExpectedDOA: utclongDate(fromExpectedDOA),
        toExpectedDOA: toExpectedDOA ? utclongDate(toExpectedDOA) : utclongDate(fromExpectedDOA),
      },
      2: {
        fromExpectedDOD: utclongDate(fromExpectedDOD),
        toExpectedDOD: toExpectedDOD ? utclongDate(toExpectedDOD) : utclongDate(fromExpectedDOD),
      },
      3: {
        fromDate: utclongDate(fromDate),
        toDate: toDate ? utclongDate(toDate) : utclongDate(fromDate),
      },
    };

    const pagerequestquery = {
      page: pageRequest.page,
      size: pageRequest.size,
      summary: true,
      active: true,
      ...(searchType && querytype[searchType]),
    };

    return preAuthService.getAllPreAuths(searchType ? pagerequestquery : pageRequest).pipe(
      tap(data => {
        setState(data?.data);
      }),
      map(data => {
        let content = data?.data?.content;
        let records = content.map(item => {
          item['admissionDate'] = new Date(item.expectedDOA).toLocaleDateString();
          item['dischargeDate'] = new Date(item.expectedDOD).toLocaleDateString();
          item['status'] = PRE_AUTH_STATUS_MSG_MAP[item.preAuthStatus];
          return item;
        });
        data.content = records;
        return data?.data;
      }),
    );
  };

  const handleOpen = () => {
    history.push('/claims/claims-preauth?mode=create&auth=OPD');
  };

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const openEditSection = preAuth => {
    history.push(`/claims/claims-preauth/${preAuth.id}?mode=edit&auth=OPD`);
  };

  const openReviewSection = preAuth => {
    history.push(`/claims/claims-preauth/review/${preAuth.id}?auth=OPD`);
  };

  const cancelPreAuth = preAuth => {
    setCancelModal(true);
    setCancelPreAuthId(preAuth.id);
  };

  const onConfirmCancel = () => {
    let payload = {
      reasonForCancellation: cancelReason,
    };
    preAuthService.cancelPreAuth(payload, cancelPreAuthId).subscribe(res => {
      setTimeout(() => {
        window.location.reload();
      }, [300]);
    });
  };

  const onConfirmCancels = () => {
    setCancelModal(false);
  };
  const openForReview = preAuth => {
    let pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      key: 'MEMBERSHIP_NO',
      value: preAuth.memberShipNo,
      key1: 'policyNumber',
      value1: preAuth.policyNumber,
    };

    let bts$ = benefitService.getAllBenefit({ page: 0, size: 1000 });
    let ps$ = providerService.getProviders();
    let serviceAll$ = forkJoin(
      serviceDiagnosis.getServicesbyId('867854950947827712', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
      serviceDiagnosis.getServicesbyId('867855014529282048', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
      serviceDiagnosis.getServicesbyId('867855088575524864', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
      serviceDiagnosis.getServicesbyId('867855148155613184', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
    );

    memberservice.getMember(pageRequest).subscribe(res => {
      if (res.content?.length > 0) {
        const member = res.content[0];
        setSelectedPreAuthForReview({ member, preAuth });
        setOpenReviewModal(true);
      }
    });
  };

  const openTimeLine = preAuth => {
    setSelectedPreAuth(preAuth);
    setOpenTimeLineModal(true);
  };

  const openReimbursement = preAuth => {
    history.push(`/claims/claims?mode=create&type=preauth&preId=` + preAuth.id);
  };

  const openDocumentsSection = preAuth => {
    history.push(`/claims/claims-preauth/${preAuth.id}?mode=edit&auth=OPD&addDoc=true`);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
  };

  const handleCloseTimeLineModal = () => {
    setOpenTimeLineModal(false);
  };

  const disableEnhance = item => {
    return (
      item.preAuthStatus != 'DRAFT' &&
      item.preAuthStatus != 'REVERTED' &&
      item.preAuthStatus != 'APPROVED' &&
      item.preAuthStatus != 'REJECTED'
    );
  };

  const disableClaimReimburse = item => {
    return item.preAuthStatus != 'WAITING_FOR_CLAIM';
  };

  const disableAddDocs = item => {
    return item.preAuthStatus != 'ADD_DOC_REQUESTED';
  };

  const disableReviewButton = item => {
    return (
      item.preAuthStatus != 'IN REVIEW' &&
      item.preAuthStatus != 'REQUESTED' &&
      item.preAuthStatus != 'EVALUATION_INPROGRESS' &&
      item.preAuthStatus != 'PENDING_EVALUATION' &&
      item.preAuthStatus != 'ADD_DOC_SUBMITTED'
    );
  };

  const disableCancelButton = item => {
    return item.preAuthStatus == 'CANCELLED';
  };

  const onSearch = () => {
    setSearchModal(false);
    setReloadTable(true);
    setTimeout(() => {
      setReloadTable(false);
      // setSearchDischargeDate('');
      // setSearchAdmissionDate('');
      setFromExpectedDOA(null);
      setToExpectedDOA(null);
      setFromExpectedDOD(null);
      setToExpectedDOD(null);
      setFromDate(null);
      setToDate(null);
    }, [1000]);
  };

  const preAuthDOASearch = type => {
    setSearchModal(true);
    setSearchType(1);
  };

  const preAuthDODSearch = type => {
    setSearchModal(true);
    setSearchType(2);
  };
  const preAuthCreationSearch = type => {
    setSearchModal(true);
    setSearchType(3);
  };

  const clearAllClick = () => {
    setFromExpectedDOA('');
    setToExpectedDOA('');
    setFromExpectedDOD('');
    setToExpectedDOD('');
    setFromDate('');
    setToDate('');
    setSearchType();
    setReloadTable(true);
  };

  const xlsColumns = [
    'id',
    'memberShipNo',
    'memberName',
    'policyNumber',
    'admissionDate',
    'dischargeDate',
    'benefitWithCost',
    'provider',
    'status'
  ];
  
  const userType = localStorage.getItem('userType');

  const configuration = {
    enableSelection: false,
    rowExpand: true,
    scrollHeight: '285px',
    pageSize: 10,
    actionButtons: userType === "TPA" ? '' : [
      {
        key: 'update_preauth',
        icon: 'pi pi-pencil',
        disabled: disableEnhance,
        className: classes.categoryButton,
        onClick: openEditSection,
        tooltip: 'Enhance',
      },

      {
        key: 'review_preauth',
        icon: 'pi pi-book',
        disabled: disableReviewButton,
        className: classes.categoryButton,
        onClick: openReviewSection,
        tooltip: 'Review',
      },
      {
        key: 'review_cancel',
        icon: 'pi pi-times',
        className: classes.categoryButton,
        disabled: disableCancelButton,
        onClick: cancelPreAuth,
        tooltip: 'Cancel',
      },
      {
        key: 'timeleine_preauth',
        icon: 'pi pi-calendar-times',
        className: classes.categoryButton,
        onClick: openTimeLine,
        tooltip: 'Timeleine',
      },
      {
        key: 'claim_preauth',
        icon: 'pi pi-money-bill',
        className: classes.categoryButton,
        disabled: disableClaimReimburse,
        onClick: openReimbursement,
        tooltip: 'Claim',
      },
      {
        key: 'claim_preauth',
        icon: 'pi pi-paperclip',
        className: classes.categoryButton,
        disabled: disableAddDocs,
        onClick: openDocumentsSection,
        tooltip: 'Add Documents',
      },
    ],

    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: userType !== "TPA" &&  roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      onCreateButtonClick: handleOpen,
      text: 'Pre-Auth - OPD',
      enableGlobalSearch: true,
      searchText: 'Search by Claim No, Membership No, Name, Policy id or Status',
      selectionMenus: [
        { icon: '', text: 'Admission Date', onClick: preAuthDOASearch },
        { icon: '', text: 'Discharge Date', onClick: preAuthDODSearch },
        { icon: '', text: 'Creation Date', onClick: preAuthCreationSearch },
        { icon: '', text: 'Clear All', onClick: clearAllClick },
      ],
      selectionMenuButtonText: 'Search',
      //   onSelectionChange: handleSelectedRows,
      //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
      //   selectionMenuButtonText: "Action"
    },
  };

  return (
    <div>
      {/* <DataGrid rows={rows} columns={props.columns} pageSize={10} /> */}
      <Eo2v2DataGrid
        $dataSource={dataSource$}
        config={configuration}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
        reloadTable={reloadTable}
      />

      {/* <PreAuthReviewModal preAuthReviewModel={selectedPreAuthForReview} open={openReviewModal} onClose={handleCloseReviewModal} setState={setSelectedPreAuthForReview}></PreAuthReviewModal> */}
      <PreAuthTimeLineModal preAuth={selectedPreAuth} open={openTimeLineModal} onClose={handleCloseTimeLineModal} />

      <Modal
        open={cancelModal}
        onClose={() => {
          setCancelModal(false);
        }}>
        <Box sx={style}>
          <div style={{ padding: '5px' }}>
            <strong>Cancel Reason</strong>
            <Grid container rowSpacing={5} style={{ marginTop: '10px' }}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="filled-multiline-static"
                  label="Add comment"
                  multiline
                  fullWidth
                  minRows={4}
                  variant="filled"
                  onChange={e => {
                    setCancelReason(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <Box display={'flex'} justifyContent={'end'} marginTop={'15px'}>
            <Button variant="contained" color="primary" onClick={onConfirmCancel}>
              Cancel PreAuth
            </Button>
            <Button variant="text" className="p-button-text" onClick={onConfirmCancels}>
              No
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={searchModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box style={modalStyle}>
          <Box>
            <Box id="alert-dialog-slide-description">
              {searchType == 1 && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box component="h3" marginBottom={'10px'}>
                      Search By Date of Admission
                    </Box>
                    <CloseOutlined onClick={() => setSearchModal(false)} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box display={'flex'} marginBottom={'10px'}>
                    <Box display={'flex'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        From
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={fromExpectedDOA}
                            onChange={date => setFromExpectedDOA(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                    <Box display={'flex'} marginLeft={'3%'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        To
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={toExpectedDOA}
                            onChange={date => setToExpectedDOA(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {searchType == 2 && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box component="h3" marginBottom={'10px'}>
                      Seach by Date of Discharge
                    </Box>
                    <CloseOutlined onClick={() => setSearchModal(false)} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box display={'flex'} marginBottom={'10px'}>
                    <Box display={'flex'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        From
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={fromExpectedDOD}
                            onChange={date => setFromExpectedDOD(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                    <Box display={'flex'} marginLeft={'3%'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        To
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={toExpectedDOD}
                            onChange={date => setToExpectedDOD(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {searchType == 3 && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box component="h3" marginBottom={'10px'}>
                      Search By Creation Date
                    </Box>
                    <CloseOutlined onClick={() => setSearchModal(false)} style={{ cursor: 'pointer' }} />
                  </Box>
                  <Box display={'flex'} marginBottom={'10px'}>
                    <Box display={'flex'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        From
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={fromDate}
                            onChange={date => setFromDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                    <Box display={'flex'} marginLeft={'3%'}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '700',
                          textTransform: 'capitalize',
                        }}>
                        To
                      </Typography>
                      &nbsp;
                      <span style={{ display: 'flex', alignItems: 'center' }}>:</span>&nbsp;
                      <Box style={{ marginBottom: '10px' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month', 'date']}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            autoOk={true}
                            id="date-picker-inline"
                            value={toDate}
                            onChange={date => setToDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change ing date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box marginTop={'10%'}>
            <Button
              variant="contained"
              style={{ backgroundColor: theme.palette.primary.main, color: '#fff' }}
              onClick={onSearch}>
              Search
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
