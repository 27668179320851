import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import "date-fns";
import * as React from "react";
import { Eo2v2DataGrid } from '../../../shared-components';
import { Observable, map } from 'rxjs';


const columnsDefinations = [
    { field: 'categoryName', headerName: 'Category' },
    { field: 'startDate', headerName: 'Start Date' },
    { field: 'endDate', headerName: 'End Date' },
];

export default function ProviderCategoryHistoryModal(props) {
    const { categoryList, closeCategoryListModal, openCategoryListModal } = props;

    const handleClose = () => {
        closeCategoryListModal();
    }

    const configuration = {
        enableSelection: true,
        scrollHeight: '300px',
        pageSize: 10,
        header: {
            enable: true,
            text: 'Category History',
        },
    };


    const data$ = new Observable(subscriber => {
        subscriber.next(categoryList);
    });

    const dataSource$ = () => {
        return data$.pipe(
            map(data => {
                let records = data.map(item => {
                    
                    item['startDate'] = new Date(item.startDate).toLocaleDateString();
                    item['endDate'] = item.endDate ? new Date(item.endDate).toLocaleDateString() : "-"
                    return item;
                });
                data.content = records;
                return data;
            })
        );
    };


    return (

        <Dialog open={openCategoryListModal} onClose={handleClose} aria-labelledby="form-dialog-title" disableEnforceFocus>
            <DialogContent>
                <Eo2v2DataGrid
                    $dataSource={dataSource$}
                    columnsDefination={columnsDefinations}
                    config={configuration}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}