import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ServiceGroupingFormComponent from './service.grouping.form';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ServiceGroupingAction(props) {
    const query = useQuery();
    const history = useHistory();

    return (
        <div>
            {(() => {
                switch (query.get("mode")) {
                    case 'edit':
                        return (
                            <ServiceGroupingFormComponent />
                        );
                    default:
                        history.push(`/masters/service-grouping/${props.match.params.id}?mode=edit`);
                }
            })()}
        </div>
    );
}