import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import Bank from './bank/bank.component';
import BankDetails from './bank/bank.details.component';
import Cards from './card/card.component';
import CardDetailsComponent from './card/card.details.component';

const roleService = new RoleService();

export default function BankRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props);
  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={path}>
            {' '}
            <Bank />{' '}
          </Route>
          <Route exact path={`${path}/banks`} component={Bank} />
          <Route exact path={`${path}/banks/:id`} component={BankDetails} />
          <Route exact path={`${path}/cards`} component={Cards} />
          <Route exact path={`${path}/cards/:id`} component={CardDetailsComponent} />
        </Switch>
      </React.Fragment>
    );
  }
  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
