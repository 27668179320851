import { Snackbar } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import Checkbox from '@material-ui/core/Checkbox';
// import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Alert } from '@material-ui/lab';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';
import { MemberService } from '../remote-api/api/member-services';
import AddIcon from '@material-ui/icons/Add';
import AgeModal from './modals/age.modal';
import { Eo2v2DataGrid } from '../shared-components';
import { GuidlineService } from '../remote-api/api/master-services/guidline.service';
import { map } from 'rxjs';

const guidelinesService = new GuidlineService();

const dataSource1$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (!pageRequest.searchKey) {
    return guidelinesService.getAgeGuidlineList(pageRequest).pipe(
      map(data => {
        return data;
      }),
    );
  }
};
const dataSource2$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (!pageRequest.searchKey) {
    return guidelinesService.getGenderGuidlineList(pageRequest).pipe(
      map(data => {
        return data;
      }),
    );
  }
};
const dataSource3$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (!pageRequest.searchKey) {
    return guidelinesService.getRelationshipGuidlineList(pageRequest).pipe(
      map(data => {
        return data;
      }),
    );
  }
};
const dataSource4$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (!pageRequest.searchKey) {
    return guidelinesService.getAnnualIncomeGuidlineList(pageRequest).pipe(
      map(data => {
        return data;
      }),
    );
  }
};
const dataSource5$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (!pageRequest.searchKey) {
    return guidelinesService.getBmiGuidlineList(pageRequest).pipe(
      map(data => {
        return data;
      }),
    );
  }
};

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formControl: {
    minWidth: 182,
  },
  formControl1: {
    minWidth: 300,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 700,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const columnsDefinations1 = [
  { field: 'underwritingMinAge', headerName: 'Min Age' },
  { field: 'underwritingMaxAge', headerName: 'Max Age' },
];

export default function GuidelinesComponent(props) {
  const classes = useStyles();
  const [ageModal, setAgeModal] = useState(false);
  const [type, setType] = useState();

  const handleClose = () => {
    setAgeModal(false);
    setType();
  };

  const configuration1 = {
    enableSelection: false,
    scrollHeight: '300px',
    pageSize: 10,
    // actionButtons: actionBtnList,
    header: {
      enable: true,
      // enableDownload: true,
      // downloadbleColumns: xlsColumns,
      // addCreateButton: 'CREATE',
      // addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      // onCreateButtonClick: handleOpen,
      text: 'Questionnaire Management',
      // enableGlobalSearch: true,
      // searchText: 'Search by Name,Policy Number',
      // selectionMenuButtonText: 'Advance Search',
    },
  };

  return (
    <div>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '20px',
          height: '2em',
          fontSize: '18px',
        }}>
        <span
          style={{
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '5px',
          }}>
          Guidelines
        </span>
      </Grid>
      <Paper
        elevation="none"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '1%',
        }}>
        <Box padding={'10px'} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gridGap={'10px'}>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={6}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Age Group
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(1);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
              <Eo2v2DataGrid
                $dataSource={dataSource1$}
                columnsDefination={columnsDefinations1}
                // onEdit={openEditSection}
                config={configuration1}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Gender
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(2);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
              <Eo2v2DataGrid
                $dataSource={dataSource2$}
                columnsDefination={columnsDefinations1}
                // onEdit={openEditSection}
                config={configuration1}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Relationship
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(3);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
              <Eo2v2DataGrid
                $dataSource={dataSource3$}
                columnsDefination={columnsDefinations1}
                // onEdit={openEditSection}
                config={configuration1}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Annual Income
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(4);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
              <Eo2v2DataGrid
                $dataSource={dataSource4$}
                columnsDefination={columnsDefinations1}
                // onEdit={openEditSection}
                config={configuration1}
              />
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  BMI
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(5);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
              <Eo2v2DataGrid
                $dataSource={dataSource5$}
                columnsDefination={columnsDefinations1}
                // onEdit={openEditSection}
                config={configuration1}
              />
            </Grid>
          </Grid>

          {/* <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Age Group
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(1);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
              <Eo2v2DataGrid
                $dataSource={dataSource1$}
                columnsDefination={columnsDefinations1}
                // onEdit={openEditSection}
                config={configuration1}
              />
            </Box>
          </Paper>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              // background: 'wheat',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Gender
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(2);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              // background: 'wheat',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Relationship
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(3);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              // background: 'wheat',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Anual Income
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(4);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              // background: 'wheat',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  BMI
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setType(5);
                    setAgeModal(true);
                  }}>
                  <AddIcon />
                </Button>
              </Box>
            </Box>
          </Paper> */}
        </Box>
      </Paper>

      <AgeModal open={ageModal} setOpen={setAgeModal} handleClose={handleClose} type={type} />
    </div>
  );
}
