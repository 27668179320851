import Box from "@material-ui/core/Box";
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ChipInput from "material-ui-chip-input";
import React, { useEffect } from "react";
import { AddressService, ParameterDataTypeService, ParameterRenderTypeService, SourcesService } from "../../remote-api/api/master-services";
import { EO2V2DragNDrop } from '../../shared-components';
import { Button } from "primereact/button";







const datatypeservice = new ParameterDataTypeService();
const rendertypeservice = new ParameterRenderTypeService();
const sourcetypeservice = new SourcesService();
const addressservice = new AddressService();

let dts$ = datatypeservice.getParameterDataTypes();
let rts$ = rendertypeservice.getParameterRenderTypes();
let addr$ = addressservice.getAddressConfig();
let src$ = sourcetypeservice.getSources();

const useStyles = makeStyles((theme) => ({
    input1: {
        width: "50%",
    },
    clientTypeRadioGroup: {
        flexWrap: "nowrap",
        "& label": {
            flexDirection: "row",
        },
    },
    formControl: {
        minWidth: 182,
    },
}));


export default function DynamicAddressComponent(props) {
    const classes = useStyles();
    const [fieldList, setFieldList] = React.useState([]);
    const [renderTypeList, setRenderTypeList] = React.useState([]);
    const [datatypeList, setDataTypeList] = React.useState([]);
    const [sourceList, setSourceList] = React.useState([]);
    const [fieldOptions, setFieldOptions] = React.useState([]);


    const useObservable = (observable, setter) => {
        useEffect(() => {
            let subscription = observable.subscribe((result) => {
                setter(result.content);
            });
            return () => subscription.unsubscribe();
        }, [observable, setter]);
    };

    const useObservable1 = (observable, setter) => {
        useEffect(() => {
            let subscription = observable.subscribe((result) => {
                let arr = []
                if (result.length !== 0) {
                    result.forEach((ad, i) => {
                        ad['iButtonRequired'] = ad.iButtonRequired === "true" ? true : false
                        ad['selectedDependsOnField'] = ad.dependOnfields
                        ad.addressConfigurationFieldMappings.forEach((acf, j) => {
                            acf['required'] = acf.required === "true" ? true : false
                            acf['spacialCharacterValidation'] = acf.spacialCharacterValidation === "true" ? true : false
                            acf['lengthValidation'] = acf.lengthValidation === "true" ? true : false
                            acf['readonly'] = acf.readonly === "true" ? true : false
                            acf['dependsOnChecked'] = acf.dependsOn !== '' && ad.dependsOn !== null ? true : false
                            arr.push({ id: "item" + i + j, val: acf.fieldName });

                        })

                    })
                }
                setFieldOptions(arr)
                setter(result);
            });
            return () => subscription.unsubscribe();
        }, [observable, setter]);
    };

    useObservable(dts$, setDataTypeList);
    useObservable(rts$, setRenderTypeList);
    useObservable(src$, setSourceList);
    useObservable1(addr$, setFieldList);

    const addNewField = (e) => {
        let field = {
            entityId: "item" + fieldList.length,
            levelName: "",
            iButtonRequired: false,
            iButtonMessage: '',
            dependOnfields: [],
            selectedDependsOnField: '',
            addressConfigurationFieldMappings: [
                {
                    fieldName: '',
                    type: '',
                    required: false,
                    dataType: '',
                    customValuePresent: '',
                    addressConfigurationFieldCustomValueMappings: [],
                    sourceId: '',
                    dependsOnChecked: false,
                    dependsOn: '',
                    size: '',
                    spacialCharacterValidation: false,
                    lengthValidation: false,
                    readonly: false,
                    defaultValue: ''
                }
            ]
        }

        setFieldList([
            ...fieldList,
            field,
        ]);
    }

    const handleField = (e, index) => {
        const { name, value } = e.target;
        const list = [...fieldList];
        list[index][name] = value;
        setFieldList(list);
    }

    const handleFieldChecked = (e, index) => {
        const { name, checked } = e.target;
        const list = [...fieldList];
        list[index][name] = checked;
        if (name === 'iButtonRequired' && !checked) {
            list[index]['iButtonMessage'] = '';
        }
        setFieldList(list);
    }


    const handleAddfieldProps = (i) => {
        let fieldprop = {
            fieldName: '',
            type: '',
            required: false,
            dataType: '',
            customValuePresent: '',
            addressConfigurationFieldCustomValueMappings: [],
            sourceId: '',
            dependsOnChecked: false,
            dependsOn: '',
            size: '',
            spacialCharacterValidation: false,
            lengthValidation: false,
            readonly: false,
            defaultValue: ''
        }

        let fl = [...fieldList]
        fl[i].addressConfigurationFieldMappings.push(fieldprop)
        setFieldList(fl);
        // let fieldArr = [...fieldOptions];
        // fieldArr.push({label:fieldName,value:fieldName});
        // setFieldOptions(fieldArr)
    }

    const handleFieldPropChange = (e, i, j) => {
        const { name, value } = e.target;
        const list = [...fieldList];
        list[i].addressConfigurationFieldMappings[j][name] = value;

        // DROPDOWN
        if (name === 'type' && value === 'dropdown') {
            list[i].addressConfigurationFieldMappings[j]['dataType'] = '';
            list[i].addressConfigurationFieldMappings[j]['size'] = '';
            list[i].addressConfigurationFieldMappings[j]['lengthValidation'] = false;
            list[i].addressConfigurationFieldMappings[j]['spacialCharacterValidation'] = false;

        }
        // TEXTBOX
        if (name === 'type' && value === 'textbox') {
            list[i].addressConfigurationFieldMappings[j]['sourceId'] = '';
            list[i].addressConfigurationFieldMappings[j]['dependsOnChecked'] = false;
            list[i].addressConfigurationFieldMappings[j]['dependsOn'] = '';
            list[i].addressConfigurationFieldMappings[j]['customValuePresent'] = '';
            list[i].addressConfigurationFieldMappings[j]['addressConfigurationFieldCustomValueMappings'] = [];
            list[i].addressConfigurationFieldMappings[j]['size'] = '';
            list[i].addressConfigurationFieldMappings[j]['lengthValidation'] = false;
            list[i].addressConfigurationFieldMappings[j]['spacialCharacterValidation'] = false;
        }
        //TEXTAREA
        if (name === 'type' && value === 'textarea') {
            list[i].addressConfigurationFieldMappings[j]['dataType'] = '';
            list[i].addressConfigurationFieldMappings[j]['sourceId'] = '';
            list[i].addressConfigurationFieldMappings[j]['dependsOnChecked'] = false;
            list[i].addressConfigurationFieldMappings[j]['dependsOn'] = '';
            list[i].addressConfigurationFieldMappings[j]['customValuePresent'] = '';
            list[i].addressConfigurationFieldMappings[j]['addressConfigurationFieldCustomValueMappings'] = [];
            list[i].addressConfigurationFieldMappings[j]['size'] = '';
            list[i].addressConfigurationFieldMappings[j]['lengthValidation'] = false;
            list[i].addressConfigurationFieldMappings[j]['spacialCharacterValidation'] = false;
        }

        //CUSTOM/DYNAMIC
        if (name === 'customValuePresent') {
            if (value === 'CUSTOM') {
                list[i].addressConfigurationFieldMappings[j]['sourceId'] = '';
                list[i].addressConfigurationFieldMappings[j]['dependsOn'] = '';
                list[i].addressConfigurationFieldMappings[j]['dependsOnChecked'] = false;
            } else {
                list[i].addressConfigurationFieldMappings[j]['addressConfigurationFieldCustomValueMappings'] = [];
            }
        }

        if (name === 'fieldName') {
            let fieldid = "field" + i + j;
            let fldOps = [...fieldOptions];
            let a = fldOps.some(el => el.id === fieldid);
            if (a) {
                fldOps.forEach(ele => {
                    if (ele.id === fieldid) {
                        ele.val = value;
                    }

                })
                setFieldOptions(fldOps);
            }
            if (!a) {
                fldOps.push({ id: fieldid, val: value })
                setFieldOptions(fldOps);

            }



        }
        setFieldList(list);
    }

    const handleFieldPropChecked = (e, i, j) => {
        const { name, checked } = e.target;
        const list = [...fieldList];
        list[i].addressConfigurationFieldMappings[j][name] = checked;
        if (name === 'dependsOnChecked' && !checked) {
            list[i].addressConfigurationFieldMappings[j]['dependsOn'] = '';
        }
        if (name === 'lengthValidation' && !checked) {
            list[i].addressConfigurationFieldMappings[j]['size'] = '';
        }
        if (name === 'readOnly' && !checked) {
            list[i].addressConfigurationFieldMappings[j]['defaultValue'] = '';
        }
        setFieldList(list);
    }


    const handleRemoveFieldProp = (i, j) => {
        const list = [...fieldList];
        list[i].addressConfigurationFieldMappings.splice(j, 1);
        setFieldList(list);
    };

    const handleRemoveField = (index) => {
        let list = [...fieldList];
        list.splice(index, 1);
        setFieldList(list);
    };

    const handleAddChip = (chip, i, j) => {
        const list = [...fieldList];
        list[i].addressConfigurationFieldMappings[j].addressConfigurationFieldCustomValueMappings.push(chip);
        setFieldList(list);
    };

    const handleDeleteChip = (chip, index, i, j) => {
        const list = [...fieldList];
        list[i].addressConfigurationFieldMappings[j].addressConfigurationFieldCustomValueMappings.splice(index, 1);
        setFieldList(list);
    };


    const saveConfigurations = () => {
        fieldList.forEach((val, index) => {
            val['order'] = (index + 1).toString();
            val['iButtonRequired'] = val['iButtonRequired'].toString()
            if (val.selectedDependsOnField !== '') {
                if(!val?.selectedDependsOnField || val?.selectedDependsOnField[0] === 'null'){
                    val['dependOnfields'] = null;
                } else {
                    val['dependOnfields'] = [val.selectedDependsOnField];
                }
            }
            // delete val.selectedDependsOnField;
            val.addressConfigurationFieldMappings.forEach((ele, j) => {
                ele['internalOrder'] = (j + 1).toString();
                ele['dependsOnChecked'] = ele['dependsOnChecked'].toString()
                ele['lengthValidation'] = ele['lengthValidation'].toString()
                ele['readonly'] = ele['readonly'].toString()
                ele['required'] = ele['required'].toString()
                ele['spacialCharacterValidation'] = ele['spacialCharacterValidation'].toString()
                // delete ele.readonly;
                // delete ele.dependsOnChecked;
                if (ele.addressConfigurationFieldCustomValueMappings.length !== 0) {
                    let customvalarr = ele.addressConfigurationFieldCustomValueMappings.map(custVal => {
                        return { value: custVal }
                    });
                    ele.addressConfigurationFieldCustomValueMappings = customvalarr;
                }
            })
        });
        addressservice.saveAddress(fieldList).subscribe(res => {
            if(res.id){
                window.location.reload();
            }
        })
    }

    const onDragEnd = (data) => {
        setFieldList(data);
    }

    return (
        <Paper elevation='none'>
            <Box p={3} my={2}>
                <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                    <Button color="primary" variant="contained" onClick={addNewField}>+ Add new field</Button>
                </Grid>
                <EO2V2DragNDrop items={fieldList} onChange={onDragEnd} >
                    <div>
                        {fieldList.map((x, i) => {
                            return (
                                <div id={x.entityId}>
                                    <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                                        <Grid item xs={12} container spacing={3} style={{ marginBottom: "20px" }}>
                                            <Grid item xs={3}>
                                                <TextField
                                                    id="standard-basic"
                                                    name="levelName"
                                                    value={x.levelName}
                                                    onChange={(e) => handleField(e, i)}
                                                    label="Field label"
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                {x.iButtonRequired ? (
                                                    <TextField
                                                        id="standard-basic"
                                                        name="iButtonMessage"
                                                        value={x.iButtonMessage}
                                                        onChange={(e) => handleField(e, i)}
                                                        label="i Button text"
                                                    />
                                                ) : null}

                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={x.iButtonRequired}
                                                            onChange={(e) => handleFieldChecked(e, i)}
                                                            name="iButtonRequired"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="i button required"
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel
                                                        id="demo-simple-select-label"
                                                        style={{ marginBottom: "0px" }}
                                                    >
                                                        Depends on Field
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="selectedDependsOnField"
                                                        value={x.selectedDependsOnField}
                                                        onChange={(e) => handleField(e, i)}
                                                    >
                                                        {fieldOptions.map((ele) => {
                                                            return <MenuItem value={ele.val}>{ele.val}</MenuItem>;
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                {/* <Button color="secondary" variant="contained" onClick={() => {handleRemoveField(i)}}>Delete field</Button> */}
                                                {fieldList.length !== 1 && (
                                                    <Tooltip title="Delete Field" placement="top">
                                                        <Button
                                                            className="mr10 p-button-danger"
                                                            onClick={() => handleRemoveField(i)}
                                                            variant="contained"
                                                            color="secondary"
                                                            style={{ marginLeft: "5px", height: "40px" }}
                                                            disabled={true}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {fieldList.length - 1 === i && (
                                                    <Tooltip title="Add Field" placement="top">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            style={{ marginLeft: "5px", height: "40px" }}
                                                            onClick={addNewField}
                                                            disabled={true}
                                                        >
                                                            <AddIcon />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {x.addressConfigurationFieldMappings.map((y, j) => {
                                            return (
                                                <Grid item xs={12} container spacing={3} style={{ marginBottom: "20px" }}>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            id="standard-basic"
                                                            name="fieldName"
                                                            value={y.fieldName}
                                                            // placeholder="enter a unique name without space"
                                                            onChange={(e) => handleFieldPropChange(e, i, j)}
                                                            label="Field name"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <FormControl className={classes.formControl}>
                                                            <InputLabel
                                                                id="demo-simple-select-label"
                                                                style={{ marginBottom: "0px" }}
                                                            >
                                                                Field type
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="type"
                                                                value={y.type}
                                                                onChange={(e) => handleFieldPropChange(e, i, j)}
                                                                style={{ marginRight:"1%", width:"80%" }}
                                                            >
                                                                {renderTypeList.map((ele) => {
                                                                    return <MenuItem value={ele.type}>{ele.name}</MenuItem>;
                                                                })}

                                                            </Select>
                                                        </FormControl>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={y.required}
                                                                    onChange={(e) => handleFieldPropChecked(e, i, j)}
                                                                    name="required"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="required field"
                                                        />

                                                    </Grid>
                                                    {/* TEXTBOX */}
                                                    {y.type === 'textbox' && (
                                                        <Grid item xs={2}>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={{ marginBottom: "0px" }}
                                                                >
                                                                    Data type
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    name="dataType"
                                                                    value={y.dataType}
                                                                    onChange={(e) => handleFieldPropChange(e, i, j)}
                                                                    style={{ marginRight:"1%", width:"80%" }}
                                                                >
                                                                    {datatypeList.map((ele) => {
                                                                        return <MenuItem value={ele.type}>{ele.name}</MenuItem>;
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>

                                                    )}

                                                    {/* DROPDOWN */}
                                                    {y.type === 'dropdown' && (
                                                        <>
                                                            <Grid item xs={2}>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel
                                                                        id="demo-simple-select-label"
                                                                        style={{ marginBottom: "0px" }}
                                                                    >
                                                                        Source type
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name="customValuePresent"
                                                                        value={y.customValuePresent}
                                                                        onChange={(e) => handleFieldPropChange(e, i, j)}
                                                                        style={{ marginRight:"1%", width:"80%" }}
                                                                    >
                                                                        {/* {identificationTypes.map((ele) => {
                                            return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                                        })} */}
                                                                        <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                                                                        <MenuItem value="DYNAMIC">DYNAMIC</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            {y.customValuePresent === 'DYNAMIC' && (
                                                                <>
                                                                    <Grid item xs={2}>
                                                                        <FormControl className={classes.formControl}>
                                                                            <InputLabel
                                                                                id="demo-simple-select-label"
                                                                                style={{ marginBottom: "0px" }}
                                                                            >
                                                                                Data Source
                                                                            </InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                name="sourceId"
                                                                                value={y.sourceId}
                                                                                onChange={(e) => handleFieldPropChange(e, i, j)}
                                                                                style={{ marginRight:"1%", width:"80%" }}
                                                                            >
                                                                                {sourceList.map((ele) => {
                                                                                    return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={y.dependsOnChecked}
                                                                                    onChange={(e) => handleFieldPropChecked(e, i, j)}
                                                                                    name="dependsOnChecked"
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label="depends on"
                                                                        />
                                                                    </Grid>

                                                                    {y.dependsOnChecked && (
                                                                        <Grid item xs={2}>
                                                                            <FormControl className={classes.formControl}>
                                                                                <InputLabel
                                                                                    id="demo-simple-select-label"
                                                                                    style={{ marginBottom: "0px" }}
                                                                                >
                                                                                    Depends on
                                                                                </InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    name="dependsOn"
                                                                                    value={y.dependsOn}
                                                                                    onChange={(e) => handleFieldPropChange(e, i, j)}
                                                                                    style={{ marginRight:"1%", width:"80%" }}
                                                                                >
                                                                                    {sourceList.map((ele) => {
                                                                                        return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                                                                                    })}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>

                                                                    )}
                                                                </>

                                                            )}

                                                            {y.customValuePresent === 'CUSTOM' && (
                                                                <ChipInput
                                                                    required
                                                                    // error={touched.parameterValues && Boolean(errors.parameterValues)}
                                                                    // helperText={touched.parameterValues && errors.parameterValues}
                                                                    label="Data Source"
                                                                    value={y.addressConfigurationFieldCustomValueMappings}
                                                                    onAdd={(chip) => handleAddChip(chip, i, j)}
                                                                    onDelete={(chip, index) => handleDeleteChip(chip, index, i, j)}
                                                                    style={{ marginRight:"1%", width:"80%" }}
                                                                />
                                                            )}

                                                        </>
                                                    )}
                                                    {/* TEXTAREA */}
                                                    {(y.type === 'textarea' || y.type === 'textbox') ? (
                                                        <>
                                                            <Grid item xs={2}>
                                                                {y.lengthValidation && (
                                                                    <TextField
                                                                        id="standard-basic"
                                                                        type="number"
                                                                        name="size"
                                                                        value={y.size}
                                                                        onChange={(e) => handleFieldPropChange(e, i, j)}
                                                                        label="Size"
                                                                        style={{ marginRight:"1%", width:"80%" }}
                                                                    />
                                                                )}
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={y.lengthValidation}
                                                                            onChange={(e) => handleFieldPropChecked(e, i, j)}
                                                                            name="lengthValidation"
                                                                            color="primary"
                                                                            // style={{ marginRight:"1%"}}
                                                                        />
                                                                    }
                                                                    label="Length validation"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={y.spacialCharacterValidation}
                                                                            onChange={(e) => handleFieldPropChecked(e, i, j)}
                                                                            name="spacialCharacterValidation"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Special character validation"
                                                                />
                                                            </Grid>
                                                        </>

                                                    ) : null}


                                                    <Grid item xs={2}>
                                                        {y.readOnly && (
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel
                                                                    id="demo-simple-select-label"
                                                                    style={{ marginBottom: "0px" }}
                                                                >
                                                                    Default Value
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    name="defaultValue"
                                                                    value={y.defaultValue}
                                                                    onChange={(e) => handleFieldPropChange(e, i, j)}
                                                                    style={{ marginRight:"1%", width:"80%" }}
                                                                >
                                                                    {/* {renderTypeList.map((ele) => {
                                                                        return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                                                                    })} */}

                                                                    <MenuItem value="IND">Country code</MenuItem>
                                                                    <MenuItem value="91">Country mobile code</MenuItem>

                                                                </Select>
                                                            </FormControl>
                                                        )}

                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={y.readOnly}
                                                                    onChange={(e) => handleFieldPropChecked(e, i, j)}
                                                                    name="readOnly"
                                                                    color="primary"
                                                                />  
                                                            }
                                                            label="readonly"
                                                        />

                                                    </Grid>

                                                    <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                                        {x.addressConfigurationFieldMappings.length !== 1 && (
                                                            <Button
                                                                className="mr10 p-button-danger"
                                                                onClick={() => handleRemoveFieldProp(i, j)}
                                                                variant="contained"
                                                                color="secondary"
                                                                style={{ marginLeft: "5px" }}
                                                                // disabled={true}
                                                                disabled={true}
                                                            >
                                                                <DeleteIcon />
                                                            </Button>
                                                        )}
                                                        {x.addressConfigurationFieldMappings.length - 1 === j && (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ marginLeft: "5px" }}
                                                                onClick={() => { handleAddfieldProps(i) }}
                                                                disabled={true}
                                                            >
                                                                <AddIcon />
                                                            </Button>
                                                        )}
                                                    </Grid>

                                                </Grid>
                                            )
                                        })}






                                        <Divider style={{ marginBottom: "20px" }} />
                                    </Grid>
                                </div>
                            )
                        })}
                    </div>
                </EO2V2DragNDrop>
                <Grid container spacing={3} style={{ marginTop: "20px" }}>
                    {fieldList.length !== 0 && <Button color="primary" disabled={true} variant="contained" onClick={saveConfigurations}>Save Configurations</Button>}

                </Grid>
            </Box>
        </Paper>
    );
}