import { withStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AccessRightsFormComponent from './access.rights.form';
import AccessRightsList from './access.rights.list';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0',
  },
});

function AccessRightsComponent(props) {
  const history = useHistory();
  const query = useQuery();

  const { classes } = props;

  const handleOpen = () => {
    // history.push("/user-management/access-rights/?mode=create");
  };

  const handleEdit = row => {
    // history.push(`/user-management/access-rights/${row.id}?mode=edit`);
    // <ParametersListComponent handleEdit={handleEdit} handleOpen={handleOpen} />
  };

  return (
    <div>
      {(() => {
        switch (query.get('mode')) {
          case 'viewList':
            return <AccessRightsList />;
          case 'create':
            return <AccessRightsFormComponent />;
          default:
            history.push('/user-management/access-rights?mode=viewList');
        }
      })()}
    </div>
  );
}

export default withStyles(useStyles)(AccessRightsComponent);
