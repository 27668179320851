import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as yup from 'yup';
import { AddressService, PrefixTypeService } from '../../remote-api/api/master-services';
import { Divider, Tooltip } from '@material-ui/core';
import { HierarchyService } from '../../remote-api/api/hierarchy-services';
import Asterisk from '../../shared-components/components/red-asterisk';

const addressservice = new AddressService();
const branchService = new HierarchyService();
const prefixservice = new PrefixTypeService();

let addr$ = addressservice.getAddressConfig();
const prefx$ = prefixservice.getPrefixTypes();

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: '90%',
  },
}));

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

const schemaObject = {
  mobileNo: yup
    .string()
    ['min'](10, 'Must be exactly 10 digit')
    ['max'](10, 'Must be exactly 10 digit')
    .nullable(),
  // .matches(new RegExp('[0-9]{10}'),'Contact number is not valid'),
  alternateMobileNo: yup
    .string()
    ['min'](10, 'Must be exactly 10 digit')
    ['max'](10, 'Must be exactly 10 digit')
    .nullable(),
  emailId: yup
    .string()
    .email('Enter a valid email')
    .nullable(),
  alternateEmailId: yup
    .string()
    .email('Enter a valid email')
    .nullable(),
  // alternateMobileNo: yup
  //   .string("Enter your Contact Number")
  //   .test('len', 'Must be exactly 10 digit', val => val.length === 10),
  // .matches(new RegExp('[0-9]{10}'),'Contact number is not valid'),
  // alternateEmailId: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email'),
};
let validationSchema = yup.object(schemaObject);

const initialValues = {
  addressData: {},
  centerAddressLine1: '',
  centerAddressLine2: '',
  centerCountry: '',
  centerCounty: '',
  centerCity: '',
  centerPostalCode: '',
  centerPoliceStation: '',
  centerPObox: '',
  personDetailPrefix: '',
  personDetailFirstName: '',
  personDetailMiddleName: '',
  personDetailLastName: '',
  personDetailPhoneNo: '',
  personDetailMobile: '',
  personDetailMailId: '',
};

export default function BranchAddressDetailsComponent(props) {
  const classes = useStyles();
  const query2 = useQuery1();
  const history = useHistory();
  const { id } = useParams();
  const [addressConfiguration, setAddressConfiguration] = React.useState([]);
  const [addressConfig, setAddressConfig] = React.useState([]);
  const [prefixes, setPrefixes] = React.useState([]);
  const [formObj, setFormObj] = React.useState({});
  const [branchForm, setBranchForm] = React.useState({ ...initialValues });
  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmitPlan();
    },
  });

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable(prefx$, setPrefixes);

  // React.useEffect(() => {
  //   if (id) {
  //     populateData(id);
  //   }
  // }, [id]);

  const populateData = (id, prodList = []) => {
    branchService.getBranchDetails(id).subscribe(value => {
      let pdata = {
        name: '',
        id: '',
      };
      prodList.forEach(ele => {
        if (ele.id === value.productId) {
          pdata = ele;
        }
      });
      formik.setValues({
        name: value.name,
        code: value.code,
        productCode: value.productCode,
        productCurrency: value.productCurrency,
        premiumCurrency: value.premiumCurrency,
        clientType: value.clientType,
        groupType: value.groupType,
        description: value.description,
        productId: value.productId,
        productData: pdata,
      });

      // if(value.clientType === 'GROUP'){
      //     formik.setFieldValue('groupType',value.groupType)
      // }
    });
  };

  const handleSubmitPlan = () => {
    let addrArr = [];

    if (formik.values.addressData) {
      for (let [key, value] of Object.entries(formik.values.addressData)) {
        let objAddr = {
          addressDetails: {
            [key]: value,
          },
          addressType: 'CURRENT_ADDRESS',
        };
        addrArr.push(objAddr);
      }
    }
    let payload = {
      branchAddressDetails: {
        addresses: addrArr,
      },
    };
    if (query2.get('mode') === 'create') {
      branchService.editBranch(payload, props.branchId, '1').subscribe(res => {
        props.handleNext();
      });
      // branchService.editBranch(payload, props.branchId, '2').subscribe(res => {
      //   props.handleClose();
      // });
    }
    if (query2.get('mode') === 'edit') {
      branchService.editBranch(payload, id, '1').subscribe(res => {
        props.handleNext();
      });
      // branchService.editBranch(payload, id, '2').subscribe(res => {
      //   props.handleClose();
      // });
    }
  };

  const handleClose = event => {
    history.push(`/branch?mode=viewList`);
    // window.location.reload();
  };

  useEffect(() => {
    let subscription = addr$.subscribe(result => {
      if (result.length !== 0) {
        result.forEach((prop, i) => {
          prop.addressConfigurationFieldMappings.forEach((field, j) => {
            // let fname = "field"+i+j;
            // field['fieldName'] = fname;
            field['value'] = '';
            if (field.sourceId !== null && field.sourceId !== '') {
              field['sourceList'] = [];
            }
            if (field.type === 'dropdown' && field.sourceId === null) {
              if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
                field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
              }
              if (field.type === 'dropdown' && field.sourceId === null) {
                if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
                  field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
                }
                // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
                //   field['sourceList'] = [];
                // }
              }
            }
          });
        });
        setAddressConfiguration(result);
      }
    });
    return () => subscription.unsubscribe();
  }, [addr$, setAddressConfiguration]);

  useEffect(() => {
    if (addressConfiguration && addressConfiguration.length !== 0) {
      setAddressConfig(addressConfiguration);
      let frmObj = {};
      // let frmLst = {};
      addressConfiguration.forEach((prop, i) => {
        prop.addressConfigurationFieldMappings.map((field, j) => {
          frmObj[field.fieldName] = field.defaultValue;
          if (field.dependsOn == '' && field.type == 'dropdown' && field.modifyApiURL) {
            addressservice.getSourceList(field.modifyApiURL).subscribe(res => {
              // field.sourceList =res.content;
              const list = [...addressConfiguration];
              list[i].addressConfigurationFieldMappings[j].sourceList = res.content;
              setAddressConfig(list);
              // frmLst[field.fieldName] = res.content;
            });
          }
        });
      });
      setFormObj(frmObj);

      setBranchForm({
        ...branchForm,
        addressData: frmObj,
      });

      let newSchema = {
        ...schemaObject,
      };
      let addressDataSchemaObject = {};
      const regex = /^[\w&., \-]*$/;
      addressConfiguration.forEach((prop, i) => {
        prop.addressConfigurationFieldMappings.map((field, j) => {
          let validationsArr = [];
          if (field.required === 'true') {
            validationsArr.push({ type: 'required', params: ['This field is required'] });
          }
          if (
            field.lengthValidation === 'true' &&
            field.size !== '' &&
            field.size !== null &&
            field.dataType !== 'numeric'
          ) {
            let msg = 'length must be' + ' ' + field.size + ' ' + 'digits';
            validationsArr.push({ type: 'min', params: [Number(field.size), msg] });
            validationsArr.push({ type: 'max', params: [Number(field.size), msg] });
          }
          if (
            field.lengthValidation === 'true' &&
            field.size !== '' &&
            field.size !== null &&
            field.dataType === 'numeric'
          ) {
            let msg = 'length must be' + ' ' + field.size + ' ' + 'digits';
            validationsArr.push({
              type: 'test',
              params: ['len', msg, val => val && val.toString().length === Number(field.size)],
            });
          }

          if (field.spacialCharacterValidation === 'true' && field.dataType !== 'numeric') {
            let msg = 'No special character allowed';
            validationsArr.push({ type: 'matches', params: [regex, msg] });
          }
          if (validationsArr.length > 0) {
            let v;
            if (field.dataType === 'numeric') {
              v = yup.number();
            } else {
              v = yup.string();
            }

            validationsArr.forEach(item => {
              v = v[item.type](...item.params);
            });
            addressDataSchemaObject = { ...addressDataSchemaObject, [field.fieldName]: v };
          }
        });
      });

      const addressDataSchema = yup.object(addressDataSchemaObject);
      newSchema = { ...newSchema, addressData: addressDataSchema };
      validationSchema = yup.object(newSchema);
    }
    // populateData();
  }, [addressConfiguration]);

  // const callAPiFunc = (field, prop, resultarr) => {
  //   if (addressConfiguration && addressConfiguration.length !== 0) {
  //     addressConfiguration && addressConfiguration.forEach((pr, i) => {
  //       pr.addressConfigurationFieldMappings.forEach((fi, j) => {
  //         if (fi.fieldName === prop.dependOnfields[0]) {
  //           // let p = prop.dependOnfields[0];
  //           // let fb = formObj[p];
  //           resultarr.push(fi.value);
  //           if (pr.dependOnfields !== null) {
  //             callAPiFunc(fi, pr, resultarr);
  //           }
  //         }
  //       });
  //     });
  //   }
  //   return resultarr;
  // };

  const handleDynamicAddressChange = (e, field, p) => {
    const { name, value } = e.target;
    if (addressConfiguration && addressConfiguration.length !== 0) {
      if (name && value) {
        setFormObj({
          ...formObj,
          [name]: value,
        });

        formik.setValues({
          ...formik.values,
          addressData: {
            ...formik.values.addressData,
            [name]: value,
          },
        });

        // setValues({
        //   ...values,
        //   addressData: {
        //     ...values.addressData,
        //     [name]: value,
        //   },
        // });
      }

      addressConfiguration.forEach((p, i) => {
        p.addressConfigurationFieldMappings.map((f, j) => {
          if (f.fieldName === name) {
            f['value'] = value;
          }
        });
      });

      addressConfiguration.forEach((p, i) => {
        p.addressConfigurationFieldMappings.map((f, j) => {
          if (field.type == 'dropdown' && p.dependOnfields !== null) {
            if (p.dependOnfields[0] === field.fieldName) {
              const word = '{code}';
              let arr = [];
              // let dArr = callAPiFunc(f, p, arr);
              let apiURL = f.modifyApiURL;
              // dArr.forEach(cd => {
              //   apiURL =
              //     apiURL.slice(0, apiURL.lastIndexOf(word)) + apiURL.slice(apiURL.lastIndexOf(word)).replace(word, cd);
              // });
              addressservice.getSourceList(apiURL).subscribe(res => {
                const list = [...addressConfig];
                list[i].addressConfigurationFieldMappings[j].sourceList = res.content;
                setAddressConfig(list);
              });
            }
          }
        });
      });
    }
  };
  
  return (
    <Paper elevation="none">
      <Box p={3} my={2}>
        <form onSubmit={formik.handleSubmit} noValidate>
          {addressConfiguration && addressConfiguration.length !== 0 && (
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              {addressConfig.map((prop, i) => {
                return prop.addressConfigurationFieldMappings.length !== 1 ? (
                  <Grid item xs={6}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                        {prop.levelName}
                      </InputLabel>
                      {prop.iButtonRequired === 'true' && (
                        <Tooltip title={prop.iButtonMessage} placement="top">
                          <InfoOutlinedIcon style={{ fontSize: 'medium' }} />
                        </Tooltip>
                      )}
                    </div>
                    {prop.addressConfigurationFieldMappings.map((field, j) => {
                      return (
                        <div>
                          {field.type === 'dropdown' && !field.readOnly && (
                            <FormControl className={classes.formControl}>
                              <Select
                                labelId="demo-simple-select-label"
                                name={field.fieldName}
                                id="demo-simple-select"
                                required={field.required === 'true' ? true : false}
                                // error={errorTxtFnc('addressData', field.fieldName)}
                                value={formObj[field.fieldName] ? formObj[field.fieldName] : ''}
                                onChange={e => {
                                  handleDynamicAddressChange(e, field, prop);
                                }}
                                style={{ marginRight: '8px' }}>
                                {field.sourceList.map(ele => {
                                  return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                                })}
                              </Select>
                              {/* {touched.hasOwnProperty('addressData') &&
                                touched?.addressData[field.fieldName] &&
                                errors.hasOwnProperty('addressData') &&
                                errors.addressData[field.fieldName] && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    {touched.hasOwnProperty('addressData') &&
                                      touched?.addressData[field.fieldName] &&
                                      errors.hasOwnProperty('addressData') &&
                                      errors.addressData[field.fieldName]}
                                  </FormHelperText>
                                )} */}
                            </FormControl>
                          )}
                          {field.type === 'textbox' && !field.readOnly && (
                            <TextField
                              id="standard-basic"
                              name={field.fieldName}
                              type={field.dataType === 'numeric' ? 'number' : 'text'}
                              required={field.required === 'true' ? true : false}
                              // error={errorTxtFnc('addressData', field.fieldName)}
                              // helperText={helperTextFnc('addressData', field.fieldName)}
                              value={formObj[field.fieldName] ? formObj[field.fieldName] : ''}
                              onChange={e => {
                                handleDynamicAddressChange(e, field);
                              }}
                              style={{ marginTop: '8px' }}
                            />
                          )}

                          {field.type === 'textarea' && !field.readOnly && (
                            <TextField
                              required={field.required === 'true' ? true : false}
                              id="standard-multiline-flexible"
                              multiline
                              name={field.fieldName}
                              rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                              value={formObj[field.fieldName] ? formObj[field.fieldName] : ''}
                              onChange={e => {
                                handleDynamicAddressChange(e, field);
                              }}
                              // error={errorTxtFnc('addressData', field.fieldName)}
                              // helperText={helperTextFnc('addressData', field.fieldName)}
                            />
                          )}
                          {field.readOnly && (
                            <TextField
                              id="standard-basic"
                              name={field.fieldName}
                              value={field.defaultValue}
                              defaultValue={field.defaultValue}
                              readonly
                              style={{ marginTop: '10px', marginRight: '8px', width: '15%' }}
                              size="small"
                            />
                          )}
                        </div>
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} md={4}>
                    {prop.addressConfigurationFieldMappings.map((field, j) => {
                      return (
                        <div>
                          {field.type === 'dropdown' && !field.readOnly && (
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                {prop.levelName}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name={field.fieldName}
                                required={field.required === 'true' ? true : false}
                                id="demo-simple-select"
                                value={formObj[field.fieldName] ? formObj[field.fieldName] : ''}
                                // error={errorTxtFnc('addressData', field.fieldName)}
                                onChange={e => {
                                  handleDynamicAddressChange(e, field, prop);
                                }}>
                                {field.customValuePresent === 'CUSTOM' &&
                                  field.sourceList.map(ele => {
                                    return <MenuItem value={ele.id}>{ele.value}</MenuItem>;
                                  })}
                                {field.customValuePresent === 'DYNAMIC' &&
                                  field.sourceList.map(ele => {
                                    return <MenuItem value={ele.code}>{ele.name}</MenuItem>;
                                  })}
                              </Select>
                              {/* {touched.hasOwnProperty('addressData') &&
                                touched?.addressData[field.fieldName] &&
                                errors.hasOwnProperty('addressData') &&
                                errors.addressData[field.fieldName] && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    {touched.hasOwnProperty('addressData') &&
                                      touched?.addressData[field.fieldName] &&
                                      errors.hasOwnProperty('addressData') &&
                                      errors.addressData[field.fieldName]}
                                  </FormHelperText>
                                )} */}
                            </FormControl>
                          )}

                          {field.type === 'textbox' && !field.readOnly && (
                            <FormControl className={classes.formControl}>
                              <TextField
                                required={field.required === 'true' ? true : false}
                                id="standard-basic"
                                name={field.fieldName}
                                type={field.dataType === 'numeric' ? 'number' : 'text'}
                                value={formObj[field.fieldName] ? formObj[field.fieldName] : ''}
                                onChange={e => {
                                  handleDynamicAddressChange(e, field);
                                }}
                                // error={errorTxtFnc('addressData', field.fieldName)}
                                // helperText={helperTextFnc('addressData', field.fieldName)}
                                label={prop.levelName}
                              />
                            </FormControl>
                          )}

                          {field.type === 'textarea' && !field.readOnly && (
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-multiline-flexible"
                                // required={field.required === 'true' ? true : false}
                                multiline
                                name={field.fieldName}
                                rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                                value={
                                  formik.values.addressData[field.fieldName]
                                    ? formik.values.addressData[field.fieldName]
                                    : ''
                                }
                                onChange={e => {
                                  handleDynamicAddressChange(e, field);
                                }}
                                // error={errorTxtFnc('addressData', field.fieldName)}
                                // helperText={helperTextFnc('addressData', field.fieldName)}
                                label={
                                  field.required === 'true'
                                    ? <span>{prop.levelName} <Asterisk /></span>
                                    : prop.levelName
                                }
                              />
                            </FormControl>
                          )}
                          {field.readOnly && (
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name={field.fieldName}
                                value={field.defaultValue}
                                label={prop.levelName}
                                defaultValue={field.defaultValue}
                                disabled={true}
                              />
                            </FormControl>
                          )}
                          {prop.iButtonRequired === 'true' && (
                            <Tooltip title={prop.iButtonMessage} placement="top">
                              <InfoOutlinedIcon style={{ fontSize: 'medium', marginTop: '23px' }} />
                            </Tooltip>
                          )}
                        </div>
                      );
                    })}
                  </Grid>
                );
              })}
              <Divider />
            </Grid>
          )}

          {/* <Grid itemxs={12} sm={6} md={4} style={{ marginTop: '5%' }}>
            <span style={{ color: '#4472C4', fontWeight: 'bold' }}>Contact Person Details</span>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '15px' }}>
            <Divider />
          </Grid>

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                  Prefix
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="personDetailPrefix"
                  value={formik.values.personDetailPrefix}
                  onChange={formik.handleChange}>
                  {prefixes.map(ele => {
                    return <MenuItem value={ele.code}>{ele.abbreviation}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="personDetailFirstName"
                  value={formik.values.personDetailFirstName}
                  onChange={formik.handleChange}
                  error={formik.touched.personDetailFirstName && Boolean(formik.errors.personDetailFirstName)}
                  helperText={formik.touched.personDetailFirstName && formik.errors.personDetailFirstName}
                  label="First Name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="personDetailMiddleName"
                  value={formik.values.personDetailMiddleName}
                  onChange={formik.handleChange}
                  error={formik.touched.personDetailMiddleName && Boolean(formik.errors.personDetailMiddleName)}
                  helperText={formik.touched.personDetailMiddleName && formik.errors.personDetailMiddleName}
                  label="Middle Name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="personDetailLastName"
                  value={formik.values.personDetailLastName}
                  onChange={formik.handleChange}
                  error={formik.touched.personDetailLastName && Boolean(formik.errors.personDetailLastName)}
                  helperText={formik.touched.personDetailLastName && formik.errors.personDetailLastName}
                  label="Last Name"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="personDetailPhoneNo"
                  value={formik.values.personDetailPhoneNo}
                  onChange={formik.handleChange}
                  error={formik.touched.personDetailPhoneNo && Boolean(formik.errors.personDetailPhoneNo)}
                  helperText={formik.touched.personDetailPhoneNo && formik.errors.personDetailPhoneNo}
                  label="Phone"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="personDetailMobile"
                  value={formik.values.personDetailMobile}
                  onChange={formik.handleChange}
                  error={formik.touched.personDetailMobile && Boolean(formik.errors.personDetailMobile)}
                  helperText={formik.touched.personDetailMobile && formik.errors.personDetailMobile}
                  label="Mobile Number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="personDetailMailId"
                  value={formik.values.personDetailMailId}
                  onChange={formik.handleChange}
                  error={formik.touched.personDetailMailId && Boolean(formik.errors.personDetailMailId)}
                  helperText={formik.touched.personDetailMailId && formik.errors.personDetailMailId}
                  label="Email"
                />
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="secondary" style={{ marginRight: '5px' }} type="submit">
                Save
              </Button>
              <Button variant="contained" color="primary" onClick={handleClose} className="p-button-text">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
}
