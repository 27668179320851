import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import "date-fns";
import * as React from "react";
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClientService, ProspectService } from "../../../remote-api/api/client-services";
import { Eo2v2MultiFieldSearch } from '../../../shared-components';




const clientservice = new ClientService();
const prospectservice = new ProspectService();



export default function ReceiptClientModal(props) {

    // const formik = useFormik({
    //     initialValues: {
    //         remarks: "",
    //         startDate: new Date().getTime()
    //     },
    //     validationSchema: validationSchema,
    //     onSubmit: (values) => {
    //         handleModalSubmit();
    //     },
    // });

    // const [openBlacklistModal, setOpenBlacklistModal] = React.useState(false);
    // useEffect(() => {
    //     setOpenBlacklistModal(props.openBlacklistModal);
    // }, [props.openBlacklistModal]);


    const handleModalSubmit = (e) => {

    }

    const handleClose = () => {
        props.handleCloseClientModal();
    }

    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        const timestamp = new Date(date).getTime();
        // formik.setFieldValue('startDate', timestamp);
    };

    const dataSource$ = (fields, pageRequest = { page: 0, size: 10 }) => {
        let pagerequestquery = {
            page: pageRequest.page, size: pageRequest.size, summary: false
        }
        Object.keys(fields).filter(key => !!fields[key])
            .forEach(key => pagerequestquery[key] = fields[key]);

        return clientservice.importClient(pagerequestquery).pipe(map(data => {
            let content = data.content;
            if (content.length > 0) {
                let records = content.map(item => {
                    item["contactNo"] = item.clientBasicDetails.contactNos[0].contactNo
                    item["code"] = item.clientBasicDetails.code;
                    item["name"] = item.clientBasicDetails.displayName;
                    item["email"] = item.clientBasicDetails.emails[0].emailId;
                    item["clientType"] = "Client";
                    return item
                });
                data.content = records;
                // return data;
            }
            return data;


        }
        )).pipe(switchMap(data => {
            let pagerequestquery2 = {
                page: pageRequest.page, size: pageRequest.size, summary: false
            }
            Object.keys(fields).filter(key => !!fields[key])
                .forEach(key => pagerequestquery2[key] = fields[key]);

            if (data.content.length === 0) {
                return prospectservice.importProspectData(pagerequestquery2).pipe(map(data2 => {
                    let content2 = data2.content;
                    let records2 = content2.map(item2 => {
                        item2["contactNo"] = item2.mobileNo;
                        item2["code"] = item2.code;
                        item2["name"] = item2.displayName;
                        item2["email"] = item2.emailId;
                        item2["clientType"] = "Prospect";

                        return item2
                    });
                    data2.content = records2;
                    return data2;

                }));
            } else return of(data)
        }))
    }

    const fields = [
        { label: "Code", propertyName: "code" },
        { label: "Name", propertyName: "name" }]


    const fields2 = [
        { label: "Code", propertyName: "code" },
        { label: "Name", propertyName: "displayName" },
        { label: "Contact", propertyName: "mobileNo" }];

    const columnsDefinations = [{ field: "code", headerName: "Code" },
    { field: "name", headerName: "Name" },
    { field: "contactNo", headerName: "Contact" }];

    const handleImport = (item) => {

        props.handleSubmitClientModal(item);
    }



    return (

        <Dialog open={props.openClientModal} onClose={handleClose} aria-labelledby="form-dialog-title" disableEnforceFocus>
            <DialogTitle id="form-dialog-title">Select Client</DialogTitle>
            <DialogContent>
                <Eo2v2MultiFieldSearch
                    $dataSource={dataSource$}
                    fields={fields}
                    onSelect={(item) => { handleImport(item) }}
                    columnsDefinations={columnsDefinations}
                    dataGridPageSize={10}
                    dataGridScrollHeight='400px'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className='p-button-text' color="primary">
                    Cancel
                </Button>
                <Button onClick={handleModalSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}