import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LayoutContext from '../LayoutContext';
import { Box } from '@material-ui/core';

const SidebarToggleHandler = ({ children, ...restProps }) => {
  const { isSidebarOpen, setSidebarOpen } = useContext(LayoutContext);

  return (
    <Box sx={{display:{xs:"block", lg:"none"}}}>
      <IconButton className="Cmt-toggle-menu" style={{color:"#000"}} onClick={() => setSidebarOpen(!isSidebarOpen)} {...restProps}>
        {children || <MenuIcon />}
      </IconButton>
    </Box>
  );
};

export default SidebarToggleHandler;
