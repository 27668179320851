import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import 'date-fns';
import * as React from 'react';
import { useEffect } from 'react';
import { map } from 'rxjs/operators';
import { AgentsService } from '../../../remote-api/api/agents-services';
import { Eo2v2MultiFieldSearch } from '../../../shared-components';

const agentservice = new AgentsService();

export default function InvoiceAgentModal(props) {
  const [selectedAgents, setSelectedAgents] = React.useState([]);

  useEffect(() => {
    setSelectedAgents(props.agentsList);
  }, [props.agentsList]);

  const handleModalSubmit = e => {
    props.handleAgentModalSubmit(selectedAgents);
  };

  const handleClose = () => {
    props.handleCloseAgentModal();
  };

  const deleteAgent = agentId => {
    const list = [...selectedAgents];
    const arr = list.filter(ele => ele.agentId !== agentId);
    setSelectedAgents(arr);
  };

  const dataSource$ = (fields, pageRequest = { page: 0, size: 10 }) => {
    let pagerequestquery = {
      page: pageRequest.page,
      size: pageRequest.size,
      summary: false,
    };
    Object.keys(fields)
      .filter(key => !!fields[key])
      .forEach(key => (pagerequestquery[key] = fields[key]));
    return agentservice.importAgentData(pagerequestquery).pipe(
      map(data => {
        let content = data.content;
        let records = content.map(item => {
          item['contactNo'] = item.agentBasicDetails.contactNos[0].contactNo;
          item['code'] = item.agentBasicDetails.code;
          item['name'] = item.agentBasicDetails.name;
          item['agentId'] = item.id;
          item['commissionType'] = 'PERCENTAGE';
          item['commissionValue'] = 0;
          item['finalValue'] = '';
          return item;
        });
        data.content = records;
        return data;
      }),
    );
  };
  const fields = [
    { label: 'Code', propertyName: 'code' },
    { label: 'Name', propertyName: 'name' },
    { label: 'Contact', propertyName: 'contactNo' },
  ];

  const columnsDefinations = [
    { field: 'code', headerName: 'Agent Code' },
    { field: 'name', headerName: 'Agent Name' },
    { field: 'contactNo', headerName: 'Agent Contact' },
  ];

  const handleImport = item => {
    let isPresent = false;
    let list = [...selectedAgents];
    selectedAgents.forEach(ele => {
      if (ele.agentId === item.agentId) {
        isPresent = true;
      }
    });
    if (!isPresent) {
      list.push(item);
      setSelectedAgents(list);
    }
  };

  return (
    <Dialog open={props.openAgentModal} onClose={handleClose} aria-labelledby="form-dialog-title" disableEnforceFocus>
      <DialogTitle id="form-dialog-title">Select Agent</DialogTitle>
      <DialogContent>
        <Eo2v2MultiFieldSearch
          $dataSource={dataSource$}
          fields={fields}
          onSelect={item => {
            handleImport(item);
          }}
          columnsDefinations={columnsDefinations}
          dataGridPageSize={10}
          dataGridScrollHeight="400px"
        />
        {/* <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid> */}
        <Divider />

        {selectedAgents.length > 0 && (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedAgents.map(row => (
                  <TableRow key={row.agentId}>
                    <TableCell component="th" scope="row">
                      {row.code}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.contactNo}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        // className={classes.button}
                        startIcon={<DeleteIcon style={{color:"#dc3545"}} />}
                        onClick={() => {
                          deleteAgent(row.agentId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="p-button-text" color="primary">
          Cancel
        </Button>
        <Button onClick={handleModalSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
