import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Endorsement from './endorsements/endorsement.component';
import EndorsementDetails from './endorsements/endorsement.details.component';

export default function EndorsementRoutersComponent(props) {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <Endorsement />{' '}
        </Route>
        <Route exact path="/endorsements" component={Endorsement} />
        <Route exact path="/endorsements/:id" component={EndorsementDetails} />
      </Switch>
    </React.Fragment>
  );
}
