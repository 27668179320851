function throwErrorMessageEvent(code, message){
    const event = new CustomEvent('errorHappend',{
        detail: {
         response:{
            data: {
                code,message
            }
         }
        }
      });
      window.document.dispatchEvent(event);
}


export default throwErrorMessageEvent;