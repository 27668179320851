import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RoleService from '../../services/utility/role';
import RenewalManagementComponent from './components/renewal-management';
import RenewalConfigForm from './components/renewal.config';
const roleService = new RoleService();

export default function RenewalRoutersComponent(props) {
  const { path } = useRouteMatch();

  const hasAccess = roleService.checkRouteAccess(props, 'PRODUCT');

  if (hasAccess) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={`${path}/pending`} component={RenewalManagementComponent} />
          <Route exact path={`${path}/config`} component={RenewalConfigForm} />
        </Switch>
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/unauthorized',
        state: {
          from: props.location,
        },
      }}
    />
  );
}
