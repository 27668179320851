import { Snackbar } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import Checkbox from '@material-ui/core/Checkbox';
// import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Alert } from '@material-ui/lab';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formControl: {
    minWidth: 182,
  },
  formControl1: {
    minWidth: 300,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 700,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function UnderwritingsReportComponent(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '20px',
          height: '2em',
          fontSize: '18px',
        }}>
        <span
          style={{
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '5px',
          }}>
          Report - Underwritings
        </span>
      </Grid>
      <Paper
        elevation="none"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '1%',
        }}>
        <Box padding={'10px'} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gridGap={'10px'}>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'} onClick={()=>{history.push('/report/underwritings/1');}}>
                <Typography align="center" variant="h2">
                  Member Statement
                </Typography>
                <LaunchIcon />
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              // background: 'wheat',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Report-2
                </Typography>
                <LaunchIcon />
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              // background: 'wheat',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Report-3
                </Typography>
                <LaunchIcon />
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation="10px"
            style={{
              borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
              display: 'flex',
              // background: 'wheat',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '1%',
            }}>
            <Box width={'250px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography align="center" variant="h4">
                  Report-4
                </Typography>
                <LaunchIcon />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Paper>
    </div>
  );
}
