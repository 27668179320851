import { withStyles } from "@material-ui/styles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import NegotiationListComponent from './negotiation.list.component';
import NegotiationFormComponent from "./negotiation.form.component";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = (theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 0'
    }
});

function NegotiationComponent(props) {
    const history = useHistory();
    const query = useQuery();

    const { classes } = props;

    const handleOpen = () => {
        history.push("/provider/negotiation?mode=create");
    }

    const handleEdit = (row) => {
        history.push(`/provider/negotiation/${row.id}?mode=edit`);
    }

    return (
        <div>
            {(() => {
                switch (query.get("mode")) {
                    case 'viewList':
                        return (
                            <NegotiationListComponent handleEdit={handleEdit} handleOpen={handleOpen} />
                        );
                    case 'create':
                        return (
                            < NegotiationFormComponent />
                        );
                    default:
                        history.push("/provider/negotiation?mode=viewList");
                }
            })()}
        </div>
    );
}

export default withStyles(useStyles)(NegotiationComponent);