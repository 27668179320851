import { Observable } from "rxjs/";
import { map } from "rxjs/operators";
import { http } from "../../http.client";
import { CleintType } from "../../models/client.type";
import { Page } from "../../models/page";
import { defaultPageRequest, PageRequest } from "../../models/page.request";

export class FundTypeService {
  readonly COMMAND_CONTEXT = `/master-data-service/v1/fundType`;
  readonly QUERY_CONTEXT = `/master-data-service/v1/fundType`;

  getFundTypes(
    pageRequest: PageRequest = defaultPageRequest
  ): Observable<Page<any>> {
    return http
      .get<Page<any>>(`${this.QUERY_CONTEXT}`, { params: pageRequest })
      .pipe(map((response) => response.data));
  }
}