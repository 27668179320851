import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import MembersComponent from "./member.component";
import MemberUploadComponent from './member.upload.component';
import MemberDetail from './member.details.components';

export default function MemberUploadRouterComponent(props) {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          {' '}
          <MemberUploadComponent />{' '}
        </Route>
        <Route exact path={`${path}/member`}>
          {' '}
          <MembersComponent />{' '}
        </Route>
        <Route exact path={`${path}/member/detail/:id`}>
          {' '}
          <MemberDetail />{' '}
        </Route>
      </Switch>
    </React.Fragment>
  );
}
