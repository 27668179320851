import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ClientService } from '../../remote-api/api/client-services';
import SLAConfigurationComponent from './sla.configuration.component';
import SLAConfigurationListComponent from './sla.configuration.list.component';

const clientService = new ClientService();
let csTableData$ = clientService.getClients();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SLAConfiguration() {
  const history = useHistory();
  const query = useQuery();

  const [isEdit, setIsEdit] = React.useState(false);

  const [rows, setRows] = React.useState([]);

  const [opendetails, setOpenDetails] = React.useState(false);

  const [clientType, setClientType] = React.useState('RETAIL');


  const extractObject = params => {
    const api = params.api;
    const fields = api
      .getAllColumns()
      .map(c => c.field)
      .filter(c => c !== '__check__' && !!c);
    const thisRow = {};

    fields.forEach(f => {
      thisRow[f] = params.getValue(f);
    });
    return thisRow;
  };

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              code: ele.clientBasicDetails.code,
              firstName: ele.clientBasicDetails.firstName,
              clientTypeCd: ele.clientBasicDetails.clientTypeCd,
              displayName: ele.clientBasicDetails.displayName,
              contact: ele.clientBasicDetails.contactNos.length > 0 ? ele.clientBasicDetails.contactNos[0].contactNo : '',
              id: ele.id,
            });
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  // useObservable(csTableData$, setRows);

  const populateDetails = obj => {
  };

  return (
    <div>
      {query.get('mode') === 'create' ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '20px',
            height: '2em',
            color: '#000',
            fontSize: '18px',
            fontWeight: 600,
          }}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            SLA Configuration
          </span>
        </Grid>
      ) : null}

      {(() => {
        switch (query.get('mode')) {
          case 'viewList':
            return <SLAConfigurationListComponent rows={rows} />;
          case 'create':
            return <SLAConfigurationComponent />;
          default:
            history.push('/sla/configuration?mode=viewList');
        }
      })()}
    </div>
  );
}
