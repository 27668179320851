// import * as React from "react";
// import * as yup from "yup";
import DateFnsUtils from '@date-io/date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { AgentsService } from '../../remote-api/api/agents-services';
import { ClientService, ProspectService } from '../../remote-api/api/client-services';
import { InvoiceService } from '../../remote-api/api/invoice-services';
import { AddressService } from '../../remote-api/api/master-services';
import { PlanService } from '../../remote-api/api/plan-services';
import { ProductService } from '../../remote-api/api/product-services';
import { TaxService } from '../../remote-api/api/tax-services';
import InvoiceClientModal from '../../invoice-management/invoice/modals/invoice.client.modal.component';
import Asterisk from '../../shared-components/components/red-asterisk';

const validationSchema = yup.object({
  name: yup.string('Enter your Name').required('Name is required'),
  type: yup.string('Choose Agent type').required('Agent Type is required'),
  contact: yup
    .string('Enter your Contact Number')
    .required('Contact number is required')
    .test('len', 'Must be exactly 10 digit', val => val.length === 10),
  // .matches(new RegExp('[0-9]{10}'),'Contact number is not valid'),
  email: yup.string('Enter your email').email('Enter a valid email'),
  natureOfAgent: yup.string('Enter Nature of Agent').required('Agent Nature is required'),
});

const invoiceservice = new InvoiceService();
const taxservice = new TaxService();
const productservice = new ProductService();
const planservice = new PlanService();
const agentservice = new AgentsService();
const clientservice = new ClientService();
const prospectservice = new ProspectService();
const addressservice = new AddressService();

let pdt$ = productservice.getProducts();
let ts$ = taxservice.getTaxes();
let addr$ = addressservice.getAddressConfig();

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: '90%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  AccordionSummary: {
    backgroundColor: theme.palette.background.default,
  },
  disabled: {},
}));

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

export default function InvoiceDetails(props) {
  const query2 = useQuery1();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [cardTypes, setCardTypes] = React.useState([]);
  const [feesTypes, setFeesTypes] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [planList, setPlanList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [openClientModal, setOpenClientModal] = React.useState(false);
  const [openAgentModal, setOpenAgentModal] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [taxList, setTaxList] = React.useState([]);
  const [clientData, setClientData] = React.useState({
    clientName: '',
    clientMobile: '',
    clientEmail: '',
    clientId: '',
    clientType: '',
  });

  const [addressConfig, setAddressConfig] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [revertedMessage, setRevertedMessage] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [codeForSearch, setCodeForSearch] = React.useState();
  const [nameForSearch, setNameForSearch] = React.useState();

  const handleInvoiceDate = date => {
    setSelectedDate(date);

    const timestamp = new Date(date).getTime();

    formik.setFieldValue('invoiceDate', timestamp);
  };

  const formik = useFormik({
    initialValues: {
      invoiceDate: new Date().getTime(),
      invoiceType: 'invoiceWithoutQuotation',
      clientOrProspectId: '',
      clientOrProspectType: '',
      product: '',
      plan: '',
      description: '',
      planData: '',
      productData: '',
      categorydata: [],
      discountType: 'PERCENTAGE',
      discountValue: 0,
      loadingType: 'PERCENTAGE',
      loadingValue: 0,
      discountAmount: 0,
      loadingAmount: 0,
      totalPremiumAmount: 0,
      totalTaxAmount: 0,
      totalAmountWithoutTax: 0,
      totalAmountWithTax: 0,
    },
    // validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit();
    },
  });

  useEffect(() => {
    let subscription = addr$.subscribe(result => {
      if (result && result.length !== 0) {
        result.forEach((prop, i) => {
          prop.addressConfigurationFieldMappings.forEach((field, j) => {
            // let fname = "field"+i+j;
            field['value'] = '';
            if (field.sourceId !== null && field.sourceId !== '') {
              field['sourceList'] = [];
            }
            if (field.type === 'dropdown' && field.sourceId === null) {
              if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
                field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
              }
              // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
              //   field['sourceList'] = [];
              // }
            }
          });
        });

        setAddressConfig(result);

        result.forEach((prop, i) => {
          prop.addressConfigurationFieldMappings.map((field, j) => {
            //   frmObj[field.fieldName] = field.defaultValue;
            if (field.dependsOn == '' && field.type == 'dropdown' && field.modifyApiURL) {
              addressservice.getSourceList(field.modifyApiURL).subscribe(res => {
                // field.sourceList =res.content;
                const list = [...result];
                list[i].addressConfigurationFieldMappings[j].sourceList = res.content;
                setAddressConfig(list);
                // frmLst[field.fieldName] = res.content;
              });
            }
          });
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [addr$, setAddressConfig]);

  const handleopenClientModal = () => {
    setOpenClientModal(true);
  };

  const handleOpenAgentModal = () => {
    setOpenAgentModal(true);
  };

  const handleCloseAgentModal = () => {
    setOpenAgentModal(false);
  };

  const handleCloseClientModal = () => {
    setOpenClientModal(false);
  };

  const handleSubmitClientModal = item => {
    setClientData({
      ...setClientData,
      clientName: item.name,
      clientMobile: item.contactNo,
      clientEmail: item.email,
      clientId: item.id,
      clientType: item.clientType,
    });
    populateDynamicAddress(item, addressConfig);
    setOpenClientModal(false);
  };

  const callAPiFunc = (field, prop, resultarr, addrrList) => {
    addrrList.forEach((pr, i) => {
      pr.addressConfigurationFieldMappings.forEach((fi, j) => {
        if (fi.fieldName === prop.dependOnfields[0]) {
          resultarr.push(fi.value);
          if (pr.dependOnfields !== null) {
            callAPiFunc(fi, pr, resultarr, addrrList);
          }
        }
      });
    });

    return resultarr;
  };

  const populateDynamicAddress = (item, addressConfigList) => {
    let addrrList = [...addressConfigList];
    let p = item?.clientAddress?.addresses.forEach(val => {
      let q = addrrList?.forEach((prop, i) => {
        let r = prop.addressConfigurationFieldMappings?.forEach((field, j) => {
          if (Object.keys(val?.addressDetails)[0] === field?.fieldName) {
            field['value'] = val?.addressDetails[field?.fieldName];
          }
        });
      });
    });

    // setAddressConfig(addrrList);

    addrrList.forEach((prop, i) => {
      prop.addressConfigurationFieldMappings.forEach((field, j) => {
        if (field.type === 'dropdown' && field.dependsOn !== '' && prop.dependOnfields !== null) {
          let arr = [];
          let dArr = callAPiFunc(field, prop, arr, addrrList);

          const word = '{code}';
          let apiURL = field.modifyApiURL;
          dArr.forEach(cd => {
            apiURL = apiURL.slice(0, apiURL.lastIndexOf(word)) + apiURL.slice(apiURL.lastIndexOf(word)).replace(word, cd);
          });
          addressservice.getSourceList(apiURL).subscribe(res => {
            addrrList[i].addressConfigurationFieldMappings[j].sourceList = res.content;

            setAddressConfig(addrrList);
          });
        } else {
          // setAddressConfig(addrrList);
        }
      });
    });

    // setAddressConfig(addrrList);
  };

  const changeCommision = (e, i) => {
    const { name, value } = e.target;
    const list = [...agentsList];
    list[i][name] = value;
    setAgentsList(list);
    calculateFinalValue(list, i);
  };

  const calculateFinalValue = (list, i) => {
    list[i]['finalValue'] = (Number(list[i]['commissionValue']) * Number(formik.values.totalAmountWithoutTax)) / 100;
    setAgentsList(list);
  };

  const calculateAgentValues = totalAmountWithoutTax => {
    const list = [...agentsList];
    list.forEach(ele => {
      ele['finalValue'] = (Number(ele.commissionValue) * Number(totalAmountWithoutTax)) / 100;
    });
    setAgentsList(list);
  };

  const handleAgentModalSubmit = selectedAgents => {
    // const finalArr = [...agentsList,...selectedAgents];
    setAgentsList(selectedAgents);
    setOpenAgentModal(false);
  };

  const handleSubmit = () => {
    let payload = {
      invoiceType: formik.values.invoiceType,
      invoiceDate: new Date(selectedDate).getTime(),
      clientOrProspectId: clientData.clientId,
      clientOrProspectType: clientData.clientType,
      productId: formik.values.product,
      planId: formik.values.plan,
      totalBeforeDiscountAndLoadingAmount: Number(formik.values.totalPremiumAmount),
      discountType: formik.values.discountType,
      discountEnterValue: formik.values.discountValue,
      totalDiscount: Number(formik.values.discountAmount),
      loadingType: formik.values.loadingType,
      loadingEnterValue: formik.values.loadingValue,
      totalLoading: Number(formik.values.loadingAmount),
      totalAfterDiscountAndLoadingAmount: Number(formik.values.totalAmountWithoutTax),
      totalTaxAmount: Number(formik.values.totalTaxAmount),
      totalAmountWithTax: Number(formik.values.totalAmountWithTax),
    };
    let invArr = [];
    formik.values.categorydata.forEach(ele => {
      invArr.push({ categoryId: ele.id, noOfMenber: ele.noOfMembers, premiumAmount: Number(ele.premiumAmount) });
    });
    payload['invoiceCategories'] = invArr;

    let invAgents = [];
    agentsList.forEach(ag => {
      invAgents.push({
        agentId: ag.agentId,
        commissionType: ag.commissionType,
        commissionValue: ag.commissionValue,
        finalValue: ag.finalValue,
      });
    });

    payload['invoiceAgents'] = invAgents;

    let invTaxes = [];

    taxList.forEach(tx => {
      if (tx.checked) {
        invTaxes.push({
          taxAmount: tx.taxVal,
          taxId: tx.id,
        });
      }
    });
    payload['invoiceTaxes'] = invTaxes;

    invoiceservice.saveInvoice(payload).subscribe(res => {
      history.push(`/invoices?mode=viewList`);
      // window.location.reload();
    });
  };

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  //tax API
  const useObservable2 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            ele['checked'] = false;
            ele['taxVal'] = 0;
          });
        }

        result.content.sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        });
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  //product API
  const useObservable3 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];

        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.productBasicDetails.name,
              id: ele.id,
            });
          });
        }
        setter(tableArr);
        populateProduct(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  // useObservable(ct$, setCardTypes);
  // useObservable(ft$, setFeesTypes);
  useObservable2(ts$, setTaxList);
  // useObservable2(ps$, setPlanList);
  useObservable3(pdt$, setProductList);

  const populateProduct = prodList => {
    if (id) {
      invoiceservice.getInvoiceDetails(id).subscribe(res => {
        formik.setFieldValue('invoiceDate', res.invoiceDate);
        formik.setFieldValue('invoiceType', res.invoiceType);
        formik.setFieldValue('clientOrProspectId', res.clientOrProspectId);
        formik.setFieldValue('clientOrProspectType', res.clientOrProspectType);
        formik.setFieldValue('discountType', res.discountType);
        formik.setFieldValue('discountValue', res.discountEnterValue);
        formik.setFieldValue('loadingType', res.loadingType);
        formik.setFieldValue('loadingValue', res.loadingEnterValue);
        formik.setFieldValue('discountAmount', res.totalDiscount);
        formik.setFieldValue('loadingAmount', res.totalLoading);
        formik.setFieldValue('totalPremiumAmount', res.totalBeforeDiscountAndLoadingAmount);
        formik.setFieldValue('totalAmountWithoutTax', res.totalAfterDiscountAndLoadingAmount);
        formik.setFieldValue('totalTaxAmount', res.totalTaxAmount);
        formik.setFieldValue('totalAmountWithTax', res.totalAmountWithTax);

        setRevertedMessage(res.reverted);

        setSelectedDate(new Date(res.invoiceDate));
        prodList.forEach(p => {
          if (p.id === res.productId) {
            formik.setFieldValue('productData', p);
            formik.setFieldValue('product', res.productId);
            planservice.getPlanFromProduct(res.productId).subscribe(pl => {
              if (pl.length > 0) {
                setPlanList(pl);
                pl.forEach(plan => {
                  if (plan.id === res.planId) {
                    formik.setFieldValue('planData', plan);
                    formik.setFieldValue('plan', res.planId);
                    planservice.getCategoriesFromPlan(res.planId).subscribe(catList => {
                      if (catList.length > 0) {
                        let catArr = [];
                        catList.forEach(cat => {
                          cat['noOfMembers'] = 0;
                          cat['premiumAmount'] = 0;
                          catArr.push(cat);
                        });
                        setCategoryList(catArr);
                        let cData = [];
                        res.invoiceCategories.forEach(invCat => {
                          catArr.forEach(cate => {
                            if (Number(invCat.categoryId) === cate.id) {
                              cate['noOfMembers'] = invCat.noOfMenber;
                              cate['premiumAmount'] = invCat.premiumAmount;
                              cData.push(cate);
                            }
                          });
                        });

                        formik.setFieldValue('categorydata', cData);
                      }
                    });
                  }
                });
              }
            });
          }
        });

        taxservice.getTaxes().subscribe(result => {
          if (result.content) {
            result.content.forEach(re => {
              re['checked'] = false;
              re['taxVal'] = 0;
            });
          }

          let txList = [];
          res.invoiceTaxes.forEach(inv => {
            if (result.content && result.content.length > 0) {
              result.content.forEach(tx => {
                if (tx.id === inv.taxId) {
                  tx['checked'] = true;
                  tx['taxVal'] = inv.taxAmount;
                }
              });
            }
          });
          result.content.sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          });

          setTaxList(result.content);
        });

        let agentIDs = [];
        // res.invoiceAgents.forEach(invAgIds => {
        //     agentIDs.push(invAgIds.agentId);
        // })

        let pageRequest = {
          page: 0,
          size: 100,
          summary: true,
          active: true,
          // agentIds:agentIDs
        };
        let invoiceAgentList = [];
        agentservice.getAgents(pageRequest).subscribe(agentdta => {
          res.invoiceAgents.forEach(invAgIds => {
            if (agentdta.content && agentdta.content.length > 0) {
              agentdta.content.forEach(ag => {
                if (invAgIds.agentId === ag.id) {
                  invoiceAgentList.push({
                    agentId: invAgIds.agentId,
                    commissionType: invAgIds.commissionType,
                    commissionValue: invAgIds.commissionValue,
                    finalValue: invAgIds.finalValue,
                    name: ag.agentBasicDetails.name,
                  });
                }
              });
            }
          });
          setAgentsList(invoiceAgentList);
        });

        if (res.clientOrProspectType === 'Client') {
          clientservice.getClientDetails(res.clientOrProspectId).subscribe(cdata => {
            setClientData({
              clientName: cdata.clientBasicDetails.displayName,
              clientEmail: cdata.clientBasicDetails.contactNos[0].contactNo,
              clientMobile: cdata.clientBasicDetails.emails[0].emailId,
              clientId: cdata.id,
              clientType: 'Client',
            });
            addr$.subscribe(result => {
              result.forEach((prop, i) => {
                prop.addressConfigurationFieldMappings.forEach((field, j) => {
                  // let fname = "field"+i+j;
                  field['value'] = '';
                  if (field.sourceId !== null && field.sourceId !== '') {
                    field['sourceList'] = [];
                  }
                  if (field.type === 'dropdown' && field.sourceId === null) {
                    if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
                      field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
                    }
                    // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
                    //   field['sourceList'] = [];
                    // }
                  }
                });
              });

              setAddressConfig(result);

              result.forEach((prop, i) => {
                prop.addressConfigurationFieldMappings.map((field, j) => {
                  //   frmObj[field.fieldName] = field.defaultValue;
                  if (field.dependsOn == '' && field.type == 'dropdown' && field.modifyApiURL) {
                    addressservice.getSourceList(field.modifyApiURL).subscribe(res => {
                      // field.sourceList =res.content;
                      const list = [...result];
                      result[i].addressConfigurationFieldMappings[j].sourceList = res.content;
                      // frmLst[field.fieldName] = res.content;
                      populateDynamicAddress(cdata, result);
                    });
                  }
                });
              });
              // setAddressConfig(result);

              // populateDynamicAddress(cdata,result)
            });
          });
        }

        if (res.clientOrProspectType === 'Prospect') {
          prospectservice.getProspectDetails(res.clientOrProspectId).subscribe(cdata => {
            setClientData({
              clientName: cdata.displayName,
              clientEmail: cdata.emailId,
              clientMobile: cdata.mobileNo,
              clientId: cdata.id,
              clientType: 'Prospect',
            });
          });
        }
      });
    }
  };

  const handleLoadingChange = e => {
    const { name, value } = e.target;
    formik.setFieldValue('loadingValue', value);

    // let lv = formik.values.loadingValue;
    let dv = formik.values.discountValue;
    let tpa = formik.values.totalPremiumAmount;
    calculateTotalAmoutWithoutTax(value, dv, tpa);
  };

  const handleDiscountChange = e => {
    const { name, value } = e.target;
    formik.setFieldValue('discountValue', value);

    let lv = formik.values.loadingValue;
    // let dv = formik.values.discountValue;
    let tpa = formik.values.totalPremiumAmount;
    calculateTotalAmoutWithoutTax(lv, value, tpa);
  };

  const calculateTotalAmoutWithoutTax = (loadingVal, discountVal, premiumTotal) => {
    let la = (Number(loadingVal) / 100) * premiumTotal;
    let da = (Number(discountVal) / 100) * premiumTotal;

    if (formik.values.loadingType === 'PERCENTAGE') {
      formik.setFieldValue('loadingAmount', la);
    }
    if (formik.values.loadingType === 'FIXED') {
      la = Number(loadingVal);
      formik.setFieldValue('loadingAmount', la);
    }
    if (formik.values.discountType === 'PERCENTAGE') {
      formik.setFieldValue('discountAmount', da);
    }
    if (formik.values.discountType === 'FIXED') {
      da = Number(discountVal);
      formik.setFieldValue('discountAmount', da);
    }

    let at = premiumTotal + la - da;
    formik.setFieldValue('totalAmountWithoutTax', at);
    calculateAgentValues(at);
    calculateTax(taxList, at);
  };

  //called after category select or premium amount change
  const calculateData = list => {
    let total = 0;

    if (list.length > 0) {
      list.forEach(ele => {
        total = total + Number(ele.premiumAmount);
      });
    }
    formik.setFieldValue('totalPremiumAmount', total);

    let lv = formik.values.loadingValue;
    let dv = formik.values.discountValue;

    calculateTotalAmoutWithoutTax(lv, dv, total);
  };

  const calculateTax = (txlist, totalAmountWithoutTax) => {
    txlist.forEach(ele => {
      if (ele.checked) {
        if (ele.type === 'PERCENTAGE') {
          ele.taxVal = (Number(ele.value) * Number(totalAmountWithoutTax)) / 100;
        }
        if (ele.type === 'FIXED') {
          ele.taxVal = Number(ele.value);
        }
      }
    });
    setTaxList(txlist);
    let grandTotal = Number(totalAmountWithoutTax);
    let tt = 0;
    txlist.forEach(v => {
      if (v.checked) {
        grandTotal = grandTotal + Number(v.taxVal);
        tt = tt + Number(v.taxVal);
      }
    });

    formik.setFieldValue('totalAmountWithTax', grandTotal);

    formik.setFieldValue('totalTaxAmount', tt);
  };

  const handleFieldChecked = (e, index) => {
    const { name, checked } = e.target;
    const list = [...taxList];
    list[index][name] = checked;
    setTaxList(list);
    calculateTax(list, formik.values.totalAmountWithoutTax);
  };

  React.useEffect(() => {
    if (id) {
      populateData(id);
    }
  }, [id]);

  const populateData = id => {};

  const getPlans = productId => {
    planservice.getPlanFromProduct(productId).subscribe(res => {
      if (res.length > 0) {
        setPlanList(res);
      }
    });
  };

  const getCategories = planid => {
    planservice.getCategoriesFromPlan(planid).subscribe(res => {
      if (res.length > 0) {
        let arr = [];
        res.forEach(ele => {
          ele['noOfMembers'] = 0;
          ele['premiumAmount'] = 0;
          arr.push(ele);
        });
        setCategoryList(arr);
      }
    });
  };

  const handleClose = () => {
    history.push(`/invoices?mode=viewList`);
    // window.location.reload();
  };

  const handlePlanChange = (e, value) => {
    formik.setFieldValue('planData', value);
    formik.setFieldValue('plan', value.id);
    getCategories(value.id);
  };

  const handleProductChange = (e, value) => {
    formik.setFieldValue('productData', value);
    formik.setFieldValue('product', value.id);
    getPlans(value.id);
  };

  const handleCategorySelect = (e, value) => {
    formik.setFieldValue('categorydata', value);
    calculateData(value);
  };

  const changeCategoryData = (e, i) => {
    const { name, value } = e.target;
    let list = formik.values.categorydata;
    list[i][name] = value;
    formik.setFieldValue('categorydata', list);
    calculateData(list);
  };

  const changePremiumAmount = (e, i) => {
    const { name, value } = e.target;
    let list = formik.values.categorydata;
    list[i][name] = value;
    formik.setFieldValue('categorydata', list);
  };
  const expandAccordian = () => {
    setExpanded(expanded => (expanded ? false : true));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <Paper elevation='none'>
                <Box p={3} my={2}>
                    <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    views={["year", "month", "date"]}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Invoice Date"
                                    value={selectedDate}
                                    onChange={handleInvoiceDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change ing date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Box>
            </Paper> */}
      <Paper elevation="none">
        <Box p={3} my={2}>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={4}>
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                views={["year", "month", "date"]}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Invoice Date"
                                value={selectedDate}
                                disabled={query2.get("mode") === 'view' ? true : false}
                                InputProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                        disabled: classes.disabled
                                    }
                                }}
                                onChange={handleInvoiceDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                }}
                                />
                            </MuiPickersUtilsProvider> */}
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="code"
                  value={codeForSearch}
                  onChange={e => setCodeForSearch(e.target.value)}
                  label="Code"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  size="small"
                  id="standard-basic"
                  name="name"
                  value={nameForSearch}
                  onChange={e => setNameForSearch(e.target.value)}
                  label="Name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} container justifyContent="flex-end">
              {query2.get('mode') !== 'view' && (
                <Button variant="contained" color="secondary" onClick={handleopenClientModal}>
                  Search Client
                </Button>
              )}
              {query2.get('mode') === 'view' && revertedMessage && (
                <span style={{ color: 'red', fontWeight: 'bold' }}>REVERTED</span>
              )}
              <InvoiceClientModal
                openClientModal={openClientModal}
                code={codeForSearch}
                name={nameForSearch}
                setOpenClientModal={setOpenClientModal}
                handleCloseClientModal={handleCloseClientModal}
                handleSubmitClientModal={handleSubmitClientModal}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper elevation="none">
        <Box p={3} my={2}>
          <Accordion expanded={expanded} onChange={expandAccordian} elevation="none">
            <AccordionSummary
              className={classes.AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography className={classes.heading}>Client Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    name="clientName"
                    readonly={true}
                    label="Name"
                    value={clientData.clientName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    name="clientEmail"
                    readonly={true}
                    label="Email"
                    value={clientData.clientEmail}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    name="clientMobile"
                    label="Contact"
                    readonly={true}
                    value={clientData.clientMobile}
                  />
                </Grid>

                {addressConfig && addressConfig.length !== 0 && (
                  <Grid item xs={12} style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row' }}>
                    {addressConfig.map((prop, i) => {
                      return prop.addressConfigurationFieldMappings.length !== 1 ? (
                        <Grid item xs={6} key={i}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                              {prop.levelName}
                            </InputLabel>
                            {prop.iButtonRequired === 'true' && (
                              <Tooltip title={prop.iButtonMessage} placement="top">
                                <InfoOutlinedIcon style={{ fontSize: 'medium' }} />
                              </Tooltip>
                            )}
                          </div>
                          {prop.addressConfigurationFieldMappings.map((field, j) => {
                            return (
                              <>
                                {field.type === 'dropdown' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      name={field.fieldName}
                                      id="demo-simple-select"
                                      required={field.required === 'true' ? true : false}
                                      style={{ marginRight: '8px' }}
                                      value={field.value}
                                      readOnly>
                                      {field.sourceList.map(ele => {
                                        return (
                                          <MenuItem value={ele.code} key={ele.code}>
                                            {ele.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                )}
                                {field.type === 'textbox' && !field.readOnly && (
                                  <TextField
                                    id="standard-basic"
                                    name={field.fieldName}
                                    type={field.dataType === 'numeric' ? 'number' : 'text'}
                                    style={{ marginTop: '8px' }}
                                    value={field.value}
                                    readOnly
                                  />
                                )}

                                {field.type === 'textarea' && !field.readOnly && (
                                  <TextField
                                    required={field.required === 'true' ? true : false}
                                    id="standard-multiline-flexible"
                                    multiline
                                    name={field.fieldName}
                                    rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                                    value={field.value}
                                    readOnly
                                  />
                                )}
                                {field.readOnly && (
                                  <TextField
                                    id="standard-basic"
                                    name={field.fieldName}
                                    // value={field.defaultValue}
                                    defaultValue={field.defaultValue}
                                    readonly
                                    style={{ marginTop: '10px', marginRight: '8px', width: '15%' }}
                                    size="small"
                                  />
                                )}
                              </>
                            );
                          })}
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={4} key={i + 50}>
                          {prop.addressConfigurationFieldMappings.map((field, j) => {
                            return (
                              <div key={j + 2}>
                                {field.type === 'dropdown' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                      {'prop.levelName'}
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      name={field.fieldName}
                                      required={field.required === 'true' ? true : false}
                                      id="demo-simple-select"
                                      value={field.value}
                                      readOnly>
                                      {field.customValuePresent === 'CUSTOM' &&
                                        field.sourceList.map(ele => {
                                          return (
                                            <MenuItem value={ele.id} key={ele.id}>
                                              {ele.value}
                                            </MenuItem>
                                          );
                                        })}

                                      {field.customValuePresent === 'DYNAMIC' &&
                                        field.sourceList.map(ele => {
                                          return (
                                            <MenuItem value={ele.code} key={ele.code}>
                                              {ele.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                )}

                                {field.type === 'textbox' && !field.readOnly && (
                                  <TextField
                                    required={field.required === 'true' ? true : false}
                                    id="standard-basic"
                                    name={field.fieldName}
                                    type={field.dataType === 'numeric' ? 'number' : 'text'}
                                    value={field.value}
                                    // value={formObj[field.fieldName] ? formObj[field.fieldName] : ""}
                                    label={prop.levelName}
                                    readOnly
                                  />
                                )}

                                {field.type === 'textarea' && !field.readOnly && (
                                  <TextField
                                    id="standard-multiline-flexible"
                                    // required={field.required === 'true' ? true : false}
                                    multiline
                                    name={field.fieldName}
                                    rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                                    value={field.value}
                                    // value={values.addressData[field.fieldName] ? values.addressData[field.fieldName] : ""}
                                    readOnly
                                    // label={prop.levelName}
                                    label={
                                      field.required === 'true'
                                        ? <span>{prop.levelName} <Asterisk /></span>
                                        : prop.levelName
                                    }
                                  />
                                )}
                                {field.readOnly && (
                                  <TextField
                                    id="standard-basic"
                                    name={field.fieldName}
                                    value={field.defaultValue}
                                    label={prop.levelName}
                                    defaultValue={field.defaultValue}
                                    readOnly
                                  />
                                )}
                                {prop.iButtonRequired === 'true' && (
                                  <Tooltip title={prop.iButtonMessage} placement="top">
                                    <InfoOutlinedIcon style={{ fontSize: 'medium', marginTop: '23px' }} />
                                  </Tooltip>
                                )}
                              </div>
                            );
                          })}
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
            {/* <Box p={3} my={2}></Box> */}
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                {query2.get('mode') !== 'view' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '5px' }}
                    onClick={() => history.push('/funds/statement/details')}>
                    Generate Fund Statement
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            {/* </Box> */}
          </Accordion>
        </Box>
      </Paper>
    </form>
  );
}
